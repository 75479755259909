import { Fragment } from 'react'
import { TabContent, TabPane } from 'reactstrap'
import Documents from './Documents'
import Notes from './Notes'
import Portfolios from './Porfolios'
import WorkflowFiles from './WorkflowFiles'
import Workflows from './Workflows'
import Info from './info'

const Tabs = ({ client, role, activeTab }) => {
	return (
		<Fragment>
			<TabContent activeTab={activeTab}>
				<TabPane tabId='1'>
					<Info activeTab={activeTab} client={client} role={role} />
				</TabPane>

				<TabPane tabId='2'>
					<div className='alert alert-info'>It&apos;s empty here.</div>
				</TabPane>

				<TabPane tabId='3'>
					<Portfolios activeTab={activeTab} client={client} role={role} />
				</TabPane>

				<TabPane tabId='4'>
					<Documents activeTab={activeTab} client={client} role={role} />
				</TabPane>

				<TabPane tabId='5'>
					<Workflows activeTab={activeTab} client={client} role={role} />
				</TabPane>
				<TabPane tabId='6'>
					<WorkflowFiles activeTab={activeTab} client={client} role={role} />
				</TabPane>
				<TabPane tabId='7'>
					<Notes activeTab={activeTab} client={client} role={role} />
				</TabPane>
			</TabContent>
		</Fragment>
	)
}

export default Tabs
