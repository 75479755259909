import WebViewer from '@pdftron/webviewer'
import { initializeWalkthrough } from '@pdftron/webviewer-walkthrough'
import { useEffect, useMemo, useRef } from 'react'

import { flatten } from 'lodash'
import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import {
	ACTIONS,
	roundToDecimal,
} from 'pages/Authentication/profile/components/workflow/documents-tab'
import notification from 'utilities/notification'
import { endFormFieldCreationMode, signatureViewDisabledElement, steps } from '.'
import { useSignatureDocumentStatus } from '../../signature-document-context'
const SignatureTab = ({ formik, documentId }) => {
	const viewerRef = useRef(null)
	const formValues = useMemo(() => formik?.values, [formik?.values])
	// eslint-disable-next-line no-unused-vars
	const [_, setIsDocumentSaved] = useSignatureDocumentStatus()
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const FILE_URL = useMemo(() => formValues?.file_path, [formValues?.file_path])
	const no_of_users = useMemo(() => formValues?.min_clients?.value || 0, [formValues])
	const [getSetting] = useLazyGetSettingQuery()

	const createInstance = async () => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				const instance = await WebViewer(
					{
						path: '/lib',
						initialDoc: FILE_URL,
						fullAPI: true,
						disabledElements: signatureViewDisabledElement,
						custom: JSON.stringify({ total_users: no_of_users }),
						licenseKey: settingValue?.data?.setting_value,
					},
					viewerRef.current,
				)

				return instance
			}
		} catch (error) {
			notification(error)
		}
	}

	const saveDocument = async (documentViewer, annotationManager) => {
		const doc = documentViewer.getDocument()
		const xfdfString = await annotationManager.exportAnnotations()
		const data = await doc.getFileData({ xfdfString })
		const arr = new Uint8Array(data)
		const blob = new Blob([arr], { type: 'application/pdf' })
		formik.setFieldValue('documentBlob', blob)
		formik.setFieldValue('status', 1)
		notification(
			'success',
			'Signature fields has been saved. Now submit the document to save this template.',
		)
		setIsDocumentSaved(true)
	}

	useEffect(() => {
		if (FILE_URL) {
			createInstance().then((instance) => {
				if (instance?.Core) {
					if (documentId) {
						createDocumentEventLog({
							document_id: documentId,
							event_type: documentEventLog.eventTypes.SignatureFieldCreation,
							module_type: documentEventLog.moduleTypes.DocumentTemplateCreation,
						})
					}

					const { documentViewer, annotationManager, Annotations } = instance.Core
					const { WidgetFlags, Forms, TextWidgetAnnotation, Border, Color } = Annotations
					instance.UI.enableFeatures([instance.UI.Feature.Initials])

					// start timestamp field insertion with signature field
					annotationManager.addEventListener(
						'annotationChanged',
						(annotations, action, { imported }) => {
							if (imported) {
								return
							}

							const selectedAnnotation = annotations[0]

							// add timestamp annotation here on signature field insertion
							if (
								selectedAnnotation instanceof
									Annotations.SignatureWidgetAnnotation &&
								action === ACTIONS.ADD
							) {
								const fieldName = selectedAnnotation.fieldName

								const [$fieldName, $userRole] = fieldName.split('.')

								const timestampFieldName = [
									$fieldName,
									$userRole,
									'timestamp',
								].join('.')
								// start insertion of timestamp form textfield
								const timestampWidgetflags = new WidgetFlags()

								const field = new Forms.Field(timestampFieldName, {
									type: 'Tx',
									defaultValue: 'timestamp goes here',
									flags: timestampWidgetflags,
								})

								const border = new Border()
								border.color = new Color(0, 0, 0)
								border.width = 1

								const widgetAnnot = new TextWidgetAnnotation(field)
								widgetAnnot.PageNumber = selectedAnnotation.PageNumber
								widgetAnnot.X = selectedAnnotation.X
								widgetAnnot.Y =
									selectedAnnotation.Y +
									roundToDecimal(selectedAnnotation.Height, 2)
								widgetAnnot.Width = 140
								widgetAnnot.Height = 15
								widgetAnnot.border = border

								annotationManager.getFieldManager().addField(field)
								annotationManager.addAnnotation(widgetAnnot)
								annotationManager.drawAnnotationsFromList([widgetAnnot])
								// end insertion of timestamp form textfield
							}
						},
					)
					// end timestamp field insertion with signature field

					const formFieldCreationManager = annotationManager.getFormFieldCreationManager()

					// prevent document templete from publishing if there is an annotation is changed.
					annotationManager.addEventListener('annotationChanged', () => {
						setIsDocumentSaved(false)
					})

					// save the document after inserting signature field inside pdf.
					instance.UI.setHeaderItems((header) => {
						header.push({
							dataElement: 'save-button',
							type: 'actionButton',
							img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
							onClick: async () => {
								try {
									const fieldManager = annotationManager.getFieldManager()
									const signatureFields = []
									fieldManager.forEachField((field) => {
										if (field?.name === 'signature') {
											signatureFields.push(field.children)
										}
									})
									const isSigntureFieldPresent =
										flatten(signatureFields).length > 0
									if (isSigntureFieldPresent) {
										await endFormFieldCreationMode(formFieldCreationManager)
										await saveDocument(documentViewer, annotationManager)
									} else {
										notification(
											'warn',
											'Please add at least one signature field to the PDF.',
										)
									}
								} catch (error) {
									notification(
										'warn',
										'An error occurred while saving the PDF document.',
									)
								}
							},
						})
					})

					const { start } = initializeWalkthrough(
						viewerRef.current,
						steps,
						() => {
							console.log('tutorial complete...')
						},
						{
							backgroundColor: '#dee3fa',
							textColor: '#37458b',
						},
					)
					start()
				}
			})
		}
	}, [FILE_URL, documentId])

	return (
		<div>
			<h3 className='mb-2 text-lg font-bold'>Signature</h3>
			<p className='text-gray-600'>Assign Signature field</p>

			<div className='rounded-md bg-white p-6'>
				<p>In this step you can add signature field to the pdf.</p>
				<div className='webviewer' ref={viewerRef} style={{ height: '100vh' }} />
			</div>
		</div>
	)
}

export default SignatureTab
