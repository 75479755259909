import { Fragment } from 'react'
import PdfFieldRender from '../../../../components/PdfFieldRender'

const SamePage = ({ docs, error, fields }) => {
	return (
		<Fragment>
			{fields.length > 0 ? (
				<div className='mt-4 flex w-full space-x-6'>
					<div className='grid w-3/4 grid-cols-2 gap-x-3'>
						{fields.map((field, i) => (
							<div key={i}>
								<PdfFieldRender field={field} i={i} error={error} />
							</div>
						))}
					</div>

					<div className='w-3/12'>
						<div className='prose'>
							<h6>This field is in the following documents</h6>
							<ol className='text-sm'>
								{docs.length > 0 ? (
									<Fragment>
										{docs.map((item, idx) => (
											<li key={idx} className='active-doc'>
												{item?.title}
											</li>
										))}
									</Fragment>
								) : (
									<Fragment>
										{Array.isArray(fields[0]?.documents) && (
											<Fragment>
												{fields[0]?.documents.map((item, idx) => (
													<li key={idx} className='active-doc'>
														{item?.title}
													</li>
												))}
											</Fragment>
										)}
									</Fragment>
								)}
							</ol>
						</div>
					</div>
				</div>
			) : (
				<div className='alert alert-info mx-auto max-w-md'>
					No profile field is required to be filled here. Please proceed.
				</div>
			)}
		</Fragment>
	)
}

export default SamePage
