import { Header } from 'modules/workflows'
import WorkflowsTemplatesContainer from 'modules/workflows/workflow-template/workflows-list'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from '../../../utils/checkPermission'
import UnAuthorize from '../../StaticPages/UnAuthorize'

const WorkflowTemplatesListing = () => {
	const isListPermission = hasPermission('workflow:list')

	return (
		<AppLayout
			meta={{
				title: 'Workflow Templates',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<WorkflowsTemplatesContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default WorkflowTemplatesListing
