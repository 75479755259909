/* eslint-disable react-hooks/exhaustive-deps */
import { withRouter } from 'react-router-dom'

import { useEffect } from 'react'
import LogoutButton from 'theme/ui/logout-button'
import HelperCard from './HelperCard'
import LeftSidebar from './LeftSidebar'
import useStepsQuery from './useStepsQuery'

const Layout = (props) => {
	const { children, helperItems = [], history } = props
	const { data, onboardStatus } = useStepsQuery()
	const PENDING = 'pending'

	useEffect(() => {
		if (Array.isArray(data)) {
			for (let index = 0; index < data.length; index++) {
				const step = data[index]
				if (step?.status === PENDING && typeof step?.id !== 'undefined') {
					history.push(`/onboarding/${step?.id}`)
					break
				}
			}
		}
	}, [data])

	useEffect(() => {
		if (Array.isArray(data)) {
			const steps = data.filter((step) => step?.status === PENDING)
			if (steps.length === 0) {
				if (onboardStatus === 'Approved') {
					window.location.href = '/dashboard'
				} else {
					window.location.href = '/onboarding/review-information'
				}
			}
		}
	}, [data])

	return (
		<div className='container-fluid overflow-hidden bg-white'>
			<div className='row'>
				<div className='col-sm-3 min-h-screen bg-[#F5F7FF] px-0 pt-2'>
					<LeftSidebar />
				</div>
				<div className='col-sm-9 '>
					<div className='grid  grid-cols-3'>
						<div className='col-span-3 mb-3 flex flex-row items-center justify-end  !border-b bg-white p-2 px-3'>
							<LogoutButton />
						</div>
						<div className='col-span-2 pt-5'>{children}</div>
						<div className='col-span-1 pt-5'>
							<div className='px-4'>
								<HelperCard items={helperItems} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withRouter(Layout)
