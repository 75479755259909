import { Col, Container, Row } from 'reactstrap'

import bg1 from 'assets/images/onboarding/bg2.png'
import apiEndpoints from 'helpers/apiEndpoints'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { savePhoneNumber } from 'store/client-onboarding/actions'
import { store } from 'store/common/actions'
import { useQuery } from 'utils/commonFunctions'
import { addPropertyTotoken, getEmail } from '..'
import Wrapper from '../components/Wrapper'
import FormHeader from '../components/header/FormHeader'
import StepForm from './StepForm'

const StepFour = (props) => {
	const { history } = props
	const { token } = useQuery(props.location.search)
	const dispatch = useDispatch()
	const email = getEmail(token)
	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = (values) => {
		setIsLoading(true)
		dispatch(store(apiEndpoints.userOnBoard.phone.sendOTP, { ...values, email })).then(
			(response) => {
				setIsLoading(false)
				if (response?.status === 200) {
					const newToken = addPropertyTotoken('phone_number', values?.phone_number, token)
					dispatch(savePhoneNumber({ phone_number: values?.phone_number }))
					history.push(`/onboarding/step-five?token=${newToken}`)
				}
			},
		)
	}

	return (
		<Wrapper>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<FormHeader token={token} />

							<StepForm history={history} onSubmit={onSubmit} isLoading={isLoading} />
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Upload multiple documents with ease
							</h2>
							<p className='text-[#74788D]'>
								No need to wonder what document you might need to upload. When
								workflow is assigned to you, all required document&apos;s list will
								be there for you to completely hassle free with absolutely no rush.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

export default StepFour
