/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	email: null,
	password: null,
	phone_number: null,
}

export const ClientOnboardingSlices = createSlice({
	name: 'client_onboarding',
	initialState: initialState,
	reducers: {
		saveEmail: (state, action) => {
			state.email = action.payload.email
		},
		savePassword: (state, action) => {
			state.password = action.payload.password
		},
		savePhoneNumber: (state, action) => {
			state.phone_number = action.payload.phone_number
		},
		clear: (state, actions) => {
			state.email = null
			state.password = null
			state.phone_number = null
		},
	},
})

export const { saveEmail, savePassword, savePhoneNumber } = ClientOnboardingSlices.actions
