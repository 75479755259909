import { Fragment } from 'react'
import CorrectionTabs from './correction-tabs'

const Tab4 = ({
	uploads,
	mutate,
	createdBy,
	activeTab,
	toggleTab,
	user_type,
	workflow_id,
	individual_document,
	screen_type,
}) => {
	const advisorName = `${createdBy?.first_name} ${createdBy?.last_name}` || '{advisor}'

	return (
		<Fragment>
			<h5 className='mb-3 text-center'>
				<strong>{advisorName}</strong> needs you to make some updates
			</h5>
			<h6 className='text-center'>
				<strong>{advisorName}</strong> has reviewed your submissions and needs you to make
				some adjustmens
			</h6>

			<CorrectionTabs
				screen_type={screen_type}
				workflow_id={workflow_id}
				uploads={uploads}
				individual_document={individual_document}
				toggleTab={toggleTab}
				activeTab={activeTab}
				user_type={user_type}
				mutate={mutate}
			/>
		</Fragment>
	)
}

export default Tab4
