import { Link } from 'react-router-dom'

const Actions = ({ field, last_updated, isActionActive }) => {
	return isActionActive ? (
		<div className='flex'>
			<Link to={field?.path} className='action-btn btn-primary-outline'>
				<i className='uil-file-download' style={{ fontSize: 16 }} />
				<span>Download</span>
			</Link>
		</div>
	) : (
		<span className=' text-sm  !text-gray-700'>{last_updated}</span>
	)
}

export default Actions
