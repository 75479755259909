import React from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap'
import ButtonLink from '../../../../components/Common/ButtonLink'
import ButtonWithLoader from '../../../../components/Common/ButtonWithLoader'
import { TextField } from '../../../../components/Forms/TextFieldComponent'
import { Field, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { required } from '../../../../utils/formValidation'
import { showSuccessToast } from '../../../../utils/commonFunctions'
import apiEndpoints from '../../../../helpers/apiEndpoints'
import * as actions from '../../../../store/permissions/actions'
import UnAuthorize from '../../../StaticPages/UnAuthorize'

const validate = {
    team_name: required('Team Name'),
    firm_name: required('Firm Name'),
    firm_address: required('Firm Address'),
}

const TeamManage = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()
    const { user_id, loading, hasTeam, isSuperAdmin } = useSelector(state => state.Permissions)

    const onSubmit = (values) => {
        const { team_name, firm_address, firm_name } = values
        const teamData = { teamOwner: { id: user_id }, team_name, firm_address, firm_name, status: 1 }
        dispatch(actions.createTeam(apiEndpoints.teams.create, teamData))
            .then(response => {
                if (response && response.status === 200) {
                    showSuccessToast(response.message)
                    window.location.href = '/profile'
                }
            })
    }

    return (
        <React.Fragment>
            {hasTeam && <UnAuthorize />}
            {isSuperAdmin && <UnAuthorize />}
            {!hasTeam && !isSuperAdmin && (
                <div className="page-content">
                    <Container fluid>
                        <Card>
                            <CardHeader>
                                <CardTitle>Create Team</CardTitle>
                                <ButtonLink to='/profile' color='primary'>Back</ButtonLink>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit((values) => onSubmit(values))}>
                                    <Row>
                                        <Col md={4}>
                                            <Field type='text' name='team_name' label='Team Name *' component={TextField} validate={validate.team_name} />
                                        </Col>

                                        <Col md={4}>
                                            <Field type='text' name='firm_name' label='Firm Name *' component={TextField} validate={validate.firm_name} />
                                        </Col>

                                        <Col md={4}>
                                            <Field type='text' name='firm_address' label='Firm Address *' component={TextField} validate={validate.firm_address} />
                                        </Col>

                                        <Col md={12}>
                                            <ButtonWithLoader type='submit' loading={loading}>Create</ButtonWithLoader>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            )}
        </React.Fragment>
    )
}

export default reduxForm({
    form: 'team',
    enableReinitialize: true,
})(TeamManage)
