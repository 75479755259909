import { Tab } from '@headlessui/react'

import FirstStep from './first-step'
import SecondStep from './second-step'

const Steps = ({
	formValues,
	formik,
	selectedIndex,
	documents,
	document_packages,
	upload_files,
	advisor_reviewer,
}) => {
	return (
		<div>
			<Tab.Group selectedIndex={selectedIndex}>
				<Tab.List className='hidden'>
					<Tab as='button' type='button'>
						Tab 1
					</Tab>
					<Tab as='button' type='button'>
						Tab 2
					</Tab>
				</Tab.List>
				<Tab.Panels>
					<Tab.Panel>
						<FirstStep formValues={formValues} formik={formik} />
					</Tab.Panel>

					<Tab.Panel>
						<SecondStep
							formValues={formValues}
							documents={documents}
							document_packages={document_packages}
							upload_files={upload_files}
							advisor_reviewer={advisor_reviewer}
						/>
					</Tab.Panel>
				</Tab.Panels>
			</Tab.Group>
		</div>
	)
}

export default Steps
