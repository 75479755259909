import Badge from 'theme/ui/badge'

const NoteTeamDetails = ({ teams }) => {
	return (
		<div className='flex-col'>
			{teams.length > 0 ? (
				<div className='flex space-x-1'>
					<span className=' text-gray-500'>Teams:</span>
					<div className='flex flex-wrap gap-1'>
						{teams.map((team, idx) => (
							<Badge rounded={false} variant='secondary' key={idx}>
								{team?.team_name}
							</Badge>
						))}
					</div>
				</div>
			) : undefined}
			{/* {users.length > 0 && (
				<span className='text-muted'>
					User : {users.map((user) => user?.first_name).join(' | ')}
				</span>
			)} */}
		</div>
	)
}

export default NoteTeamDetails
