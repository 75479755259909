import { Field } from 'formik'
import { useState } from 'react'

import { SelectField } from 'theme/ui/forms'
import { createClientOptionLabel } from '.'
import ClientsQuery from './clients-query'

const ClientDropdown = ({ formValues, client_applicants, name }) => {
	const [search, setSearch] = useState('')
	const { clients, isLoading } = ClientsQuery(search, formValues, client_applicants)

	const filterOption = ({ data }, search) => {
		if (
			data.fullName.toLowerCase().includes(search.toLowerCase()) ||
			data.email.toLowerCase().includes(search.toLowerCase())
		) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Client'
			type='select'
			name={name}
			options={clients}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isRequiredField={true}
			labelHelperText={true}
			placeholder='Select Client'
			getOptionLabel={(option) => createClientOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default ClientDropdown
