import { Field, FormikProvider, useFormik } from 'formik'
import { Button, InputField } from 'theme/ui/forms'
import { validationSchema } from '.'

const InvitationForm = ({ onDeclineInvitation, onAcceptInvitation, is_owner, token }) => {
	const formik = useFormik({
		initialValues: {
			teamName: '',
			is_owner: is_owner,
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values) => {
			const payload = {
				token: token,
			}
			if (values?.is_owner) {
				Object.assign(payload, {
					teamName: values?.teamName,
				})
			}
			await onAcceptInvitation(payload)
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='mt-2'>
					<p className='text-center text-sm  text-gray-500'>
						Please accept this invitation to join the team and streamline the documents
						and workflow processes for enhanced efficiency in completing tasks.
					</p>

					{formik.values.is_owner ? (
						<Field
							type='text'
							name='teamName'
							label='Enter your team name'
							component={InputField}
							isRequiredField
						/>
					) : undefined}
				</div>

				<div className='mt-4 flex items-center justify-center space-x-6'>
					<Button type='button' size='sm' variant='danger' onClick={onDeclineInvitation}>
						Decline
					</Button>
					<Button type='submit' size='sm' variant='success'>
						Accept
					</Button>
				</div>
			</form>
		</FormikProvider>
	)
}

export default InvitationForm
