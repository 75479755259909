import React from 'react'

import { SpinLoader } from 'components/Common'
import Board from './Board'
import FilterForm from './FilterForm'
import useKanbanQuery from './useKanbanQuery'

const KanbanView = () => {
	const { columns, mutate, isLoading } = useKanbanQuery()

	return (
		<React.Fragment>
			<FilterForm />
			{isLoading ? <SpinLoader /> : <Board columns={columns} mutate={mutate} />}
		</React.Fragment>
	)
}

export default KanbanView
