import { useEffect, useState } from 'react'

import { SpinLoader } from 'components/Common'
import { useAppRouter } from 'hooks'
import NoteForm from 'modules/notes/note-form'
import { useLazyGetNoteQuery } from 'modules/notes/notes-api'
import Pages404 from 'pages/StaticPages/Page404'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from '../../utils/checkPermission'

const NoteEdit = () => {
	const [isUpdatePermission] = useState(hasPermission('note:update'))
	const { params } = useAppRouter()
	const id = params?.id

	const [fetchNote, { data, isLoading }] = useLazyGetNoteQuery()
	const note = data?.note

	useEffect(() => {
		if (id && isUpdatePermission) {
			fetchNote(id)
		}
	}, [id])

	return (
		<AppLayout
			meta={{
				title: id ? 'Edit Note' : 'Create Note',
			}}>
			{isUpdatePermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title={id ? 'Edit Note' : 'Create Note'}
							Note
							description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
							hasBackButton={true}
						/>
						<div className='page-body'>
							{isLoading ? (
								<SpinLoader />
							) : note ? (
								<NoteForm note={note} />
							) : (
								<Pages404 />
							)}
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default NoteEdit
