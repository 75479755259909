import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { transformChangedFieldsResponse } from './profile-update'

export const authApi = createApi({
	reducerPath: 'Authentication',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	endpoints: (builder) => ({
		verifyOtp: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.auth.verifyOtp,
				method: 'POST',
				data: payload,
			}),
		}),
		getChangedFields: builder.query({
			query: (token) => ({
				url: `${apiEndpoints.profileInformation}/${token}`,
				method: 'GET',
			}),
			transformResponse: (response) => transformChangedFieldsResponse(response),
		}),
		updateChangedFields: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.profileInformation,
				method: 'POST',
				data: payload,
			}),
		}),
	}),
})

export const {
	useVerifyOtpMutation,
	useLazyGetChangedFieldsQuery,
	useUpdateChangedFieldsMutation,
} = authApi
