import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetUsersQuery } from '../users-api'
import UsersFilters from './users-filters'
import UsersTable from './users-table'
const UsersListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchRoles, { data, isFetching: isLoading }] = useLazyGetUsersQuery()

	useEffect(() => {
		if (location?.search) {
			fetchRoles({
				...parseQueryString,
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<UsersFilters />
			<UsersTable
				data={data?.usersData}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default UsersListingContainer
