import { Header } from 'modules/workflows'
import AssignedWorkflowsContainer from 'modules/workflows/assigned-workflows'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from 'utils/checkPermission'

const AssignedWorkflowsListing = () => {
	const isListPermission = hasPermission('clientWorkflow:list')

	return (
		<AppLayout
			meta={{
				title: 'Assigned Workflows',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<AssignedWorkflowsContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default AssignedWorkflowsListing
