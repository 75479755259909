import { useState } from 'react'

import ClientInviteForm from 'modules/clients/client-invite-form'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from 'utils/checkPermission'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

const ClientInvite = () => {
	const [isInvitePermission] = useState(hasPermission('user:invite'))

	return (
		<AppLayout
			meta={{
				title: 'Invite Client',
			}}>
			{isInvitePermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title='Invite Client'
							description='Effortlessly streamline your client onboarding process with our intuitive invitation form page. Seamlessly invite clients and collect essential information in one smooth flow.'
							hasBackButton={true}
						/>
						<div className='page-body'>
							<ClientInviteForm isForClient={true} />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorizePage />
			)}
		</AppLayout>
	)
}

export default ClientInvite
