import { CreatableSelectField } from 'components/Forms/CreatableSelectFieldComponent'
import { Fragment } from 'react'
import { Field } from 'redux-form'

export default function SystemFieldsArray({ csvFields, dbFieldsOptions }) {
	const onCreateOption = () => {
		return Promise.resolve()
	}

	return (
		<Fragment>
			{csvFields.map((field, idx) => {
				return (
					<Fragment key={idx}>
						<Field type='hidden' name={`fields[${idx}].csv`} component='input' />
						<Field type='hidden' name={`fields[${idx}].index`} component='input' />
						<Field
							label={field?.name}
							name={`fields[${idx}].system`}
							component={CreatableSelectField}
							options={dbFieldsOptions}
							onCreateOption={onCreateOption}
						/>
					</Fragment>
				)
			})}
		</Fragment>
	)
}
