import { Tab } from '@headlessui/react'

import classNames from 'classnames'
import { useMemo } from 'react'
import { Button } from 'theme/ui/forms'
import { getTabs } from '../tab-content'
import {
	getDocumentBoundFields,
	getDocumentTitles,
	getFieldsByUser,
	getFieldsList,
	getFileLabel,
} from '../utils'

const Tab3 = ({ createdBy, individual_document, uploads, user_type }) => {
	const advisorName = createdBy?.first_name + ' ' + createdBy?.last_name
	const UploadFiles = useMemo(
		() => getFileLabel(uploads?.note, user_type),
		[uploads?.note, user_type],
	)
	const documentTitles = useMemo(
		() => getDocumentTitles(individual_document?.documents),
		[individual_document?.documentsd],
	)
	const fieldsArray = useMemo(
		() =>
			getFieldsList(individual_document?.documents).filter(
				(field) => field?.is_document_bound === false,
			),
		[individual_document?.documents],
	)
	const fields = useMemo(() => getFieldsByUser(fieldsArray, user_type), [fieldsArray, user_type])

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individual_document?.documents, user_type),
		[individual_document?.documents],
	)

	const tabs = useMemo(() => getTabs(documentBoundFields.length > 0), [documentBoundFields])

	return (
		<div className='prose max-w-none'>
			<h5 className='text-center'>
				Thank you for all your inputs! Your advisor is now reviewing
			</h5>
			<p className='text-center text-sm text-gray-500'>
				<strong>{advisorName}</strong> is reviewing your submissions. If there are any
				changes required, we&apos;ll get you to resolve them in the next step.
			</p>
			<div className='flex w-full justify-between gap-10'>
				<div className='w-3/5'>
					<span className='mb-2 font-bold'>You provided the following information</span>
					<div className='flex flex-col space-y-6'>
						<div>
							<span className='mt-2 font-semibold'>Uploads</span>
							{UploadFiles.length > 0 ? (
								<ul>
									{UploadFiles.map((item, idx) => (
										<li key={idx}>{item?.file}</li>
									))}
								</ul>
							) : (
								<p className='m-0 text-gray-400'>No file has been uploaded.</p>
							)}
						</div>

						<div>
							<span className='mb-3 font-semibold'>Document Information</span>

							<Tab.Group>
								<Tab.List className='w-full max-w-fit border-b border-slate-200'>
									{tabs.map((tab) => (
										<Tab
											key={tab.id}
											className={({ selected }) =>
												classNames(
													'rounded-t !border !border-b-0 px-3 py-2 text-sm hover:bg-slate-100',
													selected
														? ' border-slate-200 bg-slate-100'
														: 'border-transparent',
												)
											}>
											{tab.label}
										</Tab>
									))}
								</Tab.List>
								<Tab.Panels>
									<Tab.Panel>
										<div className='text-sm'>
											{fields.length > 0 ? (
												<ul>
													{fields.map((field, idx) => (
														<li key={idx}>{field?.label}</li>
													))}
												</ul>
											) : (
												<p className='m-0 text-gray-400'>
													No fields have been filled.
												</p>
											)}
										</div>
									</Tab.Panel>
									<Tab.Panel>
										<div className='text-sm'>
											{documentBoundFields.length > 0 ? (
												<ol>
													{documentBoundFields.map((doc, idx) => (
														<li key={idx}>
															<span className='font-semibold'>
																{doc?.title}
															</span>
															<ul>
																{doc.fields.map((field, idx) => (
																	<li key={idx}>
																		{field?.label}
																	</li>
																))}
															</ul>
														</li>
													))}
												</ol>
											) : (
												<p className='m-0 text-gray-400'>
													No fields have been filled.
												</p>
											)}
										</div>
									</Tab.Panel>
								</Tab.Panels>
							</Tab.Group>
						</div>
					</div>
				</div>

				{documentTitles.length > 0 && (
					<div className='w-2/5'>
						<span className='font-bold'>Documents being populated</span>
						<p className='mt-2 text-sm text-gray-500'>
							The following documents are being populated from the information
							you&apos;ve provided us.
						</p>
						<ol className='text-sm'>
							{documentTitles.map((item, idx) => (
								<li key={idx}>{item?.title}</li>
							))}
						</ol>
					</div>
				)}
			</div>

			<div className='my-4 border-t border-dashed' />
			<div className='actions clearfix'>
				<div className='m-0 flex justify-between'>
					<Button disabled={true}>Previous</Button>
					<Button disabled={true}>Next</Button>
				</div>
			</div>
		</div>
	)
}

export default Tab3
