import Actions from './Actions'

const Heading = ({
	open,
	totalDocuments,
	handleClickEditPackage,
	handleClickAddDocument,
	document,
}) => {
	return (
		<div className='flex w-full items-center justify-between'>
			<div className='flex items-center space-x-4'>
				<span className='font-ibmplex text-lg font-bold'>{document?.title}</span>
				{totalDocuments ? (
					<div className='flex h-6 items-center justify-center space-x-1 rounded !bg-[#F2F5FF] px-2 text-xs text-main'>
						<span>{totalDocuments} Documents</span>
					</div>
				) : undefined}
			</div>
			<div className='flex items-center space-x-4'>
				<Actions
					handleClickEditPackage={handleClickEditPackage}
					handleClickAddDocument={handleClickAddDocument}
					field={document}
				/>
				<div
					className=''
					style={{
						fontSize: 28,
					}}>
					{open ? <i className='uil-angle-down' /> : <i className='uil-angle-up' />}
				</div>
			</div>
		</div>
	)
}

export default Heading
