import { Field } from 'formik'
import { snakeCase } from 'lodash'
import { UncontrolledTooltip } from 'reactstrap'

const DocumentBoundField = ({ fieldName }) => {
	return (
		<Field name={fieldName}>
			{({ field }) => {
				return (
					<div className='flex items-center'>
						<input
							id={fieldName}
							type='checkbox'
							className='form-check-input m-0'
							checked={field?.value}
							onChange={field.onChange}
						/>
						<label htmlFor={fieldName} className='!mb-0 !ml-2 cursor-pointer'>
							Is Document Bound{' '}
						</label>
						<div id={`${snakeCase(fieldName)}_info`} className='ml-1 cursor-pointer'>
							<i className='bx bx-info-circle mb-0 mt-1 text-sm text-gray-400' />
							<UncontrolledTooltip
								placement='top'
								target={`${snakeCase(fieldName)}_info`}>
								Tick this if value of this field is exclusively saved within the
								scope of this document and not at a client&apos;s profile scope.
							</UncontrolledTooltip>
						</div>
					</div>
				)
			}}
		</Field>
	)
}

export default DocumentBoundField
