import { useUser } from 'hooks'
import { useState } from 'react'
import { Field, reduxForm } from 'redux-form'

import ButtonWithLoader from 'components/Common/ButtonWithLoader'
import DocumentPreview from 'components/Common/DocumentPreview'
import InputCountrySelect from 'components/Forms/InputCountrySelect'
import { TextField } from 'components/Forms/TextFieldComponent'
import { FileUploadField } from 'theme/ui/forms'
import { phoneRequired, requiredName } from 'utils/formValidation'
import SwitchField from './switch-field'

const validate = {
	first_name: requiredName('First Name'),
	last_name: requiredName('Last Name'),
	phone_number: phoneRequired('Phone Number'),
}

const ProfileForm = ({ handleSubmit, loading, handleUpload, avatarRef, upLoader, user }) => {
	const { group } = useUser()
	const [isOpen, setIsOpen] = useState(false)
	const [url, setUrl] = useState(null)

	const toggle = async (url) => {
		setIsOpen(!isOpen)
		setUrl(url)
	}

	return (
		<form onSubmit={handleSubmit} className='flex flex-col divide-y divide-dashed'>
			<div className='justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Personal Information</h3>
					<p className='text-sm text-gray-500'>
						Manage Your Identity: Update, Edit, and Secure Your Personal Information
						with Ease.
					</p>
				</div>
				<div className='not-prose grid grid-cols-1 gap-x-6 sm:min-w-[400px]'>
					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='first_name'
							component={TextField}
							label='First Name *'
							validate={validate.first_name}
						/>

						<Field
							type='text'
							name='last_name'
							component={TextField}
							label='Last Name *'
							validate={validate.last_name}
						/>
					</div>

					<Field
						type='text'
						name='phone_number'
						component={InputCountrySelect}
						label='Phone Number *'
						validate={validate.phone_number}
					/>

					<Field
						type='text'
						name='email'
						component={TextField}
						label='Email *'
						disabled
					/>

					<FileUploadField
						label='Upload a Picture'
						ref={avatarRef}
						isUploading={upLoader}
						onUpload={handleUpload}
						allowedExtentions='.apng,.avif,.gif,.jpg,.jpeg,.pjpeg,.png,.webp'
					/>

					<div className='form-group'>
						<label>Role</label>
						<input
							type='text'
							disabled
							defaultValue={group?.name}
							className='form-control'
						/>
					</div>
				</div>
			</div>

			<div className='justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Address Information</h3>
					<p className='text-sm text-gray-500'>
						Location at Your Fingertips: Seamlessly Update and Organize Your Address
						Information for Convenient Access.
					</p>
				</div>
				<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px]'>
					<Field
						type='text'
						name='userProfile.address_1'
						component={TextField}
						label='Address line 1'
					/>

					<Field
						type='text'
						name='userProfile.address_2'
						component={TextField}
						label='Address line 2'
					/>

					<Field type='text' name='userProfile.unit' component={TextField} label='Unit' />

					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='userProfile.city'
							component={TextField}
							label='City'
						/>

						<Field
							type='text'
							name='userProfile.province'
							component={TextField}
							label='Province'
						/>
					</div>

					<div className='grid grid-cols-2 gap-x-6'>
						<Field
							type='text'
							name='userProfile.zip_code'
							component={TextField}
							label='Zip Code'
						/>

						<Field
							type='text'
							name='userProfile.country'
							component={TextField}
							label='Country'
						/>
					</div>
				</div>
			</div>

			<div className='items-center justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Multi-Factor Authentication</h3>
					<p className='text-sm text-gray-500'>
						Multi-Factor Authentication (MFA) settings enable individuals to configure
						additional layers of security.
					</p>
				</div>
				<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px] sm:max-w-[500px]'>
					<div className='flex h-fit w-full items-center justify-between space-x-3 rounded-md !border border-gray-300 p-3'>
						<span className='text-sm text-gray-500'>
							Toggle the switch to enable or disabled the Multi-Factor Authentication
						</span>
						<Field type='checkbox' name='isMfaEnabled' component={SwitchField} />
					</div>
				</div>
			</div>

			<div className='justify-between gap-10 py-6 sm:flex'>
				<div className='prose my-3 max-w-none'>
					<h3>Other Information</h3>
					<p className='text-sm text-gray-500'>Your ID Information for Added Safety</p>
				</div>
				<div className='grid grid-cols-1 gap-x-6 sm:min-w-[400px] sm:max-w-[500px]'>
					<div className='form-group mb-3'>
						<label>SIN</label>
						{user?.sin_number ? (
							<input
								type='text'
								disabled
								defaultValue={user?.sin_number}
								className='form-control'
							/>
						) : (
							<p className='text-xs text-gray-500'>(No information found)</p>
						)}
					</div>

					<div className='form-group mb-3'>
						<label>Valid Document</label>
						{user?.valid_document ? (
							<>
								<br />
								<button
									type='button'
									className='btn btn-sm text-primary underline'
									onClick={() => toggle(user?.valid_document)}>
									<span>View now</span>
								</button>
							</>
						) : (
							<p className='text-xs text-gray-500'>(No information found)</p>
						)}
					</div>

					<div className='form-group mb-3'>
						<label>Government Document</label>

						{user?.government_document ? (
							<>
								<br />
								<button
									type='button'
									className='btn btn-sm text-primary underline'
									onClick={() => toggle(user?.government_document)}>
									<span>View now</span>
								</button>
							</>
						) : (
							<p className='text-xs text-gray-500'>(No information found)</p>
						)}
					</div>
				</div>
			</div>

			<DocumentPreview url={url} toggle={toggle} isOpen={isOpen} />
			<div className='flex justify-end py-6'>
				<ButtonWithLoader loading={loading} color='primary' type='submit'>
					Update
				</ButtonWithLoader>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'user_profile',
	enableReinitialize: true,
})(ProfileForm)
