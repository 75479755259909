import { Fragment } from 'react'
import { Button, Spinner } from 'reactstrap'

const ButtonWithLoader = (props) => {
	const { loading, type = 'button', color = 'primary', className, children, ...rest } = props
	return (
		<Fragment>
			{loading ? (
				<Button color={color} type='button' disabled className={className} {...rest}>
					<Spinner size='sm' />
					&nbsp;&nbsp;Loading...
				</Button>
			) : (
				<Button color={color} type={type} className={className} {...rest}>
					{children}
				</Button>
			)}
		</Fragment>
	)
}

export default ButtonWithLoader
