import AssignedWorkflowsContainer from './assigned-workflows-container'
import { ASSIGN_WORKFLOW_API_ROUTES } from './routes'

export const getWorkflowUrl = (defaultGroupName) => {
	return defaultGroupName === 'client'
		? ASSIGN_WORKFLOW_API_ROUTES.client.findAll()
		: ASSIGN_WORKFLOW_API_ROUTES.advisor.findAll()
}

export default AssignedWorkflowsContainer
