import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import DataTable from 'theme/ui/data-table'
import StyledBadge from 'theme/ui/styled-badge'
import { statusColor } from '.'
import Actions from './actions'

const InvitedClientsTable = ({ clients, meta, isLoading, pagination, setPagination }) => {
	const [actionActiveId, setActionActiveId] = useState(null)
	const [sorting, setSorting] = useState([])
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row, {
			id: 'name',
			header: () => <span>Name</span>,
			cell: (info) => {
				return (
					<span className='line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'>
						{info.getValue()?.full_name}
					</span>
				)
			},
		}),

		columnHelper.accessor('email', {
			header: () => <span>Email</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),

		columnHelper.accessor('status', {
			header: () => <span>Status</span>,
			cell: (info) => (
				<div className='inline-block'>
					<StyledBadge color={statusColor[info.getValue()]}>
						{info.getValue()}
					</StyledBadge>
				</div>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'created_at',
			header: () => <span>Start date</span>,
			cell: (info) => (
				<Actions
					id={info.getValue()?.id}
					client={info.getValue()}
					created_at={info.getValue()?.created_at}
					isActionActive={actionActiveId === info?.row?.id}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={clients}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default InvitedClientsTable
