import noImage from 'assets/images/no-image.png'
import { Card } from 'reactstrap'
import { Button } from 'theme/ui/forms'

const FilePreview = ({ selectedFile, selected, setselected, fileType }) => {
	return (
		<Card className='dz-processing dz-image-preview dz-success dz-complete mb-0 mt-1 border shadow-none'>
			<div className='p-2'>
				<div className='flex items-center justify-between'>
					<div className='w-4/5'>
						<div className='flex items-center space-x-3'>
							<div>
								<img
									data-dz-thumbnail=''
									height='80'
									className='avatar-sm bg-light rounded'
									alt={selectedFile.name}
									src={selectedFile.preview}
									onError={(i) => (i.target.src = noImage)}
								/>
							</div>
							<div className='flex flex-col'>
								<span
									className='line-clamp-1 text-sm font-semibold'
									title={selectedFile.name}>
									{selectedFile.name}
								</span>
								<span className='text-xs text-gray-500'>
									{selectedFile.formattedSize}
								</span>
							</div>
						</div>
					</div>
					<div className='w-1/5 text-center'>
						<Button
							type='button'
							size='xs'
							variant='danger-outline'
							title='Remove'
							onClick={() => {
								let newFiles = [...selected]
								const index = selected.findIndex((item) => item?.type === fileType)
								newFiles.splice(index, 1)
								setselected(newFiles)
							}}>
							<i className='remove-file uil uil-trash-alt text-danger cursor-pointer' />
						</Button>
					</div>
				</div>
			</div>
		</Card>
	)
}

export default FilePreview
