import { CompletedDocumentsContainer } from 'modules/documents'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from '../../utils/checkPermission'
import UnAuthorize from '../StaticPages/UnAuthorize'

const CompletedDocumentsListing = () => {
	const isListPermission = hasPermission('completedDocument:list')

	return (
		<AppLayout
			meta={{
				title: 'Completed Documents',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<Header />
						<div className='page-body'>
							<CompletedDocumentsContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default CompletedDocumentsListing
