import ManageWorkflow from 'pages/Authentication/profile/components/workflow'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from 'utils/checkPermission'

const AssignedWorkflowManage = () => {
	const hasReadPermission = hasPermission('clientWorkflow:read')

	return (
		<AppLayout
			meta={{
				title: 'Fill Assigned Workflows',
			}}>
			{hasReadPermission ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<PageHeader
							title='Filling out the workflow'
							hasBackButton={true}
							description='Efficiently complete tasks with streamlined workflow filling. Enhance productivity through seamless process execution.'
						/>
					</div>
					<div className='page-body'>
						<ManageWorkflow />
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default AssignedWorkflowManage
