import { snakeCase } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'

import { Loader } from 'components/Common'
import useStepsQuery from 'pages/Authentication/client-document-upload/components/useStepsQuery'
import { useQuery } from 'utils/commonFunctions'
import { add } from 'utils/storageService'

const ProtectedRoutes = () => {
	const location = useLocation()
	const { token, next } = useQuery(location?.search)
	useEffect(() => {
		if (token) {
			add('token', token)
		}
	}, [token])

	const { defaultGroup, isSuperAdmin } = useSelector((state) => state.Permissions)
	const currentRole = defaultGroup?.group
	const CLIENT_ROLE = 'client'
	const PENDING = 'pending'
	const isClient = snakeCase(currentRole?.name) === CLIENT_ROLE

	const { data: steps, isLoading } = useStepsQuery()

	const isOnBoarded = useMemo(() => {
		if (Array.isArray(steps)) {
			const _steps = steps.filter((step) => step?.status === PENDING)
			return _steps.length === 0 ? true : false
		} else {
			return true
		}
	}, [steps])

	if (isLoading) {
		return <Loader />
	} else if (!isSuperAdmin && isClient && !isOnBoarded) {
		return <Redirect to={{ pathname: '/onboarding/required-document-list' }} />
	} else if (isClient) {
		return <Redirect to={{ pathname: '/assigned-workflows' }} />
	} else {
		if (next) {
			return <Redirect to={{ pathname: next }} />
		} else {
			return <Redirect to={{ pathname: '/dashboard' }} />
		}
	}
}

export default ProtectedRoutes
