import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import DataTable from 'theme/ui/data-table'
import { NOTIFICATIONS_TEMPLATES_APP_ROUTES } from '../routes'

const NotificationsTemplatesTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const [sorting, setSorting] = useState([])
	const columnHelper = createColumnHelper()
	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => <p className='m-0 p-0 font-bold'>{info.getValue().name}</p>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'message',
			size: 300,
			header: () => <span>Message</span>,
			cell: (info) => (
				<p className='m-0 line-clamp-1 p-0' title='info.getValue().message'>
					{info.getValue().message}
				</p>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => <span>Actions</span>,
			cell: (info) => (
				<div className='flex'>
					<Link
						to={NOTIFICATIONS_TEMPLATES_APP_ROUTES.findOne(info.getValue()?.id)}
						className='action-btn btn-gray-outline'>
						<i className='far fa-edit' />
						<span>Edit</span>
					</Link>
				</div>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
		/>
	)
}

export default NotificationsTemplatesTable
