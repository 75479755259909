import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

const PageHeader = ({
	title,
	description,
	hasBackButton,
	children,
	headerRowClassName,
	redirectTo,
}) => {
	const history = useHistory()

	const handleBackClick = () => {
		if (redirectTo) {
			history.push(redirectTo)
		} else {
			history.goBack()
		}
	}

	return (
		<div className='page-header bg-white'>
			<div className={classNames('flex w-full justify-between', headerRowClassName)}>
				<div className='max-w-[733px]'>
					{hasBackButton ? (
						<button
							className='mb-2 flex items-center text-base font-normal not-italic leading-5 text-main'
							onClick={() => handleBackClick()}>
							<i className='uil-angle-left text-3xl' />
							<span>Back</span>
						</button>
					) : undefined}

					{title ? <h4 className='page-title'>{title}</h4> : undefined}
					{description ? (
						<p className='text-xs text-[#74788D]'>{description}</p>
					) : undefined}
				</div>

				<div>{children}</div>
			</div>
		</div>
	)
}

PageHeader.defaultProps = {
	headerRowClassName: '',
}

export default PageHeader
