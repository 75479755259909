import axios from 'axios'
import { Fragment, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Progress, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'

import noImage from 'assets/images/no-image.png'
import { ButtonWithLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import { validateFileType } from 'theme/ui/forms/file-upload-field'
import { sanitizeImage } from 'theme/ui/forms/file-upload-field/image-validation'
import { sanitizePdf } from 'theme/ui/forms/file-upload-field/pdf-validation'
import notification from 'utilities/notification'
import {
	findObjectByField,
	formatBytes,
	showErrorToast,
	showSuccessToast,
	toSnakeCase,
} from 'utils/commonFunctions'
import { getCorrectionFileLabel } from '../../utils'

const Tab1 = ({ uploads, handleSubmit, workflow_id, toggleCorrection, activeCorrection }) => {
	const [selectedFiles, setselectedFiles] = useState([])
	const [progress, setProgress] = useState(0)
	const [loader, setLoader] = useState(false)

	const files = getCorrectionFileLabel(uploads?.files)
	const workflow_step_id = uploads?.id
	const filesID = files

	const fileOptions = files.map((file) => ({
		label: file?.title,
		value: toSnakeCase(file?.title),
	}))

	const form = useSelector((state) => state.form.workflow_step_1_correction)
	const uploadFiles = form?.values?.file_titles ? form?.values?.file_titles : []

	let filesUploaded = []
	if (uploadFiles && uploadFiles.length > 0) {
		for (let i = 0; i < uploadFiles.length; i++) {
			filesUploaded.push(uploadFiles[i]?.value)
		}
	}

	const handleFileUplaod = (files) => {
		const formData = new FormData()
		formData.append('workflow_id', workflow_id)
		formData.append('workflow_step_id', workflow_step_id)
		formData.append('save_type', 'in_review')
		for (let i = 0; i < files.length; i++) {
			const fileObj = findObjectByField(fileOptions, 'value', files[i]?.type)
			const file_id =
				filesID.find((file) => toSnakeCase(file?.title) === files[i]?.type)?.id || 0
			formData.append('files[]', files[i].file)
			formData.append('file_titles[]', fileObj.label)
			formData.append('deleted_files[]', file_id)
		}

		setLoader(true)
		axios
			.post(`${apiEndpoints.client.workflowDetails}/file`, formData, {
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total,
					)
					setProgress(percentCompleted)
				},
			})
			.then((response) => {
				setLoader(false)
				if (response?.data?.status === 200) {
					showSuccessToast(response?.data?.message)

					toggleCorrection(activeCorrection + 1)
				}
			})
			.catch((error) => {
				setLoader(false)
				const message = error?.response?.data?.message || error?.message
				showErrorToast(message)
			})
	}

	const submit = () => {
		if (selectedFiles.length < files.length) {
			return showErrorToast(`Please upload all ${files.length} files `)
		}

		if (files.length === 0) {
			return toggleCorrection(activeCorrection + 1)
		}

		return handleFileUplaod(selectedFiles)
	}

	const onDrop = (file, type) => {
		const files = [...selectedFiles]
		files.push({ type: toSnakeCase(type), file: file[0] })
		setselectedFiles(files)
	}

	const accept = ['.png', '.jpg', '.jpeg', '.pdf']

	return (
		<Fragment>
			<form onSubmit={handleSubmit((values) => submit(values))}>
				{files.length > 0 ? <h6>Re-Upload the following files</h6> : undefined}

				{uploads?.review_notes && (
					<div className='my-2'>
						<p>
							<strong>Note :- </strong> &nbsp; {uploads?.review_notes}
						</p>
					</div>
				)}
				<div className='mb-3'>
					{files.length > 0 ? (
						files.map((file, idx) => {
							const fileType = toSnakeCase(file?.title)
							const selectedFile =
								findObjectByField(selectedFiles, 'type', fileType)?.file ||
								undefined
							if (selectedFile) {
								Object.assign(selectedFile, {
									preview: URL.createObjectURL(selectedFile),
									formattedSize: formatBytes(selectedFile.size),
								})
							}

							return (
								<Fragment key={idx}>
									<div className='row justify-content-center mb-3'>
										<div className='col-sm-5 p-4'>
											<h5>
												{idx + 1}. {file?.title}
											</h5>
											<div
												className='dropzone-previews mt-3'
												id='file-previews'>
												{selectedFile ? (
													<Card className='dz-processing dz-image-preview dz-success dz-complete mb-0 mt-1 border shadow-none'>
														<div className='p-2'>
															<Row className='align-items-center'>
																<Col className='col-7'>
																	<Row className='align-items-center'>
																		<Col className='col-auto'>
																			<img
																				data-dz-thumbnail=''
																				height='80'
																				className='avatar-sm bg-light rounded'
																				alt={
																					selectedFile.name
																				}
																				src={
																					selectedFile.preview
																				}
																				onError={(i) =>
																					(i.target.src =
																						noImage)
																				}
																			/>
																		</Col>
																		<Col>
																			<Link
																				to='#'
																				className='text-muted font-weight-bold'>
																				{selectedFile.name}
																			</Link>
																			<p className='mb-0'>
																				<strong>
																					{
																						selectedFile.formattedSize
																					}
																				</strong>
																			</p>
																		</Col>
																	</Row>
																</Col>
																<Col className='col-1'>
																	<i
																		className='remove-file uil uil-trash-alt text-danger font-size-xl cursor-pointer'
																		title='Remove'
																		onClick={() => {
																			let newFiles = [
																				...selectedFiles,
																			]
																			const index =
																				selectedFiles.findIndex(
																					(item) =>
																						item?.type ===
																						fileType,
																				)
																			newFiles.splice(
																				index,
																				1,
																			)
																			setselectedFiles(
																				newFiles,
																			)
																		}}
																	/>
																</Col>
															</Row>
														</div>
													</Card>
												) : (
													<p className='text-muted'>No file selected</p>
												)}
											</div>
										</div>
										<div className='col-sm-4'>
											{!selectedFile ? (
												<Dropzone
													// onDrop={(files) => onDrop(files, file?.title)}
													onDrop={async (
														acceptedFiles,
														rejectedFiles,
													) => {
														try {
															const rejectedFile =
																rejectedFiles[0]?.errors[0] || null
															if (rejectedFile) {
																throw new Error(
																	rejectedFile?.message,
																)
															}
															const $file = acceptedFiles[0]
															const [type, subtype] =
																$file.type.split('/')
															await validateFileType($file, accept)

															if (type === 'image') {
																await sanitizeImage($file)
															}

															if (
																type === 'application' &&
																subtype === 'pdf'
															) {
																await sanitizePdf($file)
															}

															onDrop(acceptedFiles, file?.title)
														} catch (error) {
															notification('error', error?.message)
														}
													}}
													accept={['.png', '.jpg', '.jpeg', '.pdf']}>
													{({ getRootProps, getInputProps }) => (
														<div className='dropzone client-workflow-dropzone'>
															<div
																className='dz-message needsclick'
																{...getRootProps()}>
																<input
																	{...getInputProps()}
																	multiple={false}
																/>
																<div className='mb-3'>
																	<i className='display-4 text-muted uil uil-cloud-upload' />
																</div>
																<h6 className='m-3'>
																	Drop your file here or click to
																	upload.
																</h6>
																<p className='text-sm text-gray-500'>
																	<span>Supports:</span>{' '}
																	<span>jpeg, jpg, png, pdf</span>
																</p>
															</div>
														</div>
													)}
												</Dropzone>
											) : undefined}
										</div>
									</div>
									{1 + idx < files.length && <hr />}
								</Fragment>
							)
						})
					) : (
						<p className='mt-3 text-center text-sm text-gray-500'>
							There is no requirement to upload files at this location. You can
							proceed by clicking the &quot;Next&quot; button.
						</p>
					)}
				</div>
				{progress > 0 && (
					<Progress color={progress === 100 ? 'success' : 'primary'} value={progress}>
						{progress} %
					</Progress>
				)}
				<div className='actions clearfix border-t border-dashed px-4'>
					<div className='mt-4 flex justify-between'>
						<Button color='primary' disabled={true}>
							Previous
						</Button>
						<ButtonWithLoader color='primary' type='submit' loading={loader}>
							Next
						</ButtonWithLoader>
					</div>
				</div>
			</form>
		</Fragment>
	)
}

const Tab1Form = reduxForm({
	form: 'workflow_step_1_correction',
	enableReinitialize: true,
})(Tab1)
export default Tab1Form
