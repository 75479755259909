import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

// import editIcon from 'assets/svg/edit-icon.svg'
// import editPenIcon from 'assets/svg/edit-pen-icon.svg'
import hasPermission from 'utils/checkPermission'
import { routes } from '../..'
import DeleteConfirmAlert from './delete-confirm-alert'

const Actions = ({ workflow, last_updated, isActionActive }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const ID = workflow?.id
	const option = useMemo(() => ({ id: workflow?.id, title: workflow?.title }), [workflow])
	const isUpdatePermission = hasPermission('workflow:update')

	return (
		<div className='w-[250px]'>
			<DeleteConfirmAlert isOpen={isOpen} onClose={onClose} id={ID} />
			{isActionActive ? (
				<div className='flex space-x-2'>
					{isUpdatePermission && (
						<Link to={routes.edit(ID)} className='action-btn btn-gray-outline'>
							{/* <img src={editPenIcon} /> */}
							<i className='fas fa-edit' style={{ fontSize: 15 }} />
							<span>Edit</span>
						</Link>
					)}
					<Link to={routes.assign(option)} className='action-btn btn-gray'>
						{/* <img src={editIcon} /> */}
						<i className='uil-clipboard-notes' style={{ fontSize: 17 }} />
						<span>Assign</span>
					</Link>
					<button
						type='button'
						className='action-btn btn-red-outline'
						onClick={() => setIsOpen(true)}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button>
				</div>
			) : (
				<span className=' text-sm  !text-gray-700'>{last_updated}</span>
			)}
		</div>
	)
}

export default Actions
