import apiEndpoints from '../../helpers/apiEndpoints'
import { handleCommonError } from '../../helpers/handleCommonError'
import store from '../../store'
import * as apiAction from '../../store/common/actions'
import { findObjectByField } from '../../utils/commonFunctions'
import { resetPermissions, setDefaultGroup, userPermissions } from '../permissions/actions'
import * as requestFromServer from './cruds'
import { JwtAuthSlices } from './slices'

const { actions } = JwtAuthSlices

export const login =
	(url, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.login(url, data, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response?.status !== 200) {
					return false
				}

				// dispatch(actions.login(response?.data?.user))
				return response?.data
			})
			.catch((error) => {
				dispatch(actions.endCall({}))
				handleCommonError(error, dispatch)
			})
	}

export const logout = (url, config) => (dispatch) => {
	dispatch(actions.startCall({}))

	return requestFromServer
		.logout(url, config)
		.then((response) => {
			dispatch(actions.endCall({}))
			if (response?.status !== 200) {
				return false
			}

			dispatch(actions.logout({}))
			dispatch(resetPermissions(null))
			return response?.data
		})
		.catch((error) => {
			dispatch(actions.endCall({}))
			handleCommonError(error, dispatch)
		})
}

export const getPermissions = () => (dispatch) => {
	return dispatch(apiAction.permissionCall(apiEndpoints.auth.permissions))
		.then((response) => {
			if (response && response.status === 200 && response?.user) {
				// eslint-disable-next-line no-unsafe-optional-chaining
				const defaultGroup = store?.getState()?.Permissions?.defaultGroup

				if (Array.isArray(response?.user?.groups) && response?.user?.groups.length > 0) {
					const group = findObjectByField(response?.user?.groups, 'id', defaultGroup?.id)
					if (group) {
						dispatch(setDefaultGroup(group))
					}
				}

				dispatch(
					userPermissions({
						isOnBoarded: response?.onBoardStatus,
						user: response?.user,
					}),
				)
				return response
			}
		})
		.catch(() => {
			// console.log()
			// return error
		})
}

export const register =
	(url, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.register(url, data, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response?.status !== 200) {
					return false
				}

				return response?.data
			})
			.catch((error) => {
				dispatch(actions.endCall({}))
				handleCommonError(error, dispatch)
				return error
			})
	}

export const forgotPassword =
	(url, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.forgotPassword(url, data, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response?.status !== 200) {
					return false
				}

				return response?.data
			})
			.catch((error) => {
				dispatch(actions.endCall({}))
				handleCommonError(error, dispatch)
				return error
			})
	}

export const resetPassword =
	(url, data, config = {}) =>
	(dispatch) => {
		dispatch(actions.startCall({}))

		return requestFromServer
			.resetPassword(url, data, config)
			.then((response) => {
				dispatch(actions.endCall({}))
				if (response?.status !== 200) {
					return false
				}

				return response?.data
			})
			.catch((error) => {
				dispatch(actions.endCall({}))
				handleCommonError(error, dispatch)
				return error
			})
	}

export const saveUserData = (data) => (dispatch) => {
	dispatch(actions.login(data))
}

export const logoutFromStore = () => (dispatch) => {
	return new Promise((resolve) => {
		dispatch(actions.logout({}))
		dispatch(resetPermissions(null))

		resolve(true)
	})
}
