import SignatureTab from './signature-tab'

export const steps = [
	{
		dataElement: 'zoomOverlayButton',
		header: 'Zoom',
		text: 'You can adjust the preview of pdf from here.',
	},
	{
		dataElement: 'toolbarGroup-Forms',
		header: 'Signature field',
		text: 'You can add signature field by enable the form tool.',
	},
	{
		dataElement: 'save-button',
		header: 'Save document',
		text: 'After adding signature field you have to save the document by clicking on save button.',
	},
]

export const endFormFieldCreationMode = (formFieldCreationManager) => {
	return new Promise((resolve) => {
		// formFieldCreationManager.applyFormFields()
		// if (formFieldCreationManager.isInFormFieldCreationMode()) {
		// }
		formFieldCreationManager.endFormFieldCreationMode()
		resolve()
	})
}

export const signatureViewDisabledElement = [
	'menuButton',
	'leftPanelButton',
	'viewControlsButton',
	'panToolButton',
	'selectToolButton',
	'toolbarGroup-Shapes',
	'toolbarGroup-Edit',
	'toolbarGroup-Insert',
	'toolbarGroup-Annotate',
	'toolbarGroup-View',
	'toolbarGroup-FillAndSign',
	'toolbarGroup-EditText',
	'searchButton',
	'toggleNotesButton',
	'highlightToolGroupButton',
	'underlineToolGroupButton',
	'strikeoutToolGroupButton',
	'squigglyToolGroupButton',
	'stickyToolGroupButton',
	'markInsertTextGroupButton',
	'markReplaceTextGroupButton',
	'shapeToolGroupButton',
	'freeHandToolGroupButton',
	'freeHandHighlightToolGroupButton',
	'freeTextToolGroupButton',
	'eraserToolButton',
	'checkBoxFieldToolGroupButton',
	'radioButtonFieldToolGroupButton',
	'listBoxFieldToolGroupButton',
	'comboBoxFieldToolGroupButton',
	'textFieldToolGroupButton',
	// "toolsOverlay",
	// "signatureFieldToolButton",
	// "signatureFieldToolButton2",
	// "signatureFieldToolButton3",
	// "signatureFieldToolButton4",
	'applyFormFieldButton',
]

export default SignatureTab
