import { Fragment } from 'react'
import { FieldArray } from 'redux-form'
import SystemFieldsArray from './system-fields-array'

const FieldMappingList = ({ csvFields, dbFieldsOptions }) => {
	return (
		<Fragment>
			<div className='row justify-content-between'>
				<div className='col-sm-4 rounded bg-white p-3'>
					{csvFields.map((field, idx) => (
						<Fragment key={idx}>
							<div className='form-group mb-3'>
								<label>{field.name}</label>
								<input
									type='text'
									className='form-control'
									value={field?.name}
									readOnly
								/>
							</div>
						</Fragment>
					))}
					{/* <FieldArray
                        type='text'
                        component={CSVFieldsArray}
                        name='csv_fields'
                        csvFields={csvFields}
                    /> */}
				</div>

				<div className='col-sm-4 rounded bg-white p-3'>
					<FieldArray
						type='select'
						component={SystemFieldsArray}
						name='fields'
						csvFields={csvFields}
						dbFieldsOptions={dbFieldsOptions}
					/>
				</div>
			</div>
		</Fragment>
	)
}

export default FieldMappingList
