import { Field } from 'formik'
import { TextareaField } from 'theme/ui/forms'
import FinalReviewerDropdown from '../final-reviewer-dropdown'
import ReviewerDropdown from '../reviewer-dropdown'

const SecondStep = ({
	documents,
	formValues,
	upload_files,
	advisor_reviewer,
	document_packages,
}) => {
	return (
		<div className='form-container'>
			<div className='form-row'>
				<div className='form-heading'>
					<h3>Workflow Reviewers</h3>
					<p>
						Select the reviewers responsible for evaluating the values in the workflow.
					</p>
				</div>

				<div className='form-fields-card'>
					<div className='flex flex-col space-y-6'>
						<FinalReviewerDropdown
							formValues={formValues}
							advisor_reviewer={advisor_reviewer}
						/>
						<ReviewerDropdown
							formValues={formValues}
							advisor_reviewer={advisor_reviewer}
						/>
					</div>
				</div>
			</div>

			{documents.length > 0 ? (
				<div className='form-row'>
					<div className='form-heading'>
						<h3>Documents Populated</h3>
						<p>The populated documents are included within this workflow.</p>
					</div>

					<div className='form-fields-card'>
						<ul className='m-0 list-disc font-medium'>
							{documents.map((document, idx) => (
								<li key={idx}>{document?.title}</li>
							))}
						</ul>
					</div>
				</div>
			) : undefined}

			{document_packages.length > 0 ? (
				<div className='form-row'>
					<div className='form-heading'>
						<h3>Document Packages Populated</h3>
						<p>The populated document packages are included within this workflow.</p>
					</div>

					<div className='form-fields-card'>
						<ul className='m-0 list-disc font-medium'>
							{document_packages.map((doc_package, idx) => (
								<li key={idx}>{doc_package?.title}</li>
							))}
						</ul>
					</div>
				</div>
			) : undefined}

			{upload_files.length > 0 ? (
				<div className='form-row'>
					<div className='form-heading'>
						<h3>File Upload Request</h3>
						<p>
							The following files will be required for upload during the workflow
							fulfillment process.
						</p>
					</div>

					<div className='form-fields-card'>
						<ul className='m-0 list-disc'>
							{upload_files.map((file, idx) => (
								<li key={idx}>
									{file?.file} for{' '}
									<span className='font-bold capitalize'>{file?.user_type}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
			) : undefined}

			<div className='form-row'>
				<div className='form-heading'>
					<h3>Notes</h3>
					<p>Input notes to provide users with additional information.</p>
				</div>

				<div className='form-fields-card'>
					<div className='flex flex-col space-y-6'>
						<Field
							label='Notes'
							type='select'
							name='notes'
							component={TextareaField}
							placeholder='Add a note if needed about the workflow for the client...'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SecondStep
