import WebViewer from '@pdftron/webviewer'
import { Fragment, useEffect, useRef } from 'react'

import { documentEventLog } from 'modules/document-event-log'
import { useCreateDocumentEventLogMutation } from 'modules/document-event-log/document-event-log-api'
import { useLazyGetSettingQuery } from 'modules/settings/settings-api'
import { useSetDocumentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import notification from 'utilities/notification'
import { disabledElements } from './config'

const PdfPreview = (props) => {
	const { url, documentId } = props
	const viewer = useRef(null)
	const [createDocumentEventLog] = useCreateDocumentEventLogMutation()
	const [getSetting] = useLazyGetSettingQuery()
	const setDocumentViewer = useSetDocumentViewer()

	const webviewerInstance = async (fileUrl) => {
		const response = await getSetting('pdf_tron_apikey')
		const settingValue = response?.data?.setting
		try {
			if (settingValue?.status === 200 && settingValue?.data?.setting_value) {
				const instance = await WebViewer(
					{
						path: '/lib',
						initialDoc: fileUrl,
						fullAPI: true,
						disabledElements: disabledElements,
						licenseKey: settingValue?.data?.setting_value,
					},
					viewer.current,
				)
				setDocumentViewer(instance)
				return instance
			}
		} catch (error) {
			notification(error)
		}
	}

	useEffect(() => {
		if (documentId) {
			createDocumentEventLog({
				document_id: documentId,
				event_type: documentEventLog.eventTypes.DocumentPreview,
				module_type: documentEventLog.moduleTypes.DocumentTemplateCreation,
			})
		}
	}, [documentId])

	useEffect(() => {
		if (url !== '') {
			webviewerInstance(url)
		}

		return () => {
			viewer.current = null
		}
	}, [url])

	return (
		<Fragment>
			<div className='MyComponent'>
				<div className='webviewer' ref={viewer} style={{ height: '100vh' }}></div>
			</div>
		</Fragment>
	)
}

export default PdfPreview
