import Header from 'modules/clients/header'
import OnboardedClientsContainer from 'modules/clients/onboarded-clients'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from 'utils/checkPermission'

const ClientsListing = () => {
	const isListPermission = hasPermission('client:list')

	return (
		<AppLayout
			meta={{
				title: 'Onboarded Clients',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<OnboardedClientsContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default ClientsListing
