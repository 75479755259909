import { useMemo } from 'react'

import { useQuery } from 'hooks'

import { formatDate } from 'utilities/helpers'
import { OWNER_INVITES_API_ROUTES } from '../routes'

const OwnerInvitesQuery = (pagination) => {
	const { pageSize, pageIndex } = pagination
	const URL = useMemo(
		() => `${OWNER_INVITES_API_ROUTES.findAll()}?page=${pageIndex + 1}&limit=${pageSize}`,
		[pageSize, pageIndex],
	)
	const { data, isLoading } = useQuery(URL)

	const invites = useMemo(() => {
		return Array.isArray(data?.data?.inviteTeamOwners)
			? data?.data?.inviteTeamOwners.map((invite) => ({
					id: invite?.id,
					email: invite?.email,
					role: { name: invite?.group?.name, id: invite?.group?.id },
					invited_at: formatDate(invite?.created_at),
					expired_at: formatDate(invite?.invitation_expired_at),
			  }))
			: []
	}, [data?.data?.inviteTeamOwners])

	return {
		invites,
		meta: data?.data?.meta,
		isLoading,
	}
}

export default OwnerInvitesQuery
