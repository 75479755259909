// import { useMemo } from 'react'
// import { useSelector } from 'react-redux'
// import { useDebounce } from 'use-debounce'

// import apiEndpoints from 'helpers/apiEndpoints'
// import { useQuery } from 'hooks'
// import { toQueryString } from 'utils/commonFunctions'

const usePortfolioViewQuery = () => {
	// const values = useSelector((state) => state.form?.relation_view_filter?.values)
	// const [debounceSearch] = useDebounce(values?.search, 600)
	// const { pageIndex, pageSize } = pagination

	// const filters = useMemo(() => {
	// 	const obj = { page: pageIndex + 1, limit: pageSize }
	// 	if (debounceSearch) {
	// 		Object.assign(obj, { search: debounceSearch })
	// 	}

	// 	return obj
	// }, [values, debounceSearch, pageIndex, pageSize])

	// const query = toQueryString(filters)

	// const URL = `${apiEndpoints.clientSetting}/user/${client?.id}?${query}`
	// const { data, isLoading, mutate } = useQuery(URL)

	// const relationData = data?.data?.[0]

	// const relations = useMemo(
	// 	() =>
	// 		Array.isArray(relationData)
	// 			? relationData.map((item) => ({
	// 					id: item?.id,
	// 					key: item?.user_key,
	// 					value: item?.user_value,
	// 			  }))
	// 			: [],
	// 	[relationData],
	// )

	return {
		data: [],
		meta: {
			currentPage: 1,
			limit: 10,
			totalPages: 1,
			totalRecords: 10,
			hasNextPage: true,
			hasPrevPage: false,
			nextPage: 1,
			prevPage: null,
		},
		isLoading: false,
	}
}

export default usePortfolioViewQuery
