/* eslint-disable no-case-declarations */
import { snakeCase } from 'lodash'
import { Fragment } from 'react'
import { Field } from 'redux-form'

import { CheckBox } from 'components/Forms/CheckBoxComponent'
import { RadioComponent } from 'components/Forms/RadioComponent'
import { SelectField } from 'components/Forms/SelectComponent'
import { TextField } from 'components/Forms/TextFieldComponent'

const FieldType = ({ field, name }) => {
	const type = field?.type || 'text'

	switch (type) {
		case 'text':
		case 'number':
		case 'date':
		case 'color':
			return (
				<Field
					infoText={field?.description}
					type={type}
					name={name}
					component={TextField}
					placeholder={field?.placeholder}
					ignoreBottomMargin={true}
				/>
			)

		case 'select':
			const options = Array.isArray(field?.values)
				? field.values.map((option) => ({ label: option?.label, value: option?.value }))
				: []

			return (
				<Field
					infoText={field?.description}
					type='select'
					name={name}
					component={SelectField}
					options={options}
					isMulti={field?.multiple}
					ignoreBottomMargin={true}
				/>
			)

		case 'checkbox':
			const checkbox = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					{checkbox.map((item, idx) => {
						return (
							<Fragment key={idx}>
								<Field
									label={item?.label}
									id={snakeCase(item?.label)}
									value={item?.value}
									type='checkbox'
									name={name}
									component={CheckBox}
									ignoreBottomMargin={true}
								/>
							</Fragment>
						)
					})}
				</Fragment>
			)

		case 'radio-group':
			const radios = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<div className='flex-col'>
						<label className='mb-0'>{field?.label}</label>
						{field?.description && (
							<i className='text-muted mb-2' style={{ fontSize: 12 }}>
								{field?.description}
							</i>
						)}
					</div>
					{radios.map((item, idx) => {
						return (
							<Fragment key={idx}>
								<Field
									label={item?.label}
									id={item?.value}
									value={item?.value}
									type='radio'
									name={name}
									component={RadioComponent}
									ignoreBottomMargin={true}
								/>
							</Fragment>
						)
					})}
				</Fragment>
			)

		case 'checkbox-group':
			const checkboxes = Array.isArray(field?.values) ? field.values : []

			return (
				<Fragment>
					<Field
						type='checkbox'
						options={checkboxes}
						multiple={field?.multiple}
						name={name}
						component={CheckBox}
						ignoreBottomMargin={true}
					/>
				</Fragment>
			)

		default:
			return (
				<Field
					label={field?.label}
					infoText={field?.description}
					type='text'
					name={name}
					component={TextField}
					placeholder={field?.placeholder}
					ignoreBottomMargin={true}
				/>
			)
	}
}

export default FieldType
