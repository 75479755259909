/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import ButtonWithLoader from 'components/Common/ButtonWithLoader'
import apiEndpoints from 'helpers/apiEndpoints'
import { Fragment, useState } from 'react'
import { Button, Col, Progress, Row } from 'reactstrap'
import { reduxForm } from 'redux-form'
import { validateFileType } from 'theme/ui/forms/file-upload-field'
import { sanitizeImage } from 'theme/ui/forms/file-upload-field/image-validation'
import { sanitizePdf } from 'theme/ui/forms/file-upload-field/pdf-validation'
import notification from 'utilities/notification'
import {
	findObjectByField,
	formatBytes,
	isConfirmed,
	showErrorToast,
	showSuccessToast,
	toSnakeCase,
} from 'utils/commonFunctions'
import { getFileLabel, getFileOptions } from '../utils'
import FileUploadZone, { FilePreview } from './file-upload-zone'

const Tab1 = ({
	user_type,
	uploads,
	workflow_id,
	createdBy,
	CompleteSteps,
	setCompleteSteps,
	activeTab,
	toggleTab,
	handleSubmit,
	isLoading,
}) => {
	const [selected, setselected] = useState([])
	const [progress, setProgress] = useState(0)
	const [loader, setLoader] = useState(false)

	const files = getFileLabel(uploads?.note, user_type)
	const fileOptions = getFileOptions(files)
	const workflow_step_id = uploads?.id
	const isStepAvailable = !isLoading && files.length === 0

	const handleFileUplaod = (files) => {
		const formData = new FormData()
		formData.append('workflow_id', workflow_id)
		formData.append('workflow_step_id', workflow_step_id)
		formData.append('save_type', 'in_review')
		for (let i = 0; i < files.length; i++) {
			const fileObj = findObjectByField(fileOptions, 'value', files[i].type)
			formData.append('files[]', files[i].file)
			formData.append('file_titles[]', fileObj.label)
		}
		setLoader(true)
		axios
			.post(`${apiEndpoints.client.workflowDetails}/file`, formData, {
				onUploadProgress: (progressEvent) => {
					var percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total,
					)
					setProgress(percentCompleted)
				},
			})
			.then((response) => {
				setLoader(false)
				if (response?.data?.status === 200) {
					showSuccessToast(response?.data?.message)

					!CompleteSteps.includes(1) && CompleteSteps.push(1)
					setCompleteSteps([...CompleteSteps])
					toggleTab(activeTab + 1)
				}
			})
			.catch((error) => {
				setLoader(false)
				const message = error?.response?.data?.message || error?.message
				showErrorToast(message)
			})
	}

	const submit = () => {
		if (selected.length < files.length) {
			showErrorToast(`Please upload all ${files.length} files `)
		} else {
			isConfirmed('Are you sure you want to upload these files?', 'warning').then(
				(response) => {
					if (response) {
						handleFileUplaod(selected)
					}
				},
			)
		}
	}

	const onDropAcceptFile = (file, type) => {
		const files = [...selected]
		files.push({ type: toSnakeCase(type), file: file[0] })
		setselected(files)
	}

	return (
		<Fragment>
			{isStepAvailable ? (
				<Fragment>
					<Row className='justify-content-center'>
						<Col sm={6}>
							<div className='alert alert-info'>
								In this step, there is no need to upload a file; you can save this
								workflow by simply clicking the &apos;Next&apos; button.
							</div>
						</Col>
					</Row>
					<div className='my-4 border-t border-dashed' />
					<div className='actions clearfix'>
						<div className='m-0 flex justify-between'>
							<Button color='primary' disabled={true}>
								Previous
							</Button>
							<Button color='primary' onClick={() => toggleTab(2)}>
								Next
							</Button>
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<form onSubmit={handleSubmit((values) => submit(values))}>
						<h5 className='mb-4 text-center'>
							<strong>
								{createdBy
									? `${createdBy.first_name} ${createdBy.last_name}`
									: '{advisor}'}
							</strong>{' '}
							has asked you to upload the following files
						</h5>
						<br />

						<div className='flex flex-col'>
							{files.map((file, idx) => {
								const fileType = toSnakeCase(file?.file)
								const selectedFile =
									findObjectByField(selected, 'type', fileType)?.file || undefined
								if (selectedFile) {
									Object.assign(selectedFile, {
										preview: URL.createObjectURL(selectedFile),
										formattedSize: formatBytes(selectedFile.size),
									})
								}
								const accept = ['.png', '.jpg', '.jpeg', '.pdf']
								return (
									<div key={idx} className='row justify-content-center my-3'>
										<div className='col-sm-5 p-4'>
											<h5>
												{idx + 1}. {file?.file}
											</h5>
											<div
												className='dropzone-previews mt-3'
												id='file-previews'>
												{selectedFile ? (
													<FilePreview
														fileType={fileType}
														selected={selected}
														selectedFile={selectedFile}
														setselected={setselected}
													/>
												) : (
													<p className='text-muted'>
														Select a file to upload
													</p>
												)}
											</div>
										</div>
										<div className='col-sm-4'>
											{!selectedFile ? (
												<FileUploadZone
													accept={accept}
													onDrop={async (
														acceptedFiles,
														rejectedFiles,
													) => {
														try {
															const rejectedFile =
																rejectedFiles[0]?.errors[0] || null
															if (rejectedFile) {
																throw new Error(
																	rejectedFile?.message,
																)
															}
															const $file = acceptedFiles[0]
															const [type, subtype] =
																$file.type.split('/')
															await validateFileType($file, accept)

															if (type === 'image') {
																await sanitizeImage($file)
															}

															if (
																type === 'application' &&
																subtype === 'pdf'
															) {
																await sanitizePdf($file)
															}

															onDropAcceptFile(
																acceptedFiles,
																file?.file,
															)
														} catch (error) {
															notification('error', error?.message)
														}
													}}
												/>
											) : undefined}
										</div>
									</div>
								)
							})}
						</div>
						{progress > 0 && (
							<Progress
								color={progress === 100 ? 'success' : 'primary'}
								value={progress}>
								{progress} %
							</Progress>
						)}

						<div className='my-4 border-t border-dashed' />
						<div className='actions clearfix'>
							<div className='m-0 flex justify-between'>
								<Button color='primary' disabled={true}>
									Previous
								</Button>
								<ButtonWithLoader color='primary' type='submit' loading={loader}>
									Next
								</ButtonWithLoader>
							</div>
						</div>
					</form>
				</Fragment>
			)}
		</Fragment>
	)
}

const Tab1Form = reduxForm({
	form: 'workflow_step_1',
	enableReinitialize: true,
})(Tab1)
export default Tab1Form
