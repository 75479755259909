/* eslint-disable no-unused-vars */
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/Common'
import { Fragment, useState } from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'
import FilterForm from './FilterForm'
import usePortfolioViewQuery from './usePortfolioViewQuery'

const Portfolios = ({ client, role }) => {
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { data, meta, isLoading } = usePortfolioViewQuery(pagination, client, role)
	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			size: 40,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.portfolio, {
			id: 'portfolio',
			size: 200,
			header: () => <span>Portfolio</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row.model, {
			id: 'model',
			size: 200,
			header: () => <span>Model</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row.holding, {
			id: 'holding',
			size: 200,
			header: () => <span>Holding</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
	]
	return (
		<Fragment>
			<Card className='!rounded-lg shadow-none'>
				<CardHeader className='!border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Portfolios</h5>
				</CardHeader>
				<CardBody>
					<FilterForm />
					<DataTable
						columns={columns}
						data={data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
					/>
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default Portfolios
