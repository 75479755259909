import apiEndpoints from "helpers/apiEndpoints"
import { useQuery } from "hooks"

const InvitedClientQuery = (id) => {
    const URL = `${apiEndpoints.user.inviteUserList}/user-onboard-detail/${id}`
    const { data, isLoading } = useQuery(URL)

    return {
        client: data?.data || [],
        isLoading
    }
}

export default InvitedClientQuery