import { useMemo } from 'react'
import OTPInput from 'react-otp-input'

const OtpField = ({ form, label, field, otpLength = 4 }) => {
	const { name, value } = field
	const touched = useMemo(() => form?.touched[name], [form?.touched])
	const error = useMemo(() => form?.errors[name], [form?.errors])

	return (
		<div className='flex w-fit flex-col space-y-3'>
			{label ? <label className='text-left'>{label}</label> : undefined}
			<OTPInput
				inputType='tel'
				name={name}
				value={value}
				onChange={(value) => form.setFieldValue(field.name, value)}
				numInputs={otpLength}
				renderSeparator={<span className='w-3'></span>}
				renderInput={(props) => (
					<input
						{...props}
						className='border-1 rounded-md border-gray-200 px-2 text-center'
						style={{
							width: 38,
							height: 38,
						}}
						required
					/>
				)}
			/>
			{touched && error ? <div className='invalid-feedback text-xs'>{error}</div> : undefined}
		</div>
	)
}

export default OtpField
