import stepActiveIcon from 'assets/svg/step-active-icon.svg'
import stepCompleteIcon from 'assets/svg/step-complete-icon.svg'
import stepInActiveIcon from 'assets/svg/step-inactive-icon.svg'
import classNames from 'classnames'

const TabIndicator = ({ selectedIndex }) => {
	return (
		<div className='flex flex-col space-y-1'>
			<span className='text-[13px]'>Step 1/2</span>
			<div className='flex items-center'>
				{selectedIndex === 0 ? (
					<img src={stepActiveIcon} />
				) : (
					<img src={stepCompleteIcon} />
				)}
				<span
					className={classNames('h-[1px] w-[40px] !border-t', {
						'border-[#D1D5DB]': selectedIndex === 0,
						'border-main': selectedIndex === 1,
					})}
				/>
				{selectedIndex === 0 ? (
					<img src={stepInActiveIcon} />
				) : (
					<img src={stepActiveIcon} />
				)}
			</div>
		</div>
	)
}

export default TabIndicator
