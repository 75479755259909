import { useState } from 'react'

import ClientInviteForm from 'modules/clients/client-invite-form'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from 'utils/checkPermission'

const TeamMemberInvite = () => {
	const [isInvitePermission] = useState(hasPermission('user:invite'))

	return (
		<AppLayout
			meta={{
				title: 'Invite user',
			}}>
			{isInvitePermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title='Invite User'
							description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
							hasBackButton={true}
						/>
						<div className='page-body'>
							<ClientInviteForm />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default TeamMemberInvite
