import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'use-debounce'

import { useLazyGetQueryDataQuery } from 'modules/clients/client-apis'
import { toQueryString } from 'utils/commonFunctions'
import { CLIENT_API_ROUTES } from 'modules/clients/routes'

const useNoteViewQuery = (pagination, client) => {
	const values = useSelector((state) => state.form?.note_view_filter?.values)
	const [debounceSearch] = useDebounce(values?.search, 600)
	const { pageIndex, pageSize } = pagination

	const filters = useMemo(() => {
		const obj = { page: pageIndex + 1, limit: pageSize }
		if (debounceSearch) {
			Object.assign(obj, { search: debounceSearch })
		}

		return obj
	}, [values, debounceSearch, pageIndex, pageSize])

	const query = toQueryString(filters)

	const URL = useMemo(
		() =>
			client?.id && query
				? `${CLIENT_API_ROUTES.findAllNotes(client?.id)}?${query}`
				: undefined,
		[client?.id, query],
	)
	const [fetchNotes, { data, isLoading, refetch }] = useLazyGetQueryDataQuery()

	useEffect(() => {
		if (URL) {
			fetchNotes(URL)
		}
	}, [URL])

	const responseData = data?.data?.notes

	const response = useMemo(
		() =>
			Array.isArray(responseData)
				? responseData.map((note, idx) => ({
						id: idx + 1 + pageIndex * pageSize,
						note: note?.notes,
						sharing: note?.visibility ? 'Public' : 'Private',
				  }))
				: [],
		[responseData],
	)

	return {
		data: response || [],
		meta: data?.data?.meta,
		mutate: refetch,
		isLoading,
	}
}

export default useNoteViewQuery
