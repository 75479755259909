import { useState } from 'react'
import { Field } from 'redux-form'

import { CreatableSelectDropdown } from 'components/Forms2'
import TagOptionsQuery from './TagOptionsQuery'

const TagDropdown = ({ validate }) => {
	const [search, setSearch] = useState('')
	const { options, isLoading } = TagOptionsQuery(search)

	return (
		<Field
			label='Tags'
			name='tags'
			type='select'
			component={CreatableSelectDropdown}
			isMulti={true}
			variant='horizontal'
			width={'w-56'}
			options={options}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			validate={validate}
			isClearable={true}
			placeholder='Select tag...'
		/>
	)
}

export default TagDropdown
