import OtpInput from 'react-otp-input'

const OTPField = ({ otpState, numInputs = 4 }) => {
	const [otp, setOtp] = otpState

	return (
		<div className='justify-left mb-4 flex max-w-fit space-x-3'>
			<OtpInput
				inputType='tel'
				value={otp}
				onChange={setOtp}
				numInputs={numInputs}
				renderSeparator={<span className='w-3'></span>}
				renderInput={(props) => (
					<input
						{...props}
						className='border-1 rounded-md border-gray-200 px-2 text-center'
						style={{
							width: 38,
							height: 38,
						}}
						required
					/>
				)}
			/>
		</div>
	)
}

export default OTPField
