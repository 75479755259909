import InvitedClientsContainer from './invited-clients-container'

export const statusColor = {
	Pending: 'warning',
	Approved: 'success',
	Rejected: 'danger',
	Submitted: 'primary',
}

export default InvitedClientsContainer
