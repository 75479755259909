import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { useSaveWorkflowOutstandingMutation } from 'modules/workflows/workflows-outstandings/workflow-outstandings-apis'
import notification from 'utilities/notification'
import { preparePayload } from '../helpers'

const ConfirmAlert = ({ isOpen, onClose, ID, values, workflow }) => {
	const history = useHistory()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [saveWorkflowOutstanding] = useSaveWorkflowOutstandingMutation()

	const onConfirm = async (values, onClose, workflow) => {
		try {
			setIsSubmitting(true)
			const payload = preparePayload(workflow, values)
			const response = await saveWorkflowOutstanding({ payload, id: ID })

			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				setIsSubmitting(false)
				onClose()
				history.goBack()
			}
		} catch (error) {
			setIsSubmitting(false)
			onClose()
		}
	}

	return (
		<Transition
			show={isOpen}
			enter='transition duration-100 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-75 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as='div'>
			<Dialog open={isOpen} onClose={() => {}} className='popup-overlay relative'>
				<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
				<div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
					<Dialog.Panel className='w-80 rounded bg-white'>
						<div className='rounded-t bg-[#F5F7FE] px-8 py-8'>
							<div className='relative left-[8%] top-[8%]'>
								<button
									type='button'
									className='absolute -top-4 right-0'
									onClick={onClose}>
									<i className='fas fa-times text-lg' />
								</button>
							</div>
							<div className='mx-auto'>
								<h1 className='mb-1 text-center text-lg font-bold'>Confirmation</h1>
								<p className='m-0 text-center text-sm font-normal text-[#74788D]'>
									Save the respected changes you made to move further to next step
								</p>
							</div>
						</div>
						<div className='px-8 py-[20px]'>
							<div className='mx-auto w-fit'>
								<button
									onClick={() => onConfirm(values, onClose, workflow)}
									className='btn-primary-1'
									disabled={isSubmitting}>
									{isSubmitting ? (
										<span>
											<SpinLoader
												size='sm'
												isLoadingText='Saving...'
												color='white'
											/>
										</span>
									) : (
										'Save & Continue'
									)}
								</button>
							</div>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>
		</Transition>
	)
}

export default ConfirmAlert
