import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'reactstrap'

import apiEndpoints from 'helpers/apiEndpoints'
import { store } from 'store/common/actions'

const NotesModal = ({ workflow }) => {
	const [modal_backdrop, setmodal_backdrop] = useState(!workflow?.is_note_read)
	const dispatch = useDispatch()

	const removeBodyCss = () => {
		document.body.classList.add('no_padding')
	}

	const tog_backdrop = () => {
		setmodal_backdrop(!modal_backdrop)
		removeBodyCss()
	}

	const handleRead = () => {
		dispatch(store(`${apiEndpoints.workflowRead}/${workflow?.id}`)).finally(() => {
			setmodal_backdrop(false)
		})
	}

	return (
		<Fragment>
			<Modal
				isOpen={modal_backdrop}
				toggle={() => {
					tog_backdrop()
				}}
				scrollable={true}
				id='staticBackdrop'
				backdrop='static'>
				<div className='modal-header flex justify-between'>
					<h5 className='modal-title' id='staticBackdropLabel'>
						Notes
					</h5>
					<button type='button' className='btn btn-primary btn-sm' onClick={handleRead}>
						<i className='envelope-open' />
						Close
					</button>
				</div>
				<div className='modal-body'>
					{workflow?.notes ? <p>{workflow?.notes}</p> : <p>No note available</p>}
				</div>
			</Modal>
		</Fragment>
	)
}

export default NotesModal
