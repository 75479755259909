import classNames from 'classnames'
import { Fragment } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'

const Navs = ({ tabState }) => {
	const [activeTab, setactiveTab] = tabState

	const toggleCustomJustified2 = (tab) => {
		if (activeTab !== tab) {
			setactiveTab(tab)
		}
	}

	const menus = [
		{ label: 'Info', id: '1' },
		{ label: 'Chat', id: '2' },
		{ label: 'Portfolios', id: '3' },
		{ label: 'Documents', id: '4' },
		{ label: 'Workflows', id: '5' },
		{ label: 'Workflow Files', id: '6' },
		{ label: 'Notes', id: '7' },
	]

	return (
		<Nav tabs className='nav-tabs-custom !border-none'>
			{menus.map((menu, idx) => {
				return (
					<Fragment key={idx}>
						<NavItem>
							<NavLink
								style={{ cursor: 'pointer' }}
								className={classNames(
									{
										active: activeTab === menu?.id,
									},
									'py-3',
								)}
								onClick={() => {
									toggleCustomJustified2(menu?.id)
								}}>
								<span className='d-none d-sm-block text-md font-bold'>
									{menu?.label}
								</span>
							</NavLink>
						</NavItem>
					</Fragment>
				)
			})}
		</Nav>
	)
}

export default Navs
