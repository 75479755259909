import { createColumnHelper } from '@tanstack/react-table'
import { Fragment, useState } from 'react'

import DataTable from 'theme/ui/data-table'
import Actions from './actions'

const CompletedDocumentsTable = ({
	documents,
	meta,
	isLoading,
	pagination,
	setPagination,
	toggle,
}) => {
	const [sorting, setSorting] = useState([])
	const [actionActiveId, setActionActiveId] = useState(null)
	const onMouseEnter = (id) => setActionActiveId(id)
	const onMouseLeave = () => setActionActiveId(null)

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('index', {
			size: 20,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.title, {
			id: 'title',
			size: 200,
			header: () => <span>Document Title</span>,
			cell: (info) => (
				<span className='line-clamp-1 text-sm font-bold !text-gray-700'>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.workflow_title, {
			id: 'workflow_title',
			size: 200,
			header: () => <span>Workflow Title</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm font-medium !text-gray-700'
					title={info.getValue()}>
					{info.getValue()}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.advisor, {
			id: 'advisor',
			size: 150,
			header: () => <span>Advisor</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.final_reviewer, {
			id: 'final_reviewer',
			size: 150,
			header: () => <span>Final Reviewer</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row.client, {
			id: 'client',
			size: 120,
			header: () => <span>Client</span>,
			cell: (info) => (
				<span
					className='line-clamp-1 text-sm !text-gray-500'
					title={info.getValue()?.fullName}>
					{info.getValue()?.fullName}
				</span>
			),
		}),

		columnHelper.accessor((row) => row, {
			id: 'updated_at',
			size: 200,
			header: () => <span>Last modified</span>,
			cell: (info) => (
				<Actions
					isActionActive={actionActiveId === info?.row?.id}
					updated_at={info.getValue()?.updated_at}
					toggle={toggle}
					document={info?.row?.original}
				/>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={documents}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			setSorting={setSorting}
			sorting={sorting}
			meta={meta}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		/>
	)
}

export default CompletedDocumentsTable
