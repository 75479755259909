import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import experiviseLightLogo1 from 'assets/images/experivise-logo-white.png'
import { TextField } from 'components/Forms/TextFieldComponent'
import { confirmPassword, emailCheck, passwordCheck, required } from 'utils/formValidation'

const validate = {
	email: [required('email'), emailCheck],
	password: [required('password'), passwordCheck],
	passwordConfirm: [required('confirm password'), confirmPassword],
}

const ResetPasswordForm = (props) => {
	const { handleSubmit, loading } = props

	return (
		<div className='account-pages my-5  pt-sm-5'>
			<form onSubmit={handleSubmit} noValidate>
				<Container>
					<div className='row justify-content-center'>
						<div className='col-md-8 col-lg-6 col-xl-5'>
							<div>
								<Card>
									<CardBody className='p-4'>
										<div className='text-center mt-2'>
											<Row>
												<Col lg={12}>
													<div className='text-center'>
														<div className='d-block auth-logo'>
															<img
																src={experiviseLightLogo1}
																alt=''
																style={{ height: 100 }}
																className='logo logo-dark'
															/>
														</div>
													</div>
												</Col>
											</Row>
											<h5 className='text-primary'>Reset Password</h5>
											<p className='text-muted'>
												Reset Password with Experivise.
											</p>
										</div>
										<div className='p-2 mt-4'>
											<div className='mb-3'>
												<Field
													name='password'
													label='Create Password *'
													component={TextField}
													className='form-control'
													placeholder='Enter Password'
													type='password'
													required
													validate={validate.password}
													autoComplete='off'
													isValidatePassword={true}
												/>
											</div>

											<div className='mb-3'>
												<Field
													name='confirm_password'
													label='Confirm Password *'
													component={TextField}
													className='form-control'
													placeholder='Confirm Password'
													type='password'
													required
													validate={validate.passwordConfirm}
													autoComplete='off'
												/>
											</div>

											<Row className='row mb-0'>
												<Col className='col-12 text-end'>
													<button
														className='btn btn-primary w-md waves-effect waves-light'
														type='submit'
														disabled={loading}>
														{!loading ? 'Reset' : ''}
														{loading && (
															<Spinner
																animation='border'
																size='sm'
																color='default'
															/>
														)}
													</button>
												</Col>
											</Row>
											<div className='mt-4 text-center'>
												<p className='mb-0'>
													Remember It ?{' '}
													<Link
														to='/login'
														className='fw-medium text-primary'>
														{' '}
														Signin{' '}
													</Link>
												</p>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</Container>
			</form>
		</div>
	)
}

ResetPasswordForm.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
}

const ResetPasswordFormRedux = reduxForm({
	form: 'forgetPassword',
	enableReinitialize: true,
})(ResetPasswordForm)

export default ResetPasswordFormRedux
