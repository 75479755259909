export default function FallbackRender({ error, resetErrorBoundary }) {
	return (
		<div className=' flex min-h-screen items-center justify-center'>
			<div role='alert' className='prose w-[500px] rounded-sm border bg-white p-6 shadow-sm'>
				<h4 className='text-center'>Something went wrong!</h4>

				{process.env.NODE_ENV === 'development' ? (
					<pre style={{ color: 'red' }}>{error.message}</pre>
				) : undefined}

				<div className='flex items-center justify-center space-x-4'>
					<button onClick={resetErrorBoundary} className='btn btn-secondary'>
						Try again
					</button>

					<a href='/dashboard' className='btn btn-primary'>
						Go to dashboard
					</a>
				</div>
			</div>
		</div>
	)
}
