import { getDuration } from 'modules/subscriptions/subscription-listing'
import Badge from 'theme/ui/badge'
import { formatDate, formatPrice } from 'utilities/helpers'

const CurrentSubscription = ({ subscription }) => {
	return (
		<div>
			<div className='!mb-3 flex items-center justify-between'>
				<h3 className='m-0 text-lg font-medium text-gray-600'>Current Subscription</h3>
			</div>

			<div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
				<div className='rounded-lg !border !p-4'>
					<div className='flex justify-between gap-4'>
						<div className='flex flex-col'>
							<div className='flex h-fit items-center gap-1'>
								<span className='text-lg font-semibold capitalize text-gray-950'>
									{subscription?.plan?.plan_type}
								</span>
								<Badge variant='success'>{subscription?.status}</Badge>
							</div>
							<span>{subscription?.plan?.description}</span>
						</div>

						<div className='flex items-baseline  space-x-1'>
							<span className='text-xl font-bold text-gray-950'>
								{formatPrice(subscription?.plan_price, 'USD')}
							</span>
							<span className='text-sm font-semibold text-gray-700'>
								{getDuration(subscription?.plan?.duration)}
							</span>
						</div>
					</div>
				</div>
				<div className='rounded-lg !border !px-4 py-3'>
					<div className='flex justify-between gap-3'>
						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Started At</span>
							<span className='text-sm font-semibold text-gray-950'>
								{formatDate(subscription?.start_date)}
							</span>
						</div>
						<div className='flex flex-col'>
							<span className='text-[13px] text-gray-500'>Expires At</span>
							<span className='text-sm font-semibold text-gray-950'>
								{formatDate(subscription?.end_date)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CurrentSubscription
