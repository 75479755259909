import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import classNames from 'classnames'
import { useAppRouter } from 'hooks'
import { useState } from 'react'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { useUpdateNotificationTemplateMutation } from '../notifications-templates-api'
import { NOTIFICATIONS_TEMPLATES_APP_ROUTES } from '../routes'
const NotificationsTemplatesForm = ({ notificationTemplate }) => {
	const [notificationData, setNotificationData] = useState(notificationTemplate?.content)
	const [notificationSubject, setNotificationSubject] = useState(notificationTemplate?.subject)
	const initialData = notificationTemplate?.content
	const initialSubject = notificationTemplate?.subject
	const { goBack, navigate, hasHistory } = useAppRouter()
	const handleNotificationChange = (event, editor) => {
		const data = editor.getData()
		setNotificationData(data)
	}
	const handleNotificationSubject = (e) => {
		setNotificationSubject(e.target.value)
	}
	const [updateNotificationTemplate] = useUpdateNotificationTemplateMutation()
	const convertNotificationData = (data) => {
		return data
			.replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>')
			.replace(/<\/p><p>/g, '</p>\n\n<p>')
	}
	const resetNotificationData = () => {
		setNotificationData(initialData)
		setNotificationSubject(initialSubject)
	}
	const saveTemplate = async () => {
		try {
			const convertedData = convertNotificationData(notificationData)
			const payload = {
				content: convertedData,
				title: notificationSubject,
			}
			let response
			if (notificationTemplate) {
				response = await updateNotificationTemplate({
					payload: payload,
					id: notificationTemplate?.id,
				})
			}

			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
				return hasHistory
					? goBack()
					: navigate(NOTIFICATIONS_TEMPLATES_APP_ROUTES.findAll())
			}
		} catch (error) {
			notification('warn', error?.message)
		}
	}

	return (
		<div>
			<div className=''>
				<h5 className='mb-2 text-lg '>Notification Subject : </h5>
				<input
					type='text'
					value={notificationSubject}
					onChange={(e) => handleNotificationSubject(e)}
					placeholder='Enter notification subject'
					className='w-full rounded !border border-slate-300 px-2 py-2'
				/>
			</div>
			<div className='mt-3'>
				<CKEditor
					editor={ClassicEditor}
					data={notificationData}
					onChange={handleNotificationChange}
				/>
				<div className='flex justify-between gap-3'>
					<div></div>
					<div className='flex gap-3'>
						<Button
							variant={'primary'}
							className='mt-3 flex items-center rounded !border-gray-500 px-3 py-1'
							onClick={() => saveTemplate()}>
							Save Template
						</Button>
						<Button
							variant={'primary-outline'}
							className={classNames(
								'mt-3 flex items-center rounded !border-gray-500 px-3 py-1 !font-semibold',
							)}
							onClick={resetNotificationData}>
							Reset
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotificationsTemplatesForm
