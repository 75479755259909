import CountryDropdown from 'components/Forms/CountryDropdown'
import useCountryOptions from 'components/Forms/CountryDropdown/useCountryOptions'
import { TextField } from 'components/Forms/TextFieldComponent'
import { Field, reduxForm } from 'redux-form'
import { validate } from './validate'

const Form = ({ handleSubmit, isLoading }) => {
	const countryOptions = useCountryOptions()

	return (
		<div className='mt-5'>
			<form onSubmit={handleSubmit} className='flex flex-col space-y-5'>
				<div>
					<h4 className='mb-3 text-lg font-semibold'>Personal Details</h4>
					<div className='grid grid-cols-2 gap-x-3'>
						<Field
							label='First Name'
							type='text'
							name='first_name'
							component={TextField}
							validate={validate.first_name}
						/>

						<Field
							label='Last Name'
							type='text'
							name='last_name'
							component={TextField}
							validate={validate.last_name}
						/>
					</div>
				</div>

				<div>
					<h4 className='mb-3 text-lg font-semibold'>Address Information</h4>
					<div className='grid grid-cols-2 gap-x-3'>
						<div className='col-span-2'>
							<Field
								label='Address line 1'
								type='text'
								name='address1'
								component={TextField}
								validate={validate.address1}
							/>
						</div>

						<div className='col-span-2'>
							<Field
								label='Address line 2'
								type='text'
								name='address2'
								component={TextField}
							/>
						</div>

						<Field label='Unit' type='text' name='unit' component={TextField} />

						<Field
							label='City'
							type='text'
							name='city'
							component={TextField}
							validate={validate.city}
						/>

						<Field
							label='Province'
							type='text'
							name='province'
							component={TextField}
							validate={validate.province}
						/>

						<Field
							label='Zip Code'
							type='text'
							name='zip_code'
							component={TextField}
							validate={validate.zipCode}
						/>

						<Field
							label='Country'
							type='text'
							name='country'
							options={countryOptions}
							component={CountryDropdown}
							validate={validate.country}
						/>
					</div>
				</div>

				<div>
					<button type='submit' className='btn-primary-1' disabled={isLoading}>
						Continue
					</button>
				</div>
			</form>
		</div>
	)
}

export default reduxForm({
	form: 'verify_information',
	enableReinitialize: true,
})(Form)
