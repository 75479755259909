import { Header, WorkflowOutstandings } from 'modules/workflows'
import AppLayout from 'theme/layouts/app-layout'
import { TableProvider } from 'theme/ui/data-table'
import hasPermission from '../../../utils/checkPermission'
import UnAuthorize from '../../StaticPages/UnAuthorize'

const WorkflowsOutstandingsListing = () => {
	const isListPermission = hasPermission('workflow:list')

	return (
		<AppLayout
			meta={{
				title: 'Workflow Outstandings',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<TableProvider>
								<WorkflowOutstandings />
							</TableProvider>
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default WorkflowsOutstandingsListing
