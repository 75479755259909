import $ from 'jquery'

import { useSetFormBuilder } from 'contexts/formbuilder-provider'
import { useEffect, useMemo, useRef } from 'react'
import { disabledFieldActions, toFormBuilder } from '../../helpers'

window.jQuery = $
window.$ = $

require('jquery-ui-sortable')
require('form-builder2')

const FormBuilderTab = ({ formik, document }) => {
	const fb = useRef()
	const setFormBuilder = useSetFormBuilder()
	const documentMapping = useMemo(
		() =>
			Array.isArray(formik?.values?.document_mapping) ? formik?.values?.document_mapping : [],
		[formik?.values?.document_mapping],
	)
	const pdfFields = useMemo(
		() => (Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []),
		[formik?.values?.pdf_fields],
	)

	const formBuilderData = useMemo(
		() => toFormBuilder(documentMapping, pdfFields),
		[documentMapping, pdfFields],
	)

	const formBuilder = (formBuilderData) => {
		const config = {
			formData: formBuilderData,
			showActionButtons: false,
			disabledAttrs: ['access', 'className', 'value', 'maxlength', 'required'],
			disabledSubtypes: {
				text: ['tel', 'checkbox-group', 'file'],
			},
			disabledFieldButtons: disabledFieldActions(['remove', 'copy', 'sort']),
		}
		return $(fb.current).formBuilder(config)
	}

	useEffect(() => {
		if (formBuilderData) {
			const $builder = formBuilder(formBuilderData)
			setFormBuilder($builder)
		}
		return () => {
			$(fb.current).html('')
			setFormBuilder(null)
		}
	}, [formBuilderData])

	useEffect(() => {
		formik.setFieldValue('status', 1)
	}, [])

	return (
		<div>
			<h3 className='mb-2 text-lg font-bold'>Form Builder</h3>
			<p className='text-gray-600'>Build the form and assign field type</p>
			<div className='rounded-md bg-white p-6'>
				<div id='form-builder' ref={fb} />
			</div>
		</div>
	)
}

export default FormBuilderTab
