import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormBuilderProvider } from 'contexts/formbuilder-provider'
import { TabsProvider } from 'contexts/tabs-context'
import {
	DocumentTemplateForm,
	DocumentTemplateFormSkeleton,
	DocumentWorkflows,
	SignatureDocumentProvider,
} from 'modules/documents'
import {
	useLazyCheckEditEligibilityQuery,
	useLazyGetDocumentWorkflowsQuery,
	useLazyGetOneDocumentTemplateQuery,
} from 'modules/documents/document-api'
import { AppLayout, PageHeader } from 'theme/layouts'

const EditDocumentTemplate = () => {
	const { id: ID } = useParams()
	const [state, setState] = useState({
		isFetching: true,
		document: null,
		workflows: [],
		count: 0,
	})

	const [fetchDocumentTemplate] = useLazyGetOneDocumentTemplateQuery()
	const [checkEditEligibility] = useLazyCheckEditEligibilityQuery()
	const [fetchWorkflows] = useLazyGetDocumentWorkflowsQuery()

	const fetchDocument = async (ID) => {
		setState((prevState) => ({ ...prevState, isFetching: true }))
		try {
			const eligibilityResponse = await checkEditEligibility(ID)
			if (eligibilityResponse?.data?.status === 200) {
				const templateResponse = await fetchDocumentTemplate(ID)
				setState({
					document: templateResponse?.data?.document,
					workflows: [],
					count: 0,
					isFetching: false,
				})
			} else {
				const workflowsResponse = await fetchWorkflows(ID)
				setState({
					document: null,
					workflows: workflowsResponse?.data?.data?.workflow || [],
					count: workflowsResponse?.data?.data?.count || 0,
					isFetching: false,
				})
			}
		} catch (error) {
			console.error('Failed to fetch document:', error)
			setState((prevState) => ({ ...prevState, isFetching: false }))
		}
	}

	useEffect(() => {
		if (ID) {
			fetchDocument(ID)
		}
	}, [ID])

	const { isFetching, document, workflows, count } = state

	return (
		<AppLayout
			meta={{
				title: 'Edit Document Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title={ID ? 'Edit Document Template' : 'Create Document Template'}
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton
					/>
					<SignatureDocumentProvider>
						<div>
							{isFetching ? (
								<DocumentTemplateFormSkeleton />
							) : (
								<Fragment>
									{document && workflows.length === 0 ? (
										<FormBuilderProvider>
											<TabsProvider>
												<DocumentTemplateForm document={document} />
											</TabsProvider>
										</FormBuilderProvider>
									) : (
										<DocumentWorkflows
											id={ID}
											workflows={workflows}
											fetchDocument={fetchDocument}
											totalWorkflows={count}
										/>
									)}
								</Fragment>
							)}
						</div>
					</SignatureDocumentProvider>
				</div>
			</div>
		</AppLayout>
	)
}

export default EditDocumentTemplate
