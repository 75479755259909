/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import { SpinLoader } from 'components/Common'
import { useAppRouter } from 'hooks'
import ResourceNotFound from 'pages/StaticPages/ResourceNotFound'
import { toSnakeCase } from 'utils/commonFunctions'
import K from 'utils/constants'
import NotesModal from './NotesModal'
import WorkflowSignatureStatus from './WorkflowSignatureStatus'
import StatusInformation from './status-information'
import TabContentContainer from './tab-content'
import TabHeaderContainer from './tab-header'
import { getFieldsByUser, getFieldsList } from './utils'
import useWorkflowQuery from './utils/useWorkflowQuery'
import WorkflowInfo from './workflow-info'

const ManageWorkflow = () => {
	const { params } = useAppRouter()
	const id = params?.id

	const [activeTab, setactiveTab] = useState(1)
	const [CompleteSteps, setCompleteSteps] = useState([])

	const [StepCheckLoader, setStepCheckLoader] = useState(false)

	const {
		data: {
			signPriority,
			workflow,
			steps,
			uploads,
			individualDocument,
			createdBy,
			isAllDocumentsSigned,
		},
		isNotAvailableForSignature,
		signature_status,
		isLoading,
		mutate,
	} = useWorkflowQuery(id)

	const user_type = workflow?.user_type
	const isWorkflowLoading = isLoading || StepCheckLoader

	const currentStatus = workflow?.status
	const currentStatusLabel = toSnakeCase(currentStatus) || null

	const tabs = [
		{ id: 1, name: 'Upload Files', type: K.STEPS.UPLOADS },
		{ id: 2, name: 'Collect Information', type: K.STEPS.INDIVIDUAL_DOCUMENT },
		{ id: 3, name: 'In Review/Final Review', type: K.STEPS.REVIEW_PROCESS },
		{ id: 4, name: 'Corrections', type: K.STEPS.CORRECTIONS },
		{ id: 5, name: 'Signatures', type: K.STEPS.SIGNATURES },
	]

	useEffect(() => {
		if (steps.length > 0) {
			if (currentStatusLabel !== K.STATUS.IN_CORRECTION) {
				setStepCheckLoader(true)
				setCompleteSteps([])
				for (let i = 0; i < steps.length; i++) {
					let step = steps[i]
					let step_no = i + 1
					if (
						step?.status === 'In-Review' ||
						currentStatusLabel === K.STATUS.FINAL_REVIEW ||
						currentStatusLabel === K.STATUS.FINAL_REVIEW_REJECTED
					) {
						setactiveTab(step_no + 1)
						!CompleteSteps.includes(step_no) && CompleteSteps.push(step_no)
					}
				}
				setCompleteSteps([...CompleteSteps])
				setStepCheckLoader(false)
			}
		}
	}, [steps])

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			if (tab >= 1 && tab <= 5) {
				setactiveTab(tab)
			}
		}
	}

	useEffect(() => {
		if (currentStatusLabel === K.STATUS.IN_CORRECTION) {
			setactiveTab(4)
		}

		if (
			[
				K.STATUS.GATHERING_SIGNATURE,
				K.STATUS.FINAL_REVIEW_APPROVED,
				K.STATUS.WAITING_FOR_SIGNATURES,
				K.STATUS.APPROVED,
				K.STATUS.SIGNED,
				K.STATUS.COMPLETED,
			].includes(currentStatusLabel)
		) {
			setactiveTab(5)
		}
	}, [currentStatusLabel])

	const fieldsArray = useMemo(
		() =>
			getFieldsList(individualDocument?.documents).filter(
				(field) => field?.is_document_bound !== true,
			),
		[individualDocument],
	)
	const fields = useMemo(() => getFieldsByUser(fieldsArray, user_type), [fieldsArray, user_type])

	const tab2InitialValues = useMemo(
		() => ({
			fields: fields.map((field) => {
				if (field?.type === 'select') {
					return { [field?.name]: null }
				} else if (field?.type === 'checkbox') {
					return { [field?.name]: false }
				} else {
					return { [field?.name]: field?.value || '' }
				}
			}),
			document_bound_fields: [], // add document_bound_fields here
		}),
		[fields],
	)

	const isPreventedStatus = [
		K.STATUS.COMPLETED,
		K.STATUS.FINAL_REVIEW_APPROVED,
		K.STATUS.GATHERING_SIGNATURE,
	].includes(currentStatusLabel)

	const isVisible = isPreventedStatus && !signPriority

	const isProceed = useMemo(
		() => isVisible || isAllDocumentsSigned,
		[isVisible, isAllDocumentsSigned],
	)

	return (
		<Fragment>
			{isWorkflowLoading ? (
				<SpinLoader />
			) : (
				<div>
					<WorkflowInfo workflow={workflow} stepId={individualDocument?.id} />

					<div className='mb-3 flex w-full justify-between'>
						<h5 className='mb-1'>Your Progress</h5>
						<span className='text-gray-500'>
							Step {activeTab} of {tabs.length}
						</span>
					</div>

					<div>
						{isProceed ||
						currentStatusLabel === K.STATUS.COMPLETED ||
						currentStatusLabel === K.STATUS.APPROVED ||
						currentStatusLabel === K.STATUS.FINAL_REVIEW_APPROVED ? (
							<StatusInformation
								isAllDocumentsSigned={isAllDocumentsSigned}
								status={currentStatusLabel}
							/>
						) : (
							<Card className='shadow-none'>
								<CardBody className='p-0'>
									<div className='wizard clearfix'>
										<TabHeaderContainer activeTab={activeTab} tabs={tabs} />
										<div className='content clearfix p-4'>
											<div className='body'>
												{isNotAvailableForSignature ? (
													<WorkflowSignatureStatus
														message={signature_status}
													/>
												) : (
													<Fragment>
														{workflow ? (
															<Fragment>
																<Fragment>
																	{workflow?.notes &&
																		!workflow?.is_note_read && (
																			<NotesModal
																				workflow={workflow}
																			/>
																		)}
																</Fragment>

																<TabContentContainer
																	activeTab={activeTab}
																	createdBy={createdBy}
																	mutate={mutate}
																	toggleTab={toggleTab}
																	uploads={uploads}
																	user_type={user_type}
																	tab2InitialValues={
																		tab2InitialValues
																	}
																	CompleteSteps={CompleteSteps}
																	currentStatus={currentStatus}
																	id={id}
																	individualDocument={
																		individualDocument
																	}
																	isLoading={isLoading}
																	setCompleteSteps={
																		setCompleteSteps
																	}
																	workflow={workflow}
																/>
															</Fragment>
														) : (
															<ResourceNotFound title='Requested workflow is not found' />
														)}
													</Fragment>
												)}
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						)}
					</div>
				</div>
			)}
		</Fragment>
	)
}

export default ManageWorkflow
