import { useMemo } from 'react'
import useSWR from 'swr'
import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { toQueryString } from 'utils/commonFunctions'
import fetcher from 'utils/fetcher'

const WorkflowOptionsQuery = (search, clientId) => {
	const [debounceSearch] = useDebounce(search, 600)

	const clientsQuery = useMemo(
		() => clientId.map((client) => `id=${client}`).join('&'),
		[clientId],
	)

	const defaultParams = { page: 1, limit: 20 }
	const searchParams = debounceSearch
		? { ...defaultParams, search: debounceSearch }
		: defaultParams

	const query = toQueryString(searchParams)
	const URL = `${apiEndpoints.workflow}/client/?${query}&${clientsQuery}`

	const { data, error } = useSWR(clientId.length > 0 ? URL : null, fetcher)

	const isLoading = !error && !data
	const workflows = data?.data || []

	const options = useMemo(
		() =>
			Array.isArray(workflows)
				? workflows.map((workflow) => ({
						label: <span>{workflow?.title}</span>,
						value: workflow?.id,
				  }))
				: [],
		[workflows],
	)

	return {
		workflowOptions: options,
		isLoading,
	}
}

export default WorkflowOptionsQuery
