import { required } from 'utils/formValidation'
import TaskForm from './TaskForm'
import { createAssigneeOption } from './assignee-dropdown/createAssigneeOption'
import { createClientOption } from './client-dropdown/createClientOption'
import { createWatcherOption } from './watcher-dropdown/createWatcherOption'

export default TaskForm

export const formatDate = (fromDate) => {
	try {
		if (!fromDate) {
			return null
		}
		const originalDate = new Date(fromDate)

		const year = originalDate.getFullYear()
		const month = String(originalDate.getMonth() + 1).padStart(2, '0')
		const day = String(originalDate.getDate()).padStart(2, '0')

		return `${year}-${month}-${day}`
	} catch (error) {
		return null
	}
}

export const formatDateForCalendar = (inputDateStr) => {
	try {
		// Convert input date string to a Date object
		var inputDate = new Date(inputDateStr)

		// Extract year, month, and day components
		var year = inputDate.getFullYear()
		var month = String(inputDate.getMonth() + 1).padStart(2, '0')
		var day = String(inputDate.getDate()).padStart(2, '0')

		// Format the date as "YYYY-MM-DD"
		return `${year}-${month}-${day}`
	} catch (error) {
		return null
	}
}

export const preparePayload = (values) => {
	try {
		return {
			team_id: values?.team_id,
			attachment_ids: values?.attachment_ids,
			task_title: values?.task_title,
			assignee_id: values?.assignee_id?.value,
			progress_status: values?.progress_status?.value,
			watcher_ids: Array.isArray(values?.watcher_ids)
				? values?.watcher_ids.map((watcher_id) => watcher_id?.value)
				: [],
			client_ids: Array.isArray(values?.client_ids)
				? values?.client_ids.map((client_id) => client_id?.value)
				: [],
			tags: Array.isArray(values?.tags)
				? values?.tags.filter((tag) => tag !== null).map((tag) => tag?.value)
				: [],
			workflow_id: values?.workflow_id?.value,
			due_date: formatDate(values?.due_date),
			description: values?.description,
			custom_fields: [],
		}
	} catch (error) {
		return {
			assignee_id: null,
			watcher_ids: [],
			client_ids: [],
			tags: [],
			workflow_id: null,
			due_date: formatDate(values?.due_date),
			custom_fields: [],
		}
	}
}

export const prepareInitialValues = (values) => {
	try {
		return {
			task_title: values?.task_title,
			progress_status: { label: values?.progress_status, value: values?.progress_status },
			assignee_id: createAssigneeOption(values?.assignee),
			watcher_ids: values?.watchers.map((watcher) => createWatcherOption(watcher)) || [],
			client_ids: values?.clients.map((client) => createClientOption(client)) || [],
			tags: Array.isArray(values?.tags)
				? values?.tags.map((tag) => ({ label: tag?.tag, value: tag?.tag }))
				: [],
			workflow_id: { label: values?.workflow?.title, value: values?.workflow?.id },
			due_date: formatDateForCalendar(values?.due_date),
			description: values?.description,
			attachments:
				values?.attachments.map((attachment) => ({
					file: attachment,
					id: attachment?.id,
				})) || [],
			custom_fields: [],
		}
	} catch (error) {
		console.log('error', error)
		return {
			assignee_id: null,
			watcher_ids: [],
			client_ids: [],
			tags: [],
			workflow_id: null,
			custom_fields: [],
		}
	}
}

export const validate = {
	title: required('title', 'enter'),
	assignee_id: required('assignee', 'select'),
	watcher_ids: required('watcher', 'select'),
	client_ids: required('client', 'select'),
	workflow_id: required('workflow', 'select'),
	due_date: required('due date', 'select'),
	description: required('description', 'enter'),
}

export const copyToClipboard = (text) => {
	const textarea = document.createElement('textarea')

	textarea.value = text
	textarea.style.position = 'fixed'
	textarea.style.top = '0'
	textarea.style.left = '0'
	textarea.style.opacity = '0'

	document.body.appendChild(textarea)

	textarea.select()

	try {
		document.execCommand('copy')
	} catch (err) {
		//
	} finally {
		document.body.removeChild(textarea)
	}
}
