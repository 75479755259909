import { Field } from 'formik'

import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { createOptionLabel } from '.'
import WorkflowQuery from './workflow-query'

const WorkflowDropdown = () => {
	const [search, setSearch] = useState('')
	const { workflows, isLoading } = WorkflowQuery(search)

	const filterOption = ({ data }, search) => {
		if (data.title.toLowerCase().includes(search.toLowerCase())) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Workflow Template'
			type='select'
			name='workflow'
			component={SelectField}
			isRequiredField={true}
			labelHelperText={true}
			isLoading={isLoading}
			options={workflows}
			onInputChange={(value) => setSearch(value)}
			getOptionLabel={(option) => createOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default WorkflowDropdown
