import { Fragment } from 'react'
import { Col, Row, TabContent, TabPane } from 'reactstrap'

import { Button } from 'theme/ui/forms'
import PdfFieldRender from '../../../../components/PdfFieldRender'

const StepByStep = ({
	fields,
	activeField,
	total_fields,
	docs,
	toggleField,
	handleNext,
	error,
	note,
	isFieldSaved,
	setIsFieldSaved,
	disableNext,
}) => {
	return (
		<Fragment>
			{fields.length > 0 ? (
				<div>
					{total_fields > 0 ? (
						<div>
							<h6>Fix the following fields of information</h6>
							{note ? (
								<p>
									<strong>Note :- </strong> &nbsp; {note}
								</p>
							) : undefined}
						</div>
					) : undefined}
					<Row className='justify-content-between'>
						<Col sm={6}>
							<div className='wizard clearfix'>
								<div className='content clearfix'>
									<TabContent activeTab={activeField}>
										{fields.map((field, i) => {
											return (
												<TabPane tabId={i + 1} key={i}>
													<PdfFieldRender
														field={field}
														i={i}
														error={error}
													/>
												</TabPane>
											)
										})}
									</TabContent>
									<div className='actions clearfix'>
										<ul className='steps-btn'>
											<li>
												<Button
													disabled={activeField === 1}
													size='sm'
													onClick={() => {
														setIsFieldSaved(false)
														toggleField(activeField - 1)
													}}>
													Previous
												</Button>
											</li>
											<li>
												<Button
													variant={isFieldSaved ? 'success' : 'primary'}
													size='sm'
													onClick={handleNext}
													disabled={isFieldSaved || disableNext}>
													{activeField === total_fields ? (
														<Fragment>
															{isFieldSaved ? 'Saved' : 'Save'}
														</Fragment>
													) : (
														'Next'
													)}
												</Button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</Col>

						<Col sm={4}>
							{total_fields > 0 && (
								<div className='d-flex justify-content-end'>
									<span className='mb-3' style={{ float: 'right' }}>
										Field {activeField} of {total_fields}
									</span>
								</div>
							)}
							<div className='prose'>
								<h6>This field is in the following documents</h6>

								<ol className='text-sm'>
									{docs.length > 0 ? (
										<Fragment>
											{docs.map((item, idx) => (
												<li key={idx} className='active-doc'>
													{item?.title}
												</li>
											))}
										</Fragment>
									) : (
										<Fragment>
											{Array.isArray(fields[0]?.documents) && (
												<Fragment>
													{fields[0]?.documents.map((item, idx) => (
														<li key={idx} className='active-doc'>
															{item?.title}
														</li>
													))}
												</Fragment>
											)}
										</Fragment>
									)}
								</ol>
							</div>
						</Col>
					</Row>
				</div>
			) : (
				<div className='alert alert-info mx-auto max-w-md'>
					No profile field is required to be filled here. Please proceed.
				</div>
			)}
		</Fragment>
	)
}

export default StepByStep
