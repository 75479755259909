import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { showErrorToast, useQuery } from 'utils/commonFunctions';
import { getEmail } from '..';

const Wrapper = ({ children }) => {
    const { token } = useQuery(window.location.search)
    const email = getEmail(token)
    const { email: savedEmail } = useSelector(state => state.clientOnboarding)
    const isValidToken = savedEmail === email

    useEffect(() => {
        if (!isValidToken) {
            showErrorToast('Invalid token')
            setTimeout(() => {
                window.location.href = '/login'
            }, 2000);
        }
    }, [isValidToken])

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}

export default Wrapper