import Header from 'modules/notifications/header'
import NotificationsList from 'modules/notifications/notification-menu/notification-list'
import AppLayout from 'theme/layouts/app-layout'

const NotificationListing = () => {
	return (
		<AppLayout
			meta={{
				title: 'Notifications',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				<div className='page-container'>
					<div className='page-header bg-white'>
						<Header />
					</div>

					<div className='page-body'>
						<NotificationsList isReadAllPage={true}/>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default NotificationListing
