import { RadioComponent } from 'components/Forms/RadioComponent'
import { TextField } from 'components/Forms/TextFieldComponent'
import { Fragment } from 'react'
import { Field } from 'redux-form'
import { validateVerifyInfo } from '.'

const Tab3 = ({ client }) => {
	const STEP_KEY = 'verify_info'
	const step = client.find((item) => item?.user_key === STEP_KEY)
	const user = step?.user

	return (
		<Fragment>
			{step ? (
				<div className='space-y-6'>
					<div className='grid grid-cols-2 gap-x-6 gap-y-3'>
						<Field
							type='text'
							label='First Name'
							id={`step.${STEP_KEY}.user.first_name`}
							name={`step.${STEP_KEY}.user.first_name`}
							component={TextField}
							validate={validateVerifyInfo.user.first_name}
						/>

						<Field
							type='text'
							label='Last Name'
							id={`step.${STEP_KEY}.user.last_name`}
							name={`step.${STEP_KEY}.user.last_name`}
							component={TextField}
							validate={validateVerifyInfo.user.last_name}
						/>

						<div className='form-group mb-3'>
							<label>Email</label>
							<input
								type='text'
								className='form-control'
								readOnly
								defaultValue={user?.email}
							/>
						</div>

						<div className='form-group mb-3'>
							<label>Phone Number</label>
							<input
								type='text'
								className='form-control'
								readOnly
								defaultValue={user?.phone_number}
							/>
						</div>

						<Field
							type='text'
							label='Address line 1'
							id={`step.${STEP_KEY}.user.address1`}
							name={`step.${STEP_KEY}.user.address1`}
							component={TextField}
							validate={validateVerifyInfo.address1}
							className={{
								formGroupClass: 'col-span-2',
							}}
						/>

						<Field
							type='text'
							label='Address line 2'
							id={`step.${STEP_KEY}.user.address2`}
							name={`step.${STEP_KEY}.user.address2`}
							component={TextField}
							className={{
								formGroupClass: 'col-span-2',
							}}
						/>

						<Field
							type='text'
							label='Unit'
							id={`step.${STEP_KEY}.user.unit`}
							name={`step.${STEP_KEY}.user.unit`}
							component={TextField}
						/>

						<Field
							type='text'
							label='City'
							id={`step.${STEP_KEY}.user.city`}
							name={`step.${STEP_KEY}.user.city`}
							component={TextField}
							validate={validateVerifyInfo.city}
						/>

						<Field
							type='text'
							label='Province'
							id={`step.${STEP_KEY}.user.province`}
							name={`step.${STEP_KEY}.user.province`}
							component={TextField}
							validate={validateVerifyInfo.province}
						/>

						<Field
							type='text'
							label='Zip Code'
							id={`step.${STEP_KEY}.user.zip_code`}
							name={`step.${STEP_KEY}.user.zip_code`}
							component={TextField}
							validate={validateVerifyInfo.zip_code}
						/>

						<Field
							type='text'
							label='Country'
							id={`step.${STEP_KEY}.user.country`}
							name={`step.${STEP_KEY}.user.country`}
							component={TextField}
							validate={validateVerifyInfo.country}
						/>
					</div>

					<div>
						<label>Status:</label>
						<div className='flex space-x-5'>
							<Field
								id={`step-${STEP_KEY}-status-approved`}
								label='Approved'
								name={`step.${STEP_KEY}.status`}
								type='radio'
								component={RadioComponent}
								value='1'
							/>

							<Field
								id={`step-${STEP_KEY}-status-rejected`}
								label='Rejected'
								name={`step.${STEP_KEY}.status`}
								type='radio'
								component={RadioComponent}
								value='0'
							/>
						</div>
					</div>

					<Field
						label='Addtional Notes :'
						type='textarea'
						name={`step.${STEP_KEY}.reason`}
						component={TextField}
					/>
				</div>
			) : (
				<div className='alert alert-warning'>Step not found</div>
			)}
		</Fragment>
	)
}

export default Tab3
