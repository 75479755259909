/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'

import { SpinLoader } from 'components/Common'
import { useLazyGetPublicNotesQuery } from 'modules/notes/notes-api'

const Notes = () => {
	const [fetchNotes, { data, isLoading }] = useLazyGetPublicNotesQuery()

	useEffect(() => {
		fetchNotes({})
	}, [])

	const notes = data?.notes || []

	return (
		<Fragment>
			<Card>
				<CardHeader>
					<CardTitle>Notes</CardTitle>
				</CardHeader>
				<CardBody>
					{isLoading ? (
						<SpinLoader />
					) : (
						<Row>
							{notes.length > 0 ? (
								notes.map((note, index) => {
									let noteTitle = note.notes
									let postfix = note.notes.length > 150 ? '...' : ''
									return (
										<Fragment key={index}>
											<Col sm={3} md={3} xs={12}>
												<Card className='note-box-dashboard' color='info'>
													<CardBody>
														{noteTitle.substring(0, 150) + postfix}
													</CardBody>
												</Card>
											</Col>
										</Fragment>
									)
								})
							) : (
								<Fragment>
									<Col sm={12} md={12} xs={12} className='text-center'>
										<p>No notes found</p>
									</Col>
								</Fragment>
							)}
						</Row>
					)}
				</CardBody>
			</Card>
		</Fragment>
	)
}

export default Notes
