import { Fragment, useState } from 'react'

import { Pagination, SoftLoader } from 'components/Common'
import { useLazyGetNoteQuery } from '../notes-api'
import NotesDetails from '../notes-details'
import NoteCard from './note-card'
const NotesListingContainer = ({ notes, total, limit, page, onPageChange }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [fetchNote, { data, isLoading }] = useLazyGetNoteQuery()

	const onClose = () => setIsOpen(false)
	const onOpen = () => setIsOpen(true)

	const onViewNote = (id) => {
		fetchNote(id).then((response) => {
			if (response?.data?.note) {
				onOpen()
			}
		})
	}

	return (
		<Fragment>
			{isLoading ? <SoftLoader /> : undefined}
			<NotesDetails isOpen={isOpen} onClose={onClose} note={data?.note} />

			{notes.length > 0 ? (
				<div className='mb-3 grid grid-cols-2 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
					{notes.map((note, idx) => (
						<NoteCard note={note} key={idx} onViewNote={onViewNote} />
					))}
				</div>
			) : (
				<div className='mx-auto w-full text-center'>
					<div className='text-center' style={{ padding: '12rem 0px' }}>
						<h5>No Notes Found</h5>
					</div>
				</div>
			)}

			{notes.length > 0 ? (
				<div className='d-flex justify-content-center'>
					<Pagination
						total={total}
						limit={limit}
						currentPage={page}
						onPageChange={onPageChange}
					/>
				</div>
			) : undefined}
		</Fragment>
	)
}

export default NotesListingContainer
