import * as yup from 'yup'
import NoteForm from './note-form'

export const getPayload = (values) => {
	return {
		visibility: values?.visibility,
		teams: Array.isArray(values?.teams) ? values?.teams.map((t) => ({ id: t?.value })) : [],
		notes: values?.notes,
	}
}

export const validationSchema = yup.object().shape({
	visibility: yup.string().required('Please choose the visibility of note.'),
	teams: yup.array().min(1).required('Please select the team.'),
	notes: yup.string().required('Please enter the note description'),
})

export default NoteForm
