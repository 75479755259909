import { useParams } from 'react-router-dom'

import CompletedWorkflowDetails from 'modules/workflows/completed-workflow-details'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from 'utils/checkPermission'

const WorkflowOutstandingsDetails = () => {
	const isReadAble = hasPermission('workflow:read')
	const params = useParams()
	const id = params?.id

	return (
		<AppLayout
			meta={{
				title: 'Workflow Details',
			}}>
			<div className='page-content px-0 !pt-[70px] !font-ibmplex'>
				{isReadAble ? (
					<div className='page-container'>
						<PageHeader
							title='Workflow Details'
							hasBackButton={true}
							description='The most important feature in the workflow section is the creating one. When assigning documents/packages here,
do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						/>
						<div className='page-body'>
							<CompletedWorkflowDetails id={id} />
						</div>
					</div>
				) : (
					<UnAuthorize />
				)}
			</div>
		</AppLayout>
	)
}

export default WorkflowOutstandingsDetails
