import { useMemo } from 'react'
import { Card, CardBody } from 'reactstrap'
import NoteTeamDetails from './note-team-details'
import NotesAction from './notes-action'

const NoteCard = ({ note, onViewNote }) => {
	const { teams, users } = useMemo(() => {
		return {
			teams: Array.isArray(note.teams) ? note.teams : [],
			users: Array.isArray(note.users) ? note.users : [],
		}
	}, [note])

	return (
		<Card className='note-box m-0 max-h-[220px]' id={`tooltip_${note?.id}`}>
			<CardBody className='note-box-body'>
				<p
					className='m-0 line-clamp-3 hover:cursor-pointer hover:text-main hover:underline'
					onClick={() => onViewNote(note?.id)}>
					{note?.notes}
				</p>
				<div className='border-top note-box-footer pt-1'>
					<NoteTeamDetails teams={teams} users={users} />
					<NotesAction note={note} />
				</div>
			</CardBody>
		</Card>
	)
}

export default NoteCard
