import { useState } from 'react'
import { Field } from 'redux-form'

import { SelectDropdown } from 'components/Forms2'
import AssigneeOptionsQuery from './AssigneeOptionsQuery'

const AssigneeDropdown = ({ validate, label }) => {
	const [search, setSearch] = useState('')
	const { assigneeOptions, isLoading } = AssigneeOptionsQuery(search)

	return (
		<Field
			label={label}
			name='assignee_id'
			type='select'
			component={SelectDropdown}
			variant='horizontal'
			width={'w-56'}
			options={assigneeOptions}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			validate={validate}
			isClearable={true}
			placeholder='Select individual...'
		/>
	)
}

export default AssigneeDropdown
