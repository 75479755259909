import ManageWorkflow from './manage-workflow'
import { fillClientValues } from './utils'

export const getDocumentsPayload = (
	documentList,
	documentBoundFieldValues,
	fieldValues,
	user_type,
) => {
	return documentList.map((document, idx) => {
		const $filledFields = fillClientValues(
			document?.document_body,
			fieldValues,
			user_type,
			false,
		)
		const boundFields = Array.isArray(documentBoundFieldValues[idx]?.fields)
			? documentBoundFieldValues[idx]?.fields
			: []

		const $boundFields = fillClientValues($filledFields, boundFields, user_type, true)

		return {
			...document,
			document_body: $boundFields,
		}
	})
}

export const getPayload = (
	workflow_id,
	upload_step_id,
	individual_document_step_id,
	documentsPayload,
) => {
	const payload = {
		method_type: 'Submit',
		workflow_id: workflow_id,
		workflow_steps: [
			{
				workflow_step_id: upload_step_id,
				type: 'uploads',
				notify: false,
				documents: [],
			},
			{
				workflow_step_id: individual_document_step_id,
				type: 'individual_document',
				notify: false,
				documents: documentsPayload,
			},
		],
	}

	return payload
}

export default ManageWorkflow
