import { useHistory } from 'react-router-dom'
import { TabContent, TabPane } from 'reactstrap'

import { useState } from 'react'
import Tab1 from '../Tab1'
import Tab2 from '../Tab2'
import Tab3 from '../Tab3'
import Tab4 from '../Tab4'
import Tab5 from '../Tab5'
import { DocumentViewerProvider } from '../document-viewer-provider'

const TabContentContainer = ({
	id,
	mutate,
	uploads,
	workflow,
	toggleTab,
	activeTab,
	isLoading,
	createdBy,
	user_type,
	CompleteSteps,
	currentStatus,
	setCompleteSteps,
	tab2InitialValues,
	individualDocument,
}) => {
	const history = useHistory()
	const [loader, setLoader] = useState(false)
	const screen_type = workflow?.screen_type || 'step_by_step'

	return (
		<TabContent activeTab={activeTab}>
			<TabPane tabId={1}>
				<Tab1
					user_type={user_type}
					uploads={uploads}
					workflow_id={Number(id)}
					createdBy={createdBy}
					CompleteSteps={CompleteSteps}
					setCompleteSteps={setCompleteSteps}
					toggleTab={toggleTab}
					activeTab={activeTab}
					isLoading={isLoading}
					individual_document={individualDocument}
				/>
			</TabPane>
			<TabPane tabId={2}>
				<Tab2
					screen_type={screen_type}
					user_type={user_type}
					individual_document={individualDocument}
					createdBy={createdBy}
					CompleteSteps={CompleteSteps}
					setCompleteSteps={setCompleteSteps}
					toggleTab={toggleTab}
					activeTab={activeTab}
					loader={loader}
					setLoader={setLoader}
					id={id}
					uploads_id={uploads?.id}
					isLoading={isLoading}
					initialValues={tab2InitialValues}
				/>
			</TabPane>
			<TabPane tabId={3}>
				<Tab3
					user_type={user_type}
					currentStatus={currentStatus}
					createdBy={createdBy}
					individual_document={individualDocument}
					uploads={uploads}
					CompleteSteps={CompleteSteps}
					toggleTab={toggleTab}
					activeTab={activeTab}
				/>
			</TabPane>
			<TabPane tabId={4}>
				<Tab4
					screen_type={screen_type}
					user_type={user_type}
					workflow_id={Number(id)}
					createdBy={createdBy}
					individual_document={individualDocument}
					uploads={uploads}
					currentStatus={currentStatus}
					CompleteSteps={CompleteSteps}
					setCompleteSteps={setCompleteSteps}
					toggleTab={toggleTab}
					activeTab={activeTab}
					mutate={mutate}
				/>
			</TabPane>
			<TabPane tabId={5}>
				{workflow && (
					<DocumentViewerProvider>
						<Tab5
							CompleteSteps={CompleteSteps}
							toggleTab={toggleTab}
							activeTab={activeTab}
							workflow={workflow}
							individualDocument={individualDocument}
							history={history}
							mutateWorkflow={mutate}
						/>
					</DocumentViewerProvider>
				)}
			</TabPane>
		</TabContent>
	)
}

export default TabContentContainer
