import { useDebounce } from 'use-debounce'

import apiEndpoints from 'helpers/apiEndpoints'
import { useQuery } from 'hooks'
import { useMemo } from 'react'
import { toQueryString } from 'utils/commonFunctions'
import { createCoApplicantOption } from '.'

const CoApplicantsQuery = (search, formValues, client_applicants, fieldName) => {
	const [debounceSearch] = useDebounce(search, 600)

	const params = useMemo(() => {
		const paramsObj = { page: 1, limit: 20, role: 'client' }
		if (debounceSearch) {
			Object.assign(paramsObj, { search: debounceSearch })
		}
		return paramsObj
	}, [debounceSearch])

	const query = toQueryString(params)
	const url = `${apiEndpoints.clients}?${query}`

	const { data, isLoading } = useQuery(url)

	const options =
		data?.clients[0].map((client) =>
			createCoApplicantOption(client, formValues, client_applicants, fieldName),
		) || []

	return {
		clients: options,
		isLoading,
	}
}

export default CoApplicantsQuery
