export const createWatcherOption = (watcher) => {
	return {
		label: (
			<div className='line-clamp-1 flex max-w-min flex-col'>
				<span className='text-sm font-normal text-[#495057]'>
					{watcher?.first_name}&nbsp;{watcher?.last_name}
				</span>
				<span
					className='text-xs font-normal text-gray-400'
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}>
					{watcher?.email}
				</span>
			</div>
		),
		value: watcher?.id,
	}
}
