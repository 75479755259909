import Header from 'modules/clients/header'
import InvitedClientsContainer from 'modules/clients/invited-clients'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from 'utils/checkPermission'

const InvitedClientsListing = () => {
	const isListPermission = hasPermission('client:list')

	return (
		<AppLayout
			meta={{
				title: 'Invited Clients',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<InvitedClientsContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default InvitedClientsListing
