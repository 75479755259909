import FieldType from './field-type'

const PdfFieldRender = ({ field, fieldName, error }) => {
	return (
		<div className='flex flex-col'>
			<FieldType field={field} fieldName={fieldName} />
			{error ? <div className='invalid-feedback block'>{error}</div> : undefined}
		</div>
	)
}

export default PdfFieldRender
