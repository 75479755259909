import TabContentContainer from './tab-content-container'

export const fieldTabsList = [
	{ id: 0, label: 'Profile Fields' },
	{ id: 1, label: 'Document Bound Fields' },
]

export const getTabs = (isDocumentFields) => {
	return fieldTabsList.filter((tab) => (isDocumentFields ? tab?.id <= 1 : tab?.id < 1))
}

export default TabContentContainer
