import classNames from 'classnames'
import { routes } from 'modules/workflows'

import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import notification from 'utilities/notification'
import { toSnakeCase } from 'utils/commonFunctions'
import { useCancelWorkflowOutstandingMutation } from '../workflow-outstandings-apis'

const Actions = ({ workflow }) => {
	const [cancelWorkflow, { isLoading: isCancelling }] = useCancelWorkflowOutstandingMutation()

	const isCancellable =
		toSnakeCase(workflow?.status) === 'completed' ||
		toSnakeCase(workflow?.status) === 'cancelled'

	const isReviewAble = ![
		'assigned',
		'approved',
		'completed',
		'cancelled',
		'waiting_for_signatures',
		'rejected',
	].includes(toSnakeCase(workflow?.status))

	const isReadAble = toSnakeCase(workflow?.status) === 'completed'

	const onCancel = async (id) => {
		const res = await Swal.fire({
			title: 'Are you sure you want to cancel this workflow?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
		})
		if (res?.isConfirmed) {
			cancelWorkflow(id).then((response) => {
				if (response?.data?.status === 200) {
					notification('success', response?.message)
				}
			})
		}
	}

	return (
		<div className='flex space-x-1'>
			{isReadAble ? (
				<div
					className={classNames({
						'cursor-not-allowed': !isReadAble,
					})}>
					<Link
						className={classNames('action-btn btn-gray', {
							'pointer-events-none !bg-gray-500 opacity-50': !isReadAble,
						})}
						to={{
							pathname: isReadAble ? routes.view(workflow?.id) : '#',
						}}>
						View
					</Link>
				</div>
			) : undefined}

			{isReviewAble ? (
				<div
					className={classNames({
						'cursor-not-allowed': !isReviewAble,
					})}>
					<Link
						className={classNames('action-btn  btn-gray', {
							'pointer-events-none !bg-gray-500 opacity-50': !isReviewAble,
						})}
						to={{
							pathname: isReviewAble ? routes.review(workflow?.id) : '#',
						}}>
						Review
					</Link>
				</div>
			) : undefined}

			{!isCancellable ? (
				<div
					className={classNames({
						'cursor-not-allowed': isCancellable,
					})}>
					<button
						onClick={() => onCancel(workflow?.id)}
						disabled={isCancelling || isCancellable}
						className={classNames('action-btn  bg-red-500 text-white', {
							'pointer-events-none !border-gray-500 !bg-gray-500 opacity-50':
								isCancellable,
						})}>
						{isCancelling ? 'Cancelling...' : 'Cancel'}
					</button>
				</div>
			) : undefined}
		</div>
	)
}

export default Actions
