import { snakeCase } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

import experiviseLightLogo from 'assets/images/experivise-logo-white.png'
import { SpinLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import RegisterForm, { CLIENT_ROLE, getDecodedValues } from 'modules/authentication/register'
import { getParseQueryString } from 'utilities/helpers'
import notification from 'utilities/notification'
import request from 'utilities/request'
import { add, get } from 'utils/storageService'

const Register = () => {
	const history = useHistory()
	const location = useLocation()
	const parsedQuery = getParseQueryString(location?.search)
	const token = parsedQuery?.token
	const [isValidating, setIsValidating] = useState(true)
	const [initialValues, setInitialValues] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		password: '',
		passwordConfirm: '',
		team_name: '',
		invite_user: false,
	})

	useEffect(() => {
		if (token) {
			const payload = { token }
			request
				.post(apiEndpoints.user.validateInvitation, payload)
				.then((response) => {
					if (response?.status === 200) {
						const { role_name, email, first_name, last_name, team_name } =
							getDecodedValues(token)
						setInitialValues({
							...initialValues,
							email: email,
							first_name: first_name,
							last_name: last_name,
							team_name: team_name,
							invite_user: true,
						})
						setIsValidating(false)
						if (response?.isUserExist) {
							history.push({ pathname: '/login', search: '?token=' + token })
							notification(
								'Your Account is already registered with us. Please login to continue.',
							)
						}
						if (!response?.isUserExist && snakeCase(role_name) === CLIENT_ROLE) {
							history.push({
								pathname: '/onboarding/step-one',
								search: '?token=' + token,
							})
						}
					}
				})
				.catch(() => {
					history.push('/login')
				})
		} else {
			// history.push('/login')
			setIsValidating(false)
		}
	}, [token])

	useEffect(() => {
		document.body.className = 'authentication-bg'
		return function cleanup() {
			document.body.className = ''
		}
	})

	useEffect(() => {
		if (!get('logout')) {
			add('logout', true)
		}
	}, [])

	return (
		<div className='account-pages pt-sm-3 my-12'>
			<Container>
				<Row className='align-items-center justify-content-center'>
					<Col md={8} lg={6} xl={5}>
						<Card>
							<CardBody className='p-4'>
								<div className='mt-2 text-center'>
									<Row>
										<Col lg={12}>
											<div className='text-center'>
												<div className='d-block auth-logo'>
													<img
														src={experiviseLightLogo}
														alt=''
														style={{ height: 100 }}
														className='logo logo-dark'
													/>
												</div>
											</div>
										</Col>
									</Row>
									<h5 className='text-primary'>Register Account</h5>
									<p className='text-muted'>Get your Experivise account now.</p>
								</div>

								{isValidating ? (
									<div className='!my-10 flex flex-col items-center space-y-3'>
										<SpinLoader />
										<span className='text-center '>
											Please wait while we verify your onboarding invitation
											link.
										</span>
									</div>
								) : (
									<RegisterForm initialValues={initialValues} />
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default Register
