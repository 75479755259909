import { Link } from 'react-router-dom'

import { formatDate } from 'utilities/helpers'
import { toSnakeCase } from 'utils/commonFunctions'
import { CLIENT_APP_ROUTES } from '../routes'

const Actions = ({ isActionActive, client }) => {
	return (
		<div className='w-[170px]'>
			{isActionActive ? (
				<div className='flex space-x-3'>
					{toSnakeCase(client?.on_board_status) === 'pending' ? (
						<button type='button' className='action-btn btn-primary-outline' disabled>
							<i className='far fa-edit' />
							<span>Edit</span>
						</button>
					) : (
						<Link
							to={CLIENT_APP_ROUTES.verifyClient(client?.id)}
							className='action-btn btn-primary-outline'>
							<i className='far fa-edit' />
							<span>Edit</span>
						</Link>
					)}
				</div>
			) : (
				<span className=' text-sm !text-gray-700'>{formatDate(client?.created_at)}</span>
			)}
		</div>
	)
}

export default Actions
