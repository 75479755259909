import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { SpinLoader } from 'components/Common'
import { useLazyGetOneClientQuery } from 'modules/clients/client-apis'
import ClientDetailsCard from 'modules/clients/client-details'
import { NotFound } from 'modules/error-sections'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from 'utils/checkPermission'

const ClientDetails = () => {
	const params = useParams()
	const isReadPermission = hasPermission('client:read')
	const [fetchClient, { data, isFetching: isLoading, isSuccess }] = useLazyGetOneClientQuery(
		params?.id,
	)

	useEffect(() => {
		if (params?.id) {
			fetchClient(params?.id)
		}
	}, [params?.id])

	return (
		<AppLayout
			meta={{
				title: 'Client details',
			}}>
			{isReadPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title='Client Details'
							description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
							hasBackButton={true}
						/>
						<div className='page-body'>
							{isLoading ? (
								<SpinLoader />
							) : (
								<>
									{isSuccess ? (
										<ClientDetailsCard
											client={data?.client}
											role={data?.role}
										/>
									) : (
										<NotFound />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default ClientDetails
