import { Redirect } from 'react-router-dom'
import Callback from '../Callback'
// Authentication related pages
import ForgetPwd from '../pages/Authentication/forgot-password/ForgetPassword'

import Logout from '../pages/Authentication/Logout'

import ResetPassword from '../pages/Authentication/reset-password/ResetPassword'
// team manage
import TeamManage from '../pages/Authentication/profile/components/TeamManage'
// client workflows

// documents
// import DocumentsManage from '../pages/Documents/DocumentsManage'

import Pages404 from '../pages/StaticPages/Page404'

// client onboarding
import {
	DocumentList,
	SINNumber,
	TakeSelfie,
	TakeSelfieFromPhone,
	UploadDocuments,
	VerifyInformation,
} from 'pages/Authentication/client-document-upload'
import SelfieTokenExpired from 'pages/Authentication/client-document-upload/components/SelfieTokenExpired'
import ThankYouPage from 'pages/Authentication/client-document-upload/components/ThankYouPage'
import {
	StepFive,
	StepFour,
	StepOne,
	StepThree,
	StepTwo,
} from 'pages/Authentication/client-onboarding'

import { TasksList } from 'pages/Tasks'

import { SystemFieldsCreate, SystemFieldsEdit, SystemFieldsList } from 'pages/system-fields'

import {
	WorkflowTemplateAssign,
	WorkflowTemplateCreate,
	WorkflowTemplateEdit,
	WorkflowTemplatesListing,
} from 'pages/Workflows/workflow-template'

import {
	WorkflowFinalReviewManage,
	WorkflowFinalReviewsListing,
} from 'pages/Workflows/workflow-final-review'

import { Login, Register } from 'pages/Auth2'

import {
	AssignedWorkflowManage,
	AssignedWorkflowsListing,
} from 'pages/Workflows/assigned-workflows'

import { CompletedDocumentsListing, DocumentPackageListing } from 'pages/Documents'

import { CLIENT_APP_ROUTES } from 'modules/clients/routes'
import {
	ClientDetails,
	ClientImport,
	ClientInvite,
	ClientsListing,
	ClientVerify,
	InvitedClientsListing,
} from 'pages/Clients'

import { DOCUMENT_TEMPLATE_APP_ROUTES } from 'modules/documents/routes'

import ReviewInformation from 'pages/Authentication/client-document-upload/review-information'
import Dashboard from 'pages/Dashboard'
import {
	CreateDocumentTemplate,
	DeletedDocumentTemplateListing,
	DocumentTemplatesListing,
	EditDocumentTemplate,
} from 'pages/Documents/document-template'
import { RoleManage } from 'pages/Groups'
import { NoteCreate, NoteEdit, NotesListing } from 'pages/Notes'
import { Profile, ProfileUpdate } from 'pages/profile'

import { TEAM_APP_ROUTES } from 'modules/teams'
import { TeamDetails, TeamsListing, TeamsSubscribe, TeamUserManage } from 'pages/Teams'

import { UserDetails, UsersListing } from 'pages/Users'
import {
	WorkflowOutstandingsDetails,
	WorkflowOutstandingsReview,
	WorkflowsOutstandingsListing,
} from 'pages/Workflows/workflows-outstandings'

import { FEATURES_APP_ROUTES, SUBSCRIPTION_APP_ROUTES } from 'modules/subscriptions/routes'

import { EMAIL_TEMPLATES_APP_ROUTES } from 'modules/email-templates/routes'
import { NOTES_APP_ROUTES } from 'modules/notes/routes'
import { NOTIFICATIONS_TEMPLATES_APP_ROUTES } from 'modules/notification-templates/routes'
import { NOTIFICATIONS_APP_ROUTES } from 'modules/notifications/routes'
import { PENDING_SUB_APP_ROUTES } from 'modules/payments/pending-subscription/routes'
import { PAYMENTS_APP_ROUTES } from 'modules/payments/routes'
import { ROLES_APP_ROUTES } from 'modules/roles/routes'
import { SETTINGS_APP_ROUTES } from 'modules/settings/routes'
import { TEAM_MEMBER_APP_ROUTES } from 'modules/team-members/routes'
import { EmailTemplatesEdit, EmailTemplatesListing } from 'pages/email-templates'
import RolesListing from 'pages/Groups/roles-listing'
import { Notifications } from 'pages/Notifications'
import {
	NotificationsTemplatesEdit,
	NotificationsTemplatesListing,
} from 'pages/notifications-templates'
import { PaymentDetails, PaymentListing } from 'pages/Payments'
import PaymentEdit from 'pages/Payments/payment-edit'
import PendingSubscriptionListing from 'pages/Pending-subscription/pending-subscription-listing'
import { SettingsList } from 'pages/settings'
import {
	SubscriptionCreate,
	SubscriptionDetails,
	SubscriptionEdit,
	SubscriptionLising,
} from 'pages/subscriptions'
import {
	FeatureCreate,
	FeatureDetails,
	FeatureEdit,
	FeatureListing,
} from 'pages/subscriptions/features'
import {
	TeamMemberInvite,
	TeamMembersInvitationListing,
	TeamMembersListing,
} from 'pages/team-members'

const userRoutes = [
	{ path: '/dashboard', component: Dashboard },

	// //profile
	{ path: '/profile', component: Profile },
	{ path: '/profile-update/:token', component: ProfileUpdate },
	{ path: '/profile/create-team', component: TeamManage },

	// groups
	{ path: ROLES_APP_ROUTES.findAll(), component: RolesListing },
	// { path: '/roles/create', component: GroupsManage },
	{ path: ROLES_APP_ROUTES.edit(':id'), component: RoleManage },

	// users
	{ path: '/users', component: UsersListing },
	// { path: '/users/create', component: UsersManage },
	{ path: '/users/:id/show', component: UserDetails },

	// teams
	{ path: TEAM_APP_ROUTES.findAll(), component: TeamsListing },
	{ path: TEAM_APP_ROUTES.findOne(':id'), component: TeamDetails },
	{ path: TEAM_APP_ROUTES.subscribe(':id'), component: TeamsSubscribe },
	{ path: TEAM_APP_ROUTES.editUser(':teamId', ':userId'), component: TeamUserManage },
	// { path: '/teams/users/invite', component: TeamUserManage },

	// system fields
	{ path: '/system-fields', component: SystemFieldsList },
	{ path: '/system-fields/create', component: SystemFieldsCreate },
	{ path: '/system-fields/:id/edit', component: SystemFieldsEdit },

	// notes
	{ path: NOTES_APP_ROUTES.findAll(), component: NotesListing },
	{ path: NOTES_APP_ROUTES.create(), component: NoteCreate },
	{ path: NOTES_APP_ROUTES.edit(':id'), component: NoteEdit },

	// subscriptions
	{ path: SUBSCRIPTION_APP_ROUTES.findAll(), component: SubscriptionLising },
	{ path: SUBSCRIPTION_APP_ROUTES.create(), component: SubscriptionCreate },
	{ path: SUBSCRIPTION_APP_ROUTES.edit(':id'), component: SubscriptionEdit },
	{ path: SUBSCRIPTION_APP_ROUTES.findOne(':id'), component: SubscriptionDetails },

	// Payments
	{ path: PAYMENTS_APP_ROUTES.findAll(), component: PaymentListing },
	{ path: PAYMENTS_APP_ROUTES.findOne(':id'), component: PaymentDetails },
	{ path: PAYMENTS_APP_ROUTES.edit(':id'), component: PaymentEdit },
	{ path: PENDING_SUB_APP_ROUTES.findAll(), component: PendingSubscriptionListing },

	// Notifications
	{ path: NOTIFICATIONS_APP_ROUTES.findAll(), component: Notifications },

	// features
	{ path: FEATURES_APP_ROUTES.findAll(), component: FeatureListing },
	{ path: FEATURES_APP_ROUTES.create(), component: FeatureCreate },
	{ path: FEATURES_APP_ROUTES.edit(':id'), component: FeatureEdit },
	{ path: FEATURES_APP_ROUTES.findOne(':id'), component: FeatureDetails },

	// clients
	{ path: CLIENT_APP_ROUTES.findAll(), component: ClientsListing },
	{ path: CLIENT_APP_ROUTES.findAllInvites(), component: InvitedClientsListing },
	{ path: CLIENT_APP_ROUTES.import(), component: ClientImport },
	{ path: CLIENT_APP_ROUTES.invite(), component: ClientInvite },
	{ path: CLIENT_APP_ROUTES.verifyClient(':id'), component: ClientVerify },
	{ path: CLIENT_APP_ROUTES.findOne(':id'), component: ClientDetails },

	// team members
	{ path: TEAM_MEMBER_APP_ROUTES.findAll(), component: TeamMembersListing },
	{ path: TEAM_MEMBER_APP_ROUTES.findAllInvites(), component: TeamMembersInvitationListing },
	{ path: TEAM_MEMBER_APP_ROUTES.invite(), component: TeamMemberInvite },

	// documents
	{ path: DOCUMENT_TEMPLATE_APP_ROUTES.findAll(), component: DocumentTemplatesListing },
	{
		path: DOCUMENT_TEMPLATE_APP_ROUTES.findAllDeleted(),
		component: DeletedDocumentTemplateListing,
	},
	{ path: DOCUMENT_TEMPLATE_APP_ROUTES.create(), component: CreateDocumentTemplate },
	{ path: DOCUMENT_TEMPLATE_APP_ROUTES.edit(':id'), component: EditDocumentTemplate },

	{ path: '/document-packages', component: DocumentPackageListing },
	{ path: '/completed-documents', component: CompletedDocumentsListing },

	// create documents
	{ path: '/new-documents/create', component: CreateDocumentTemplate },
	{ path: '/new-documents/:id/edit', component: EditDocumentTemplate },

	// workflow templates
	{ path: '/workflows', component: WorkflowTemplatesListing },
	{ path: '/workflows/create', component: WorkflowTemplateCreate },
	{ path: '/workflows/:id/edit', component: WorkflowTemplateEdit },
	{ path: '/workflows/assign', component: WorkflowTemplateAssign },

	// workflow outstandings
	{ path: '/workflows-outstandings', component: WorkflowsOutstandingsListing },
	{ path: '/workflows-outstandings/:id', component: WorkflowOutstandingsDetails },
	{ path: '/workflows-outstandings/:id/review', component: WorkflowOutstandingsReview },

	// workflow outstandings
	{ path: '/workflows-final-reviews', component: WorkflowFinalReviewsListing },
	{ path: '/workflows-final-reviews/:id/review', component: WorkflowFinalReviewManage },

	// client workflows
	{ path: '/assigned-workflows', component: AssignedWorkflowsListing },
	{ path: '/assigned-workflows/:id/fill', component: AssignedWorkflowManage },

	//tasks routes
	{ path: '/tasks', component: TasksList },
	{ path: '/tasks/:id', component: TasksList },
	{ path: '/tasks-templates', component: TasksList },

	//Settings
	{ path: SETTINGS_APP_ROUTES?.findAll(), component: SettingsList },

	//Email templates
	{ path: EMAIL_TEMPLATES_APP_ROUTES?.findAll(), component: EmailTemplatesListing },
	{ path: EMAIL_TEMPLATES_APP_ROUTES?.findOne(':id'), component: EmailTemplatesEdit },

	//Notifications templates
	{
		path: NOTIFICATIONS_TEMPLATES_APP_ROUTES?.findAll(),
		component: NotificationsTemplatesListing,
	},
	{
		path: NOTIFICATIONS_TEMPLATES_APP_ROUTES?.findOne(':id'),
		component: NotificationsTemplatesEdit,
	},

	// this route should be at the end of all other routes
	{ path: '/', exact: true, component: () => <Redirect to='/login' /> },
	{ path: '*', component: Pages404 },
]

const authRoutes = [
	{ path: '/logout', component: Logout },
	{ path: '/login', component: Login },
	{ path: '/forgot-password', component: ForgetPwd },
	{ path: '/reset-password', component: ResetPassword },
	{ path: '/register', component: Register },
	{ path: '/callback', component: Callback },
	{ path: '/onboarding/step-one', component: StepOne },
	{ path: '/onboarding/step-two', component: StepTwo },
	{ path: '/onboarding/step-three', component: StepThree },
	{ path: '/onboarding/step-four', component: StepFour },
	{ path: '/onboarding/step-five', component: StepFive },
	{ path: '/onboarding/take-selfie-from-phone', component: TakeSelfieFromPhone },
	{ path: '/onboarding/page-expired', component: SelfieTokenExpired },
	{ path: '/onboarding/thank-you', component: ThankYouPage },
]

const clientOnBoardingRoutes = [
	// client onboarding document uploads
	{ path: '/onboarding/required-document-list', component: DocumentList },
	{ path: '/onboarding/input-sin-number', component: SINNumber },
	{ path: '/onboarding/upload-documents', component: UploadDocuments },
	{ path: '/onboarding/verify-information', component: VerifyInformation },
	{ path: '/onboarding/take-selfie', component: TakeSelfie },
	{ path: '/onboarding/review-information', component: ReviewInformation },
]

export { authRoutes, clientOnBoardingRoutes, userRoutes }

