import { Fragment } from 'react'
import { Field } from 'redux-form'
import PdfFieldRender from './pdf-field-render'

const DocumentBoundFieldsTab = ({ documentBoundFields }) => {
	return (
		<div className='flex flex-col divide-y'>
			{documentBoundFields.map((document, idx) => {
				const fieldName = `document_bound_fields[${idx}]`
				return (
					<div key={idx} className='flex py-3'>
						<div className='w-2/5 p-6'>
							<h3 className='mb-2 mt-0 text-lg font-semibold'>
								{idx + 1}. {document?.title}
							</h3>
						</div>
						<div className='not-prose w-3/5 p-6'>
							<div className='grid grid-cols-2 gap-x-3'>
								<Field
									type='hidden'
									name={`${fieldName}.document_title`}
									value={document?.title}
									component='input'
								/>

								<Fragment>
									{document?.fields.length > 0 ? (
										<>
											{document.fields.map((field, fidx) => (
												<div key={fidx}>
													<PdfFieldRender
														fieldName={`${fieldName}.fields[${fidx}].${field?.name}`}
														field={field}
														error={undefined}
													/>
												</div>
											))}
										</>
									) : (
										<p className='col-span-2 m-0 text-center text-sm text-gray-500'>
											No document-bound fields were found here.
										</p>
									)}
								</Fragment>
							</div>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default DocumentBoundFieldsTab
