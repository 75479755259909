import { Field, FieldArray } from 'formik'
import { useMemo, useState } from 'react'

import { useDocmentViewer } from 'pages/Authentication/profile/components/workflow/document-viewer-provider'
import { Button } from 'theme/ui/forms'
import { getCoordinates } from '../pdf-viewer'
import SystemFieldDropdown from '../system-field-dropdown'
import UserDropdown from '../user-dropdown'
import DocumentBoundField from './document-bound-field'

const PdfFields = ({ formik, onAutoSave, document }) => {
	const documentInstance = useDocmentViewer()
	const [lastAnnotation, setLastAnnotation] = useState(null)
	const [annots, setAnnots] = useState([])
	const [page, setPage] = useState(1)

	const pdf_fields = useMemo(
		() => (Array.isArray(document?.pdf_fields) ? document?.pdf_fields : []),
		[document],
	)

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const numberOfUsers = formValues?.min_clients?.value

	const userOptions = useMemo(() => {
		const users = [
			{ label: 'Advisor', value: 'advisor' },
			{ label: 'Client', value: 'client' },
		]
		if (numberOfUsers > 1) {
			users.push(
				...new Array(numberOfUsers - 1).fill(0).map((_, idx) => ({
					label: `Co Applicant ${idx + 1}`,
					value: `applicant_${idx + 1}`,
				})),
			)
		}
		return users
	}, [numberOfUsers])

	const onHighlight = async (field) => {
		try {
			const {
				Annotations,
				annotationManager,
				Math: MathViewer,
				DocumentViewer,
			} = documentInstance.Core
			const coordinates = getCoordinates(field)

			const displayMode = new DocumentViewer()
			displayMode.setCurrentPage(Number(page), true)
			// displayMode.displayPageLocation(Number(page), coordinates.x - 40, coordinates.y - 20)

			if (annots.length > 0) {
				annots.forEach((annotId) => {
					const annot = annotationManager.getAnnotationById(annotId)
					if (annot) {
						annotationManager.deleteAnnotation(annot)
					}
				})
			}

			if (lastAnnotation) {
				await annotationManager.hideAnnotation(lastAnnotation)
			}
			const { Rect } = MathViewer
			annotationManager.deleteAnnotations(annotationManager.getAnnotationsList())
			const quads = Rect.createFromDimensions(
				coordinates.x,
				coordinates.y,
				coordinates.width,
				coordinates.height,
			).toQuad()
			const highlight = new Annotations.TextHighlightAnnotation()
			highlight.PageNumber = page
			highlight.FillColor = [255, 0, 0]
			highlight.StrokeColor = new Annotations.Color(255, 0, 0)
			highlight.Quads = [quads]
			const $annots = [...annots]
			$annots.push(highlight?.Id)
			setAnnots($annots)
			annotationManager.addAnnotation(highlight)
			annotationManager.drawAnnotations(highlight.PageNumber)
			setLastAnnotation(highlight)
		} catch (error) {
			//
		}
	}

	const onMoveToTrash = (field) => {
		return new Promise((resolve) => {
			const delete_document_mapping = formValues?.delete_document_mapping
			formik.setFieldValue('delete_document_mapping', [...delete_document_mapping, field])
			resolve({ message: 'field successfully moved to trash' })
		})
	}

	return (
		<div className='h-[100vh] rounded'>
			<div className='slim-scroll !max-h-[92%] overflow-auto rounded-b bg-white !px-3 !py-3'>
				<FieldArray
					name='document_mapping'
					render={(arrayHelpers) => {
						return (
							<div className='flex flex-col divide-y'>
								{formValues?.document_mapping.map((field, idx) => {
									const pdf_field = pdf_fields.find(
										($field) => $field?.label === field?.pdf_field,
									)

									return (
										<div
											key={idx}
											className='rounded !px-2 !py-4 transition-all hover:bg-slate-50'>
											<div className='!mb-2 flex items-start justify-between space-x-2'>
												<SystemFieldDropdown
													label={field?.pdf_field}
													name={`${arrayHelpers.name}[${idx}].field`}
													onAutoSave={onAutoSave}
													onHighlight={() => onHighlight(pdf_field)}
												/>
												<Field
													type='hidden'
													name={`${arrayHelpers.name}[${idx}].pdf_field`}
													value={field?.pdf_field}
													component='input'
												/>
												<UserDropdown
													name={`${arrayHelpers.name}[${idx}].user`}
													options={userOptions}
													onAutoSave={onAutoSave}
												/>
												<div className='!pt-[33px]'>
													<Button
														type='button'
														size='xs'
														variant='danger-outline'
														onClick={async () => {
															await onMoveToTrash(field)
															arrayHelpers.remove(idx)
														}}>
														<i className='uil-trash-alt' />
													</Button>
												</div>
											</div>

											<DocumentBoundField
												fieldName={`${arrayHelpers.name}[${idx}].is_document_bound`}
											/>
										</div>
									)
								})}
							</div>
						)
					}}
				/>
			</div>
		</div>
	)
}

export default PdfFields
