import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useUser = () => {
	const { user } = useSelector((state) => state.jwtauth)
	const { isSuperAdmin, defaultGroup } = useSelector((state) => state.Permissions)
	const isTeamDisabled = defaultGroup?.team?.status === 0
	const team = defaultGroup?.team
	const group = defaultGroup?.group
	const permissions = useMemo(
		() =>
			Array.isArray(group?.accesses) ? group?.accesses.map((access) => access?.module) : [],
		[group],
	)

	return {
		user,
		isSuperAdmin,
		team,
		group,
		isTeamDisabled,
		permissions,
	}
}

export default useUser
