import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, InputField } from 'theme/ui/forms'
import { getPayload, getUserTypeOptions, validationSchema } from '.'
import {
	useCreateWorkflowTemplateMutation,
	useUpdateWorkflowTemplateMutation,
} from '../workflow-template-apis'
import AssignDocuments from './assign-documents'
import DocumentUploadSection from './document-upload-section'

const WorkflowForm = ({ initialValues, id }) => {
	const history = useHistory()
	const [createWorkflowTemplate] = useCreateWorkflowTemplateMutation()
	const [updateWorkflowTemplate] = useUpdateWorkflowTemplateMutation()

	const formik = useFormik({
		initialValues: {
			title: initialValues?.title || '',
			documents: initialValues?.documents || [],
			document_packages: initialValues?.document_packages || [],
			files: initialValues?.files || [],
			steps: initialValues?.steps || [],
		},
		enableReinitialize: true,
		validationSchema: validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				if (initialValues && id) {
					await updateWorkflowTemplate({ payload, id })
				} else {
					await createWorkflowTemplate(payload)
				}

				setSubmitting(false)
				history.goBack()
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik.values, [formik.values])
	const userTypeOptions = useMemo(() => getUserTypeOptions(formValues), [formValues])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Workflow Title</h3>
								<p>
									Assigning documents/packages here, do not ignore to fill in all
									the required fields completely.
								</p>
							</div>

							<div className='form-fields-card'>
								<Field
									label='Title'
									type='text'
									name='title'
									component={InputField}
									isRequiredField={true}
									labelHelperText={true}
									placeholder='For eg:- Alchemytech, Webosphere'
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Assign Documents</h3>
								<p>
									Listed below are the set of documents which will be needed to
									submit while submitting the above workflow.
								</p>
							</div>

							<div className='form-fields-card space-y-6'>
								<AssignDocuments formik={formik} />
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>
									Documents Upload{' '}
									<span className='text-base font-normal text-gray-600'>
										(Optional)
									</span>
								</h3>
								<p>
									Listed below are the set of documents which will beneeded to
									submit while submitting the above workflow.
								</p>
							</div>

							<div className='form-fields-card'>
								<DocumentUploadSection
									userTypeOptions={userTypeOptions}
									formValues={formValues}
								/>
							</div>
						</div>

						<div className='flex justify-end !py-5'>
							<Button
								type='submit'
								// disabled={formik.isValid}
								isLoading={formik.isSubmitting}
								isLoadingText='Saving...'>
								Save
							</Button>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								When assigning documents/ packages here, do not ignore to fill in
								all the required fields completely and follow the document/package
								adding rules.
							</p>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default WorkflowForm
