/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import apiEndpoints from '../../../../helpers/apiEndpoints'
import * as actions from '../../../../store/common/actions'

const SecontWidget = ({ client }) => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const getLoginHistory = () => {
		setLoading(true)
		dispatch(actions.all(`${apiEndpoints.clients}/login-history/${client?.id}?page=1&limit=5`))
			.then((response) => {
				setLoading(false)
				if (response?.status === 200 && Array.isArray(response.userLoginHistory)) {
					const [data] = response.userLoginHistory
					setData(data)
				}
			})
			.catch((_) => {
				setLoading(false)
			})
	}

	useEffect(() => {
		if (client?.id) {
			getLoginHistory()
		}
	}, [client])

	return (
		<Fragment>
			{/* <div className='mb-4'>
                        <input className='form-control mb-3' placeholder='Search' />
                        <Field type='select' component={SelectField} placeholder='Shortcuts' />
                        <button className='btn btn-link'><i className='uil uil-plus' /> Assessment</button><br />
                        <button className='btn btn-link'><i className='uil uil-plus' /> Forms</button>
                    </div> */}

			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Login History</h5>
				</div>
				<div className='card-body p-0'>
					{loading ? (
						<div className='d-flex justify-content-center align-items-center'>
							<div className='spinner-border mr-2 !h-5 !w-5' /> &nbsp;Loading...
						</div>
					) : (
						<Fragment>
							{data.length > 0 ? (
								data.map((history, idx) => {
									return (
										<Fragment key={idx}>
											<div className='flex rounded border-b px-[1.25rem] py-3 last:border-none'>
												{/* <p className='text-gray mb-0'>
															{client?.first_name} signed in{' '}
															{moment(history.login_at).format(
																'hh:mm A',
															)}
															,{' '}
															{moment(history.login_at).format(
																'MMM D YYYY',
															)}{' '}
															viewed the{' '}
															{getModuleName(history?.body)} page
														</p> */}
												<strong className='w-[50px]'>{idx + 1}.</strong>
												<span>
													{moment(history.login_at).format(
														'MMMM DD, yy, hh:mm A',
													)}
												</span>
											</div>
										</Fragment>
									)
								})
							) : (
								<Fragment>
									<p className='text-center'>No login history found</p>
								</Fragment>
							)}
						</Fragment>
					)}
				</div>
			</div>

			{/* <div className='mb-4'>
						<h4 className='mb-3'>Upcoming (Calendar)</h4>
						<div className='border-dark rounded border p-2'>
							<p className='text-gray mb-0'>
								Annual Review Meeting - 3pm, May 29th, 2020 - Video Call
							</p>
						</div>
					</div> */}
			{/* <UpcomingCalendar /> */}

			{/* <div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>History</h5>
				</div>
				<div className='card-body p-0'>
					{new Array(10).fill(0).map((_, idx) => {
						return (
							<Fragment key={idx}>
								<div className='border-b px-[1.25rem] py-3 last:border-none'>
									<p className='text-gray mb-0'>
										EFT in - $55,000 - Jan 19, 2020
									</p>
								</div>
							</Fragment>
						)
					})}
				</div>
			</div> */}
		</Fragment>
	)
}

const SecontWidgetForm = reduxForm({
	form: 'SecontWidgetForm',
})(SecontWidget)

export default SecontWidgetForm
