import { TeamMemberListingContainer } from 'modules/team-members'
import Header from 'modules/team-members/header'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from 'utils/checkPermission'

const TeamMembersListing = () => {
	const isListPermission = hasPermission('user:list')

	return (
		<AppLayout
			meta={{
				title: 'Team Members',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<Header />
						</div>
						<div className='page-body'>
							<TeamMemberListingContainer />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default TeamMembersListing
