import { SelectDropdown } from 'components/Forms2'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field } from 'redux-form'
import WorkflowOptionsQuery from './WorkflowOptionsQuery'

const WorkflowDropdown = ({ validate }) => {
	const [search, setSearch] = useState('')
	const values = useSelector((state) => state.form.task_form?.values)
	const clients = useMemo(
		() =>
			Array.isArray(values?.client_ids)
				? values?.client_ids.map((client) => client?.value)
				: [],
		[values?.client_ids],
	)

	const { workflowOptions, isLoading } = WorkflowOptionsQuery(search, clients)

	return (
		<Field
			label='Workflow'
			name='workflow_id'
			type='select'
			component={SelectDropdown}
			options={workflowOptions}
			isLoading={clients.length === 0 ? false : isLoading}
			variant='horizontal'
			onInputChange={(value) => setSearch(value)}
			width={'w-56'}
			validate={validate}
			placeholder='Search workflow'
		/>
	)
}

export default WorkflowDropdown
