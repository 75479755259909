/* eslint-disable react-hooks/exhaustive-deps */
import { createColumnHelper } from '@tanstack/react-table'
import { DataTable } from 'components/Common'
import { Fragment, useState } from 'react'
import { reduxForm } from 'redux-form'
import CreateNewNote from './CreateNewNote'
import FilterForm from './FilterForm'
import useNoteViewQuery from './useNoteViewQuery'

const Notes = (props) => {
	const { client, role, handleSubmit, reset } = props
	const [modal_standard, setmodal_standard] = useState(false)

	function removeBodyCss() {
		document.body.classList.add('no_padding')
	}
	const togStandard = () => {
		setmodal_standard(!modal_standard)
		removeBodyCss()
	}
	const columnHelper = createColumnHelper()

	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { data, meta, isLoading, mutate } = useNoteViewQuery(pagination, client, role)
	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			size: 40,
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),

		columnHelper.accessor((row) => row.note, {
			id: 'note',
			size: 200,
			header: () => <span>Note</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
		columnHelper.accessor((row) => row.sharing, {
			id: 'sharing',
			size: 200,
			header: () => <span>Sharing</span>,
			cell: (info) => <span>{info.getValue()}</span>,
		}),
	]

	return (
		<Fragment>
			<div className='card !rounded-lg shadow-none'>
				<div className='card-header !border-none !bg-[#F2F5FF] p-4 py-3'>
					<h5 className='mb-0 font-bold'>Notes</h5>
					<div>
						<button
							className='btn btn-primary'
							onClick={() => {
								togStandard()
							}}
							size='sm'
							color='primary'>
							<i className='uil uil-plus' /> Create Note
						</button>
					</div>
				</div>
				<div className='card-body'>
					<FilterForm />
					<DataTable
						columns={columns}
						data={data}
						isLoading={isLoading}
						pagination={pagination}
						setPagination={setPagination}
						meta={meta}
					/>
				</div>
			</div>
			<CreateNewNote
				mutate={mutate}
				client={client}
				reset={reset}
				open={modal_standard}
				onClose={() => setmodal_standard(false)}
				onOpen={togStandard}
				handleSubmit={handleSubmit}
			/>
		</Fragment>
	)
}

const NotesForm = reduxForm({
	form: 'notes_form',
	enableReinitialize: true,
})(Notes)

export default NotesForm
