import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import apiEndpoints from "../../../helpers/apiEndpoints"
import * as authActions from '../../../store/jwtauth/actions'
import { showErrorToast, showSuccessToast, useQuery } from '../../../utils/commonFunctions'
import ResetPasswordForm from "./components/ResetPasswordForm"

const ResetPassword = props => {
	const { loading } = useSelector(state => state.jwtauth)
	const dispatch = useDispatch()
	const { token } = useQuery(props.location.search)

	useEffect(() => {
		if (!token) {
			props.history.push('/login');
		}
		document.body.className = "authentication-bg"
		return function cleanup() {
			document.body.className = "";
		};
	});

	return (
		<Fragment>
			<ResetPasswordForm
				loading={loading}
				onSubmit={value => {
					if (value.password !== value.confirm_password) {
						showErrorToast('Confirm password does not match')
					} else {
						dispatch(authActions.resetPassword(apiEndpoints.auth.resetPassword, { token, password: value.password }))
							.then(response => {
								if (response && response.status === 200) {
									showSuccessToast(response.message)
									props.history.push('/login')
								}
							})
					}
				}}
			/>
		</Fragment>
	)
}

export default ResetPassword