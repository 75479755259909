import { routes } from 'modules/workflows'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { toSnakeCase } from 'utils/commonFunctions'

const Actions = ({ row }) => {
	return (
		<Fragment>
			{toSnakeCase(row?.status) === 'completed' ? (
				<div className='flex space-x-3'>
					<Link to={routes.view(row?.id)} className='action-btn btn-gray-outline'>
						<i className='uil-edit' style={{ fontSize: 16 }} />
						<span>Edit</span>
					</Link>
				</div>
			) : (
				<p>-</p>
			)}
		</Fragment>
	)
}

export default Actions
