import classNames from 'classnames'
import { FormikProvider, useFormik } from 'formik'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { routes } from 'modules/workflows'
import { Button } from 'theme/ui/forms'
import {
	assignValidationSchema,
	assignWorkflow,
	getWorkflowAssignPayload,
	validateWorkflow,
} from '.'
import WorkflowQuery from '../workflow-query'
import { default as Steps } from './steps/steps'

const WorkflowAssignForm = ({ selectedIndex, setSelectedIndex, initialValues }) => {
	const history = useHistory()

	const lastStep = 1
	const isLastStep = selectedIndex === lastStep
	const onPrevious = () => setSelectedIndex(selectedIndex - 1)

	const formik = useFormik({
		initialValues: {
			workflow: initialValues?.workflow || null,
			advisor: null,
			client: { user: null, screen_type: 'same_page' },
			co_applicants: [],
			reviewers: [],
			final_reviewer: null,
			userCount: 1,
		},
		enableReinitialize: true,
		validationSchema: assignValidationSchema,
		onSubmit: async (values, { setTouched, setSubmitting }) => {
			try {
				if (isLastStep) {
					setSubmitting(true)
					const payload = getWorkflowAssignPayload(values)
					await validateWorkflow(payload?.workflowTemplate?.id)
					await assignWorkflow(payload)
					setSubmitting(false)
					history.push(routes.templatesListing())
				} else {
					setSelectedIndex(selectedIndex + 1)
					setTouched({})
					setSubmitting(false)
				}
			} catch (error) {
				setSubmitting(false)
			}
		},
	})

	const formValues = useMemo(() => formik?.values, [formik?.values])
	const { user_count, steps } = WorkflowQuery(formValues.workflow?.value)

	useEffect(() => {
		if (user_count > 1) {
			formik.setFieldValue('userCount', user_count)
			formik.setFieldValue(
				'co_applicants',
				Array(user_count - 1).fill({ user: null, screen_type: 'same_page' }),
			)
		} else {
			formik.setFieldValue('userCount', 1)
			formik.setFieldValue('co_applicants', [])
		}
	}, [user_count])

	const documents = useMemo(() => steps?.documents?.raw_documents || [], [steps])

	const document_packages = useMemo(() => steps?.documents?.document_packages || [], [steps])
	const upload_files = useMemo(() => {
		const files = steps?.uploads?.note || '[]'
		return JSON.parse(files)
	}, [steps])

	const advisor_reviewer = useMemo(() => {
		const reviewers = formValues?.reviewers.map((reviewer) => reviewer?.value)
		return [formValues?.advisor?.value, formValues?.final_reviewer?.value, ...reviewers].filter(
			(user) => typeof user !== 'undefined',
		)
	}, [formValues])

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div>
						<Steps
							formValues={formValues}
							formik={formik}
							selectedIndex={selectedIndex}
							documents={documents}
							document_packages={document_packages}
							upload_files={upload_files}
							advisor_reviewer={advisor_reviewer}
						/>
						<div
							className={classNames(
								'flex !py-5',
								selectedIndex > 0 ? 'justify-between' : 'justify-end',
							)}>
							{selectedIndex > 0 ? (
								<Button
									type='button'
									variant='primary-outline'
									onClick={onPrevious}>
									Previous
								</Button>
							) : undefined}

							<Button
								type='submit'
								isLoading={formik.isSubmitting}
								isLoadingText='Assigning...'>
								{selectedIndex === lastStep ? 'Assign Workflow' : 'Next'}
							</Button>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								When assigning documents/packages here, do not ignore to fill in all
								the required fields completely and follow the document/package
								adding rules.
							</p>
						</div>
					</div>
				</div>
			</form>
		</FormikProvider>
	)
}

export default WorkflowAssignForm
