import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Switch from 'react-switch'
import { Button } from 'reactstrap'

import apiEndpoints from 'helpers/apiEndpoints'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import { useHistory, useParams } from 'react-router-dom'
import { find } from 'store/common/actions'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from 'utils/checkPermission'
import { KanbanView } from './components/kanban'
import ListView from './components/list-view'
import TaskForm, { prepareInitialValues } from './components/task-form'

const TasksList = () => {
	const params = useParams()
	const history = useHistory()
	const TASK_ID = Number(params?.id)

	const [isKanbanView, setIsKanbanView] = useState(false)
	const [task, setTask] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const onOpen = () => setIsOpen(true)
	const onClose = () => {
		setIsOpen(false)
		history.push('/tasks')
	}

	function toggleCanvas() {
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'auto'
		}

		return () => {
			document.body.style.overflow = 'auto'
		}
	}, [isOpen])

	const dispatch = useDispatch()

	const initialValues = useMemo(() => {
		if (TASK_ID) {
			return task
		} else {
			return {
				progress_status: { label: 'To Do', value: 'To Do' },
			}
		}
	}, [TASK_ID, task])

	useEffect(() => {
		if (TASK_ID) {
			onOpen()
			setIsLoading(true)
			setTask(null)
			dispatch(find(apiEndpoints.task, TASK_ID)).then((response) => {
				setIsLoading(false)
				if (response?.status === 200) {
					const task = prepareInitialValues(response?.data)
					setTask(task)
				}
			})
		}
	}, [TASK_ID])

	return (
		<AppLayout
			meta={{
				title: 'Tasks',
			}}>
			{!hasPermission('task:list') ? (
				<UnAuthorize />
			) : (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<div className='page-header bg-white'>
							<div>
								<h4 className='page-title'>Tasks List</h4>
								<p className='text-xs text-[#74788D]'>
									The most important feature in the workflow section is the
									creating one. When assigning documents/packages here,
									<br />
									do not ignore to fill in all the required fields completely and
									follow the document/package adding rules.
								</p>
								<div className='custom-switch flex items-center space-x-2'>
									<span>List View</span>
									<Switch
										uncheckedIcon={false}
										checkedIcon={false}
										onColor='#F1B44C'
										offColor='#626ed4'
										height={20}
										width={40}
										handleDiameter={12}
										onChange={() => setIsKanbanView(!isKanbanView)}
										checked={isKanbanView}
									/>
									<span>Kanban View</span>
								</div>
							</div>

							{hasPermission('task:create') ? (
								<Fragment>
									<Button color='primary' className='min-w-max' onClick={onOpen}>
										Create Task
									</Button>

									{!isLoading ? (
										<TaskForm
											initialValues={initialValues}
											id={TASK_ID}
											canvasState={[isOpen, setIsOpen]}
											toggleCanvas={toggleCanvas}
											onClose={onClose}
										/>
									) : undefined}
								</Fragment>
							) : undefined}
						</div>
						<div className='page-body'>
							{isKanbanView ? <KanbanView /> : <ListView />}
						</div>
					</div>
				</div>
			)}
		</AppLayout>
	)
}

export default TasksList
