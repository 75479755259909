import classNames from 'classnames'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import hasPermission from 'utils/checkPermission'
import { routes } from '..'

const Header = () => {
	const location = useLocation()
	const isCreatePermission = hasPermission('workflow:create')

	const tabs = useMemo(
		() => [
			{
				label: 'Workflows Templates',
				href: '/workflows',
				permission: hasPermission('workflow:list'),
			},
			{
				label: 'Workflows Outstandings',
				href: '/workflows-outstandings',
				permission: hasPermission('workflow:list'),
			},
			{
				label: 'Workflows Final Reviews',
				href: '/workflows-final-reviews',
				permission: hasPermission('workflow:list'),
			},
			{
				label: 'Assigned Workflows',
				href: '/assigned-workflows',
				permission: hasPermission('clientWorkflow:list'),
			},
		],
		[],
	)

	const activeTab = useMemo(
		() => tabs.find((tab) => tab?.href === location?.pathname),
		[location?.pathname],
	)

	return (
		<div className='flex w-full flex-col'>
			<div className='mb-3 flex w-full justify-between'>
				<div className='max-w-[733px]'>
					<h4 className='page-title'>{activeTab?.label}</h4>
					<p className='text-xs text-[#74788D]'>
						The most important feature in the workflow section is the creating one. When
						assigning documents/packages here,
						<br />
						do not ignore to fill in all the required fields completely and follow the
						document/package adding rules.
					</p>
				</div>

				{isCreatePermission && (
					<Link
						to={routes.create()}
						className='h-fit min-w-max rounded bg-main px-3 py-2 text-white'>
						Create new workflow
					</Link>
				)}
			</div>
			<div className='-mb-[12px] space-x-2'>
				{tabs
					.filter((item) => item?.permission)
					.map((tab, i) => (
						<Link
							to={tab.href}
							key={i}
							className={classNames(
								'border-b-2 px-[10px] py-[10px] text-sm font-bold text-gray-400',
								{
									'border-main text-main': tab?.href == location?.pathname,
								},
							)}>
							{tab.label}
						</Link>
					))}
			</div>
		</div>
	)
}

export default Header
