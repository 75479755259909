/* eslint-disable react-hooks/exhaustive-deps */
import { snakeCase } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'

import { Notes } from 'modules/dashboard'
import * as authActions from 'store/jwtauth/actions'
import AppLayout from 'theme/layouts/app-layout'

const Dashboard = () => {
	const dispatch = useDispatch()
	const { isSuperAdmin, defaultGroup } = useSelector((state) => state.Permissions)
	const currentGroup = useMemo(() => snakeCase(defaultGroup?.group?.name), [defaultGroup])
	const roles = ['owner', 'advisor']

	useEffect(() => {
		if (!isSuperAdmin && currentGroup === 'client') {
			// history.push('/assigned-workflows')
		}
	}, [currentGroup])

	useEffect(() => {
		dispatch(authActions.getPermissions())
	}, [])

	return (
		<AppLayout
			meta={{
				title: 'Dashboard',
			}}>
			<div className='page-content'>
				<Container fluid>
					<h4>Dashboard</h4>

					{!isSuperAdmin && roles.includes(currentGroup) ? <Notes /> : undefined}
				</Container>
			</div>
		</AppLayout>
	)
}
export default Dashboard
