import DeletedDocumentTemplateContainer from 'modules/documents/deleted-document-templates'
import DocumentTabs from 'modules/documents/document-tabs'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'
import hasPermission from '../../../utils/checkPermission'
import UnAuthorize from '../../StaticPages/UnAuthorize'

const DeletedDocumentTemplateListing = () => {
	const isListPermission = hasPermission('teamDocument:list')

	return (
		<AppLayout
			meta={{
				title: 'Deleted Document Templates',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<Header />
						<div className='page-body'>
							<DocumentTabs />
							<div className='mt-3'>
								<DeletedDocumentTemplateContainer />
							</div>
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default DeletedDocumentTemplateListing
