import { toArray } from 'lodash'
import { findValueByKey, flattenArray, toJson, toSnakeCase } from 'utils/commonFunctions'

export const getFileLabel = (files, user_type) => {
	try {
		const items = JSON.parse(files)
		return items.filter((item) => item?.user_type === user_type)
	} catch (_) {
		return []
	}
}

export const getCorrectionFileLabel = (files) => {
	if (Array.isArray(files)) {
		return files
			.filter((file) => file?.correction)
			.map((file) => ({
				id: file?.files?.id,
				title: file?.title,
			}))
	} else {
		return []
	}
}

export const getFileOptions = (files) => {
	let options = []
	if (Array.isArray(files)) {
		for (let i = 0; i < files.length; i++) {
			let item = files[i]
			if (item?.file) {
				options.push({ label: item?.file, value: toSnakeCase(item?.file) })
			}
		}
	}
	return options
}

export const getDocumentTitles = (array) => {
	if (Array.isArray(array)) {
		return array
			.map((document) => {
				return {
					id: document?.id,
					title: document?.clientDocument?.document ? document?.title : '',
				}
			})
			.filter((item) => item)
	} else {
		return []
	}
}

// remove duplicate objects by key from array
export const getUniqueListBy = (arr, key) => {
	return [...new Map(arr.map((item) => [item[key], item])).values()]
}

// combine all the fields of document into singal array
export const getFieldsArray = (array) => {
	if (Array.isArray(array)) {
		const documents = array.map((item) => {
			return {
				id: item?.id,
				title: item?.title,
				fields: flattenArray(toArray(item?.clientDocument?.document)),
			}
		})
		return documents.map((item) => ({
			...item,
			fields: item?.fields.map((field) => ({
				...field,
				documents: getDocumentsListByField(documents, field?.name),
			})),
		}))
	} else {
		return []
	}
}

const getDocumentsListByField = (array, field_name) => {
	if (Array.isArray(array)) {
		return array
			.filter((document) => {
				if (Array.isArray(document?.fields)) {
					return document?.fields.filter((field) => field?.name === field_name).length > 0
				} else {
					return false
				}
			})
			.map((item) => ({ id: item?.id, title: item?.title }))
	} else {
		return []
	}
}

// convert if fields list is an object into array
export const getFieldsList = (fields) => {
	const documents = getFieldsArray(fields)
	if (Array.isArray(documents)) {
		const fieldsArray = documents.map((item) => item?.fields)
		return flattenArray(fieldsArray)
	} else {
		return []
	}
}

// bifurcate pdf fields by user type
export const getFieldsByUser = (fieldsArray, user_type) => {
	const fields = flattenArray(fieldsArray).filter(
		(field) => field?.type !== 'header' && field?.user_type === user_type,
	)

	if (Array.isArray(fields)) {
		return getUniqueListBy(fields, 'name')
	} else {
		return []
	}
}

// fill client values into pdf fields
export function fillClientValues(fieldsArray, fieldValues, user_type, is_document_bound) {
	let filledValues = []
	const allFields = flattenArray(fieldsArray)

	for (let i = 0; i < allFields.length; i++) {
		let field = allFields[i]
		let value = findValueByKey(fieldValues, field.name)
		let Obj = { ...field }

		if (
			value &&
			field &&
			field?.user_type === user_type &&
			field?.is_document_bound === is_document_bound
		) {
			if (field?.type === 'select') {
				Object.assign(Obj, {
					value: value[field?.name] ? value[field?.name]?.value : null,
				})
			} else if (field?.type === 'checkbox') {
				Object.assign(Obj, {
					value: typeof value === 'undefined' ? false : value[field?.name],
				})
			} else {
				Object.assign(Obj, {
					value: value[field?.name] ? value[field?.name]?.trim() : null,
				})
			}
		}

		filledValues.push(Obj)
	}
	return filledValues
}

export const getDocumentsList = (array) => {
	if (Array.isArray(array)) {
		return array
			.map((item) => ({
				document_id: item?.id,
				document_body: item?.clientDocument?.document,
			}))
			.filter((item) => Array.isArray(item?.document_body))
	} else {
		return []
	}
}

export const getCorrectionNote = (review_notes, user_type) => {
	if (user_type && toJson(review_notes) && toJson(review_notes)[user_type]) {
		return toJson(review_notes)[user_type]
	} else {
		return null
	}
}

export const getField = (field) => {
	if (!field) {
		return Promise.reject(new Error('Field not found'))
	}
	const keyArray = Object.keys(field) || []
	const valueArray = Object.values(field) || []
	const key = keyArray[0]
	let value = valueArray[0]

	if (typeof value === 'string') {
		value = value.trim()
	}
	return Promise.resolve({ key: key, value: value })
}

// get document bound fields document wise
export const getDocumentBoundFields = (documents, user_type) => {
	try {
		if (Array.isArray(documents)) {
			return documents
				.map((document) => {
					const fields = document?.clientDocument?.document.filter(
						(field) => field.is_document_bound === true,
					)
					const fieldbyUser = getFieldsByUser(fields, user_type)

					return {
						id: document?.id,
						title: document?.title,
						fields: Array.isArray(fieldbyUser) ? fieldbyUser : [],
					}
				})
				.filter((d) => d?.fields.length > 0)
		} else {
			return []
		}
	} catch (error) {
		return []
	}
}
