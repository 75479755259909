import React from 'react';

const Pagination = ({ total, limit, currentPage, onPageChange }) => {

    let pageNumber = [];
    for (let i = 1; i <= Math.ceil(total / limit); i++) {
        pageNumber.push(i)
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                    <button className="page-link"
                        onClick={(e) => onPageChange(e, currentPage - 1)}
                        disabled={currentPage === 1}
                    >Previous</button>
                </li>
                {pageNumber.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number && 'active'}`}>
                        {currentPage === number ? (
                            <button className="page-link">{number}</button>

                        ) : (
                            <button className="page-link" onClick={(e) => onPageChange(e, number)}>{number}</button>
                        )}
                    </li>
                ))}
                <li className={`page-item ${pageNumber.length === currentPage && 'disabled'}`}>
                    <button className="page-link"
                        onClick={(e) => onPageChange(e, currentPage + 1)}
                        disabled={pageNumber.length === currentPage}
                    >Next</button>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination