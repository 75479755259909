import { ButtonWithLoader } from 'components/Common'
import { TextField } from 'components/Forms/TextFieldComponent'
import { useCreateNoteMutation } from 'modules/clients/client-apis'
import { useSelector } from 'react-redux'
import { Modal } from 'reactstrap'
import { Field } from 'redux-form'
import { showSuccessToast } from 'utils/commonFunctions'
import { required } from 'utils/formValidation'

const validate = {
	notes: required('Notes'),
}
const CreateNewNote = ({ mutate, client, reset, open, onClose, onOpen, handleSubmit }) => {
	const [createNoteFun, { isLoading }] = useCreateNoteMutation()

	const { defaultGroup } = useSelector((state) => state.Permissions)

	const team = defaultGroup?.team

	const onSubmit = async (values) => {
		const payload = {
			...values,
			visibility: 0,
			teams: [{ id: team?.id }],
			users: [{ id: client?.id }],
		}

		const response = await createNoteFun(payload)
		if (response?.data?.status === 200) {
			reset()
			onOpen()
			showSuccessToast(response?.data?.message)
			await mutate()
		}
	}

	return (
		<Modal isOpen={open} toggle={() => onOpen()}>
			<div className='modal-header'>
				<h5 className='modal-title mt-0' id='myModalLabel'>
					Create Note
				</h5>
				<button
					type='button'
					onClick={onClose}
					className='close'
					data-dismiss='modal'
					aria-label='Close'>
					<span aria-hidden='true'>&times;</span>
				</button>
			</div>
			<form onSubmit={handleSubmit((values) => onSubmit(values))}>
				<div className='modal-body'>
					<Field
						label='Notes'
						type='textarea'
						name='notes'
						component={TextField}
						validate={validate.notes}
					/>
				</div>
				<div className='modal-footer'>
					<div className='d-flex align-items-center justify-content-center w-100'>
						<button
							type='button'
							onClick={() => onOpen()}
							className='btn btn-secondary waves-effect'
							data-dismiss='modal'>
							Close
						</button>
						&nbsp;
						<ButtonWithLoader loading={isLoading} type='submit'>
							Save
						</ButtonWithLoader>
					</div>
				</div>
			</form>
		</Modal>
	)
}

export default CreateNewNote
