import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate, formatToTwoDigits } from 'utilities/helpers'

const API_URL = apiEndpoints.workflow_template

export const workflowTemplateApi = createApi({
	reducerPath: 'WorkflowTemplate',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['WorkflowTemplate'],
	endpoints: (builder) => ({
		getWorkflowTemplates: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: API_URL,
				method: 'GET',
				params: params,
			}),
			providesTags: ['WorkflowTemplate'],
			transformResponse: (response, _, arg) => {
				const workflows = Array.isArray(response?.data?.workflows)
					? response?.data?.workflows.map((workflow, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: workflow?.id,
							title: workflow?.title,
							users: formatToTwoDigits(workflow?.workflowCount),
							created_by: `${workflow?.created_by?.first_name} ${workflow?.created_by?.last_name}`,
							last_updated: formatDate(workflow?.updated_at),
					  }))
					: []

				return {
					workflows,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneWorkflowTemplate: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['WorkflowTemplate'],
			transformResponse: (response) => {
				return {
					workflow: response?.data,
				}
			},
		}),
		createWorkflowTemplate: builder.mutation({
			query: (payload) => ({
				url: API_URL,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['WorkflowTemplate'],
		}),
		updateWorkflowTemplate: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['WorkflowTemplate'],
		}),
		deleteWorkflowTemplate: builder.mutation({
			query: (id) => ({
				url: `${API_URL}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['WorkflowTemplate'],
		}),
	}),
})

export const {
	useLazyGetWorkflowTemplatesQuery,
	useLazyGetOneWorkflowTemplateQuery,
	useCreateWorkflowTemplateMutation,
	useUpdateWorkflowTemplateMutation,
	useDeleteWorkflowTemplateMutation,
} = workflowTemplateApi
