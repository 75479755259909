import { Link } from 'react-router-dom'
import { ASSIGN_WORKFLOW_APP_ROUTES } from './routes'

const Actions = ({ id, last_updated, isActionActive }) => {
	return (
		<div className='w-full'>
			{isActionActive ? (
				<div className='flex w-full space-x-3'>
					<Link
						to={ASSIGN_WORKFLOW_APP_ROUTES.findOne(id)}
						className='action-btn btn-gray'>
						<span>View</span>
					</Link>
				</div>
			) : (
				<span className=' text-sm  !text-gray-700'>{last_updated}</span>
			)}
		</div>
	)
}

export default Actions
