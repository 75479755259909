import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import { Loader } from 'components/Common'
import ProtectedRoutes from './ProtectedRoutes'

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => {
	const { loggedIn } = useSelector((state) => state.jwtauth)

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthProtected && !loggedIn) {
					if (rest?.path != '/') {
						window.location.href = `/login?next=${rest.location.pathname}`
					} else {
						window.location.href = `/login`
					}

					return <Loader />
				}

				if (!isAuthProtected && loggedIn && rest?.path !== '/logout') {
					return <ProtectedRoutes />
				}

				return <Component {...props} />
			}}
		/>
	)
}

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
}

export default Authmiddleware
