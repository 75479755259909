import { useState } from 'react'
import { Link } from 'react-router-dom'
import Actions from './Actions'
import SearchInput from './search-input'

const PackageTable = ({ documents, packageDocument }) => {
	const [filteredData, setFilteredData] = useState(documents)

	return (
		<div>
			<SearchInput setFilteredData={setFilteredData} data={documents} />
			<table className='document-package'>
				<tbody>
					{filteredData?.length > 0 ? (
						filteredData?.map((document, idx) => {
							return (
								<tr key={idx}>
									<td className='w-[5%]'>
										<span>{idx + 1}.</span>
									</td>
									<td className='w-[30%]'>
										<span
											className='line-clamp-1 max-w-fit font-ibmplex font-bold'
											title={`${document?.title}`}>{`${document?.title}`}</span>
									</td>
									<td className='w-[25%]' align='left'>
										{document?.file?.original_name ? (
											<Link
												to={`documents/${document?.id}/edit`}
												className='hover:!underline'>
												<span>{document?.file?.original_name}</span>
											</Link>
										) : (
											'-'
										)}
									</td>

									<td className='w-[20%]'>
										<Actions
											field={document}
											documents={filteredData}
											packageDocument={packageDocument}
										/>
									</td>
								</tr>
							)
						})
					) : (
						<tr>
							<td colSpan={5} className='text-center'>
								No records found
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	)
}

export default PackageTable
