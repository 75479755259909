import { useDraggable } from '@dnd-kit/core'
import moment from 'moment'
import React, { useMemo } from 'react'

import { snakeCase } from 'lodash'
import { convertToTranslate } from '.'
import Clients from './Clients'
import Tags from './Tags'

const Task = ({ task, index }) => {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: task?.id,
		data: {
			index,
			task,
			parent: task?.progress_status,
			type: snakeCase(task?.progress_status),
		},
	})
	const transForm = useMemo(() => convertToTranslate(transform), [transform])
	const assignee = `${task?.assignee?.first_name} ${task?.assignee?.last_name}`

	return (
		<React.Fragment>
			<div
				className='space-y-2 rounded bg-white p-[10px]'
				style={{ transform: transForm }}
				{...listeners}
				{...attributes}
				ref={setNodeRef}>
				<Tags tags={task?.tags} />

				<h3 className='text-base font-semibold'>{task?.task_title}</h3>

				<div className='flex space-x-1'>
					<div className='flex items-center justify-center rounded bg-indigo-100 px-2 py-1 text-xs'>
						<span className='font-semibold uppercase text-indigo-600'>{assignee}</span>
					</div>
				</div>

				<div className='flex items-center space-x-2 rounded py-2 text-xs'>
					<span>{task?.team?.team_name}</span>
				</div>

				<span className='tag tag-info line-clamp-1'>
					<i className='uil-list-ul mr-1' />
					{task?.workflow?.title}
				</span>

				<Clients clients={task?.clients} />

				<div className='mb-2 w-full border' />

				<div className='flex justify-between'>
					<span className='text-success rounded bg-green-100 p-1 text-xs'>
						{moment(task?.due_date).format('D, MMM YYYY')}
					</span>

					{task?.attachments.length > 0 ? (
						<span className='rounded bg-gray-100 px-1'>
							<i className='uil-paperclip text-base' />
							&nbsp;{task?.attachments.length}
						</span>
					) : undefined}
				</div>
			</div>
		</React.Fragment>
	)
}

export default Task
