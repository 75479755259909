import { useMemo } from 'react'

const Tags = ({ tags }) => {
	const _tags = useMemo(() => (Array.isArray(tags) ? tags : []), [tags])

	return (
		<div className='flex-wrap space-x-1'>
			{_tags.map((tag, idx) => (
				<span className='tag tag-primary' key={idx}>
					{tag?.tag}
				</span>
			))}
		</div>
	)
}

export default Tags
