import classNames from 'classnames'
import React from 'react'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

export const CheckBox = ({
	label,
	options,
	input,
	meta,
	multiple,
	id,
	className,
	ignoreBottomMargin = false,
}) => {
	const { touched, error } = meta
	return (
		<React.Fragment>
			{multiple ? (
				options && options.length > 0 ? (
					<div
						className={classNames(ignoreBottomMargin ? '!my-2' : 'mb-3', 'form-check')}>
						<Label>{label}</Label>
						{options.map((option, index) => (
							<React.Fragment key={index}>
								<FormGroup className='form-check'>
									<input
										type='checkbox'
										className={`form-check-input ${className}`}
										id={`${option.value}_${index}`}
										name={`${input.name}[${index}]`}
										value={option.value}
										checked={input.value.indexOf(option.value) !== -1}
										onChange={(event) => {
											const newValue = [...input.value]
											if (event.target.checked) {
												newValue.push(option.value)
											} else {
												newValue.splice(newValue.indexOf(option.value), 1)
											}
											return input.onChange(newValue)
										}}
									/>
									<Label
										className='form-check-label'
										htmlFor={`${option.value}_${index}`}>
										{option.label}
									</Label>
								</FormGroup>
							</React.Fragment>
						))}
						{touched && error && (
							<FormFeedback style={{ display: 'block' }}>
								{touched && error}
							</FormFeedback>
						)}
					</div>
				) : (
					''
				)
			) : (
				<div className={classNames(ignoreBottomMargin ? '!my-1.5' : 'mb-3', 'form-check')}>
					<input
						{...input}
						type='checkbox'
						className={`form-check-input ${className}`}
						id={id}
						value={input.value}
						onChange={(value) => input.onChange(value)}
					/>
					<Label className='form-check-label' htmlFor={id}>
						{label}
					</Label>
					{touched && error && (
						<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
					)}
				</div>
			)}
		</React.Fragment>
	)
}
