/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { SoftLoader, SpinLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import { useGetWorkflowDocumentsQuery } from 'modules/workflows/assigned-workflows/assigned-workflow-apis'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getAllTabs, removeTab, saveDocument } from '.'
import { useDocmentViewer } from '../document-viewer-provider'
import { DocumentList } from '../documents-tab'

const Tab5 = ({ activeTab, mutateWorkflow, workflow, history }) => {
	const workflow_id = workflow?.id
	const [isSaving, setIsSaving] = useState(false)
	const documentInstance = useDocmentViewer()

	const URL = useMemo(
		() => `${apiEndpoints.client.workflowDetails}/${workflow_id}/documents`,
		[workflow_id],
	)
	const { data, refetch, isLoading } = useGetWorkflowDocumentsQuery(URL)
	const documents = useMemo(
		() => (Array.isArray(data?.documents) ? data?.documents : []),
		[data?.documents],
	)

	const onSavePdf = async () => {
		try {
			setIsSaving(true)
			const { documentViewer, annotationManager } = documentInstance.Core
			const fieldManager = annotationManager.getFieldManager()

			if (fieldManager.areRequiredFieldsFilled()) {
				fieldManager.forEachField((field) => {
					if (field.name === 'signature') {
						field.children.forEach(async (_field) => {
							_field.widgets.map((annot) => annot.fieldFlags.set('Required', false))
							_field.widgets.map((annot) => annot.fieldFlags.set('ReadOnly', false))
						})
					}
				})
				const activeTab = documentInstance.UI.TabManager.getActiveTab()?.id
				const document_step_id =
					documentInstance.UI.TabManager.getActiveTab()?.options?.document_id

				const doc = documentViewer.getDocument()
				const xfdfString = await annotationManager.exportAnnotations()
				const data = await doc.getFileData({ xfdfString })
				const arr = new Uint8Array(data)
				const blob = new Blob([arr], { type: 'application/pdf' })

				await saveDocument(blob, document_step_id, workflow_id)
				await removeTab(documentInstance, activeTab)
				await getAllTabs(documentInstance)
				mutateWorkflow()
				refetch()
			} else {
				notification('warn', 'Please insert your signatures')
			}

			setIsSaving(false)
		} catch (error) {
			setIsSaving(false)
			notification('warning', 'Something went wrong while saving the document')
		}
	}

	return (
		<Fragment>
			{isSaving && <SoftLoader />}
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					{documents.length > 0 ? (
						<Fragment>
							<h5>Ready for Signatures</h5>
							<p className='text-muted'>
								You and the parties that need to sign documents have been notified.
								If there are third parties on your documents, they will be provided
								a secure link to come sign these documents themselves
							</p>
							<h6>Click the Signature boxes to insert your Signature</h6>

							{activeTab === 5 && documents.length > 0 && (
								<DocumentList
									documentList={documents}
									workflow_id={workflow_id}
									workflow={workflow}
									history={history}
									mutate={refetch}
								/>
							)}

							<div className='my-4 w-full border-t border-dashed' />

							<div className='actions clearfix'>
								<div className='m-0 flex justify-between'>
									<Button disabled={true}>Previous</Button>
									<Button
										disabled={!documentInstance}
										isLoading={isSaving}
										isLoadingText={'Saving...'}
										type='button'
										onClick={onSavePdf}>
										{documents.length === 1 ? 'Submit' : 'Next'}
									</Button>
								</div>
							</div>
						</Fragment>
					) : (
						<div className='prose mx-auto my-10 flex flex-col text-center'>
							<h3>No documents have been found.</h3>
							<p className='text-gray-500'>
								There are no documents currently in the workflow, or perhaps you
								have already signed all of them.
							</p>

							<Link
								to='/assigned-workflows'
								className='btn btn-primary btn-sm mx-auto max-w-fit text-sm'>
								Go back to the Workflows
							</Link>
						</div>
					)}
				</Fragment>
			)}
		</Fragment>
	)
}

export default Tab5
