import { useMemo } from 'react'

import apiEndpoints from 'helpers/apiEndpoints'
import { useGetQueryDataQuery } from 'modules/clients/client-apis'

const useRelationViewQuery = (client) => {
	console.log('client', client)
	const URL = `${apiEndpoints.clientSetting}/user/${client?.id}`

	const { data, isLoading } = useGetQueryDataQuery(URL)

	const relationData = data?.data?.userSettings

	const relations = useMemo(
		() =>
			Array.isArray(relationData)
				? relationData.map((item) => ({
						id: item?.id,
						key: item?.user_key,
						value: item?.user_value,
				  }))
				: [],
		[relationData],
	)

	return {
		data: relations || [],
		meta: data?.data?.meta,
		isLoading,
	}
}

export default useRelationViewQuery
