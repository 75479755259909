import classNames from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, CardBody } from 'reactstrap'
import K from 'utils/constants'

const StatusInformation = ({ status, isAllDocumentsSigned }) => {
	const statusDescriptions = useMemo(
		() => [
			{
				status: K.STATUS.COMPLETED,
				title: 'Workflow Completed',
				description:
					'Your workflow has been successfully completed. You can now return to the Workflows.',
				icon: 'uil-check-circle text-green-600',
			},
			{
				status: K.STATUS.FINAL_REVIEW_APPROVED,
				title: 'Workflow Approved by Final Reviewer',
				description:
					'Kindly hold off until the workflow of the other related user receives approval.',
				icon: 'uil-clock-three text-yellow-500',
			},
			{
				status: K.STATUS.GATHERING_SIGNATURE,
				title: isAllDocumentsSigned ? 'Signed' : 'Please wait...',
				isAllDocumentsSigned: isAllDocumentsSigned,
				description:
					'Kindly hold off until the remaining users have finished signing the documents.',
				icon: isAllDocumentsSigned
					? 'uil-check-circle text-green-600'
					: 'uil-clock-three text-yellow-500',
			},
			{
				status: K.STATUS.APPROVED,
				title: 'Workflow Approved',
				description:
					'Kindly hold off until the workflow of the other related user receives approval.',
				icon: 'uil-clock-three text-yellow-500',
			},
		],
		[],
	)

	const currentStatus = useMemo(
		() => statusDescriptions.find((item) => item?.status === status),
		[status, statusDescriptions],
	)

	return (
		<Card className='shadow-none'>
			<CardBody>
				<div className='prose mx-auto my-10 flex flex-col text-center'>
					{currentStatus?.icon ? (
						<i className={classNames('text-8xl', currentStatus?.icon)} />
					) : undefined}

					{currentStatus?.title ? <h3>{currentStatus?.title}</h3> : undefined}

					{currentStatus?.description ? (
						<p className='text-gray-500'>{currentStatus?.description}</p>
					) : undefined}

					<Link
						to='/assigned-workflows'
						className='btn btn-primary btn-sm mx-auto max-w-fit text-sm'>
						Go back to the Workflows
					</Link>
				</div>
			</CardBody>
		</Card>
	)
}

export default StatusInformation
