import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

const API_URL = '/document/document-version-history'

export const documentVersionApi = createApi({
	reducerPath: 'DocumentVersions',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['DocumentVersion'],
	endpoints: (builder) => ({
		getDocumentVersions: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, id }) => ({
				url: `${API_URL}/${id}`,
				method: 'GET',
				params: params,
			}),
			providesTags: ['DocumentVersion'],
			transformResponse: (response, _, arg) => {
				console.log('response?.data?.documents', response?.data?.documents)
				const versions = Array.isArray(response?.data?.documents)
					? response?.data?.documents.map((document, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: document?.id,
							version: document?.version,
							is_current: idx === 0,
							created_at: formatDate(document?.created_at),
							created_by: document?.createdBy,
					  }))
					: []
				return {
					versions,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const { useLazyGetDocumentVersionsQuery } = documentVersionApi
