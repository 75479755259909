import { NotesListingContainer } from 'modules/notes'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from 'reactstrap'
import AppLayout from 'theme/layouts/app-layout'
import { useDebounce } from 'use-debounce'
import { ButtonLink, SpinLoader } from '../../components/Common'
import apiEndpoints from '../../helpers/apiEndpoints'
import * as actions from '../../store/common/actions'
import hasPermission from '../../utils/checkPermission'
import { toQueryString, useQuery } from '../../utils/commonFunctions'
import UnAuthorizePage from '../StaticPages/UnAuthorize'

const NotesListing = (props) => {
	const {
		history,
		location: { pathname, search: currentSearch },
	} = props
	const query = useQuery(currentSearch)
	const [search, setSearch] = useState(query?.search || '')
	const [debounceSearch] = useDebounce(search, 600)
	const dispatch = useDispatch()
	const [notes, setNotes] = useState([])
	const [loader, setLoader] = useState(false)
	const [total, setTotal] = useState(0)
	const [limit, setLimit] = useState(12)
	const [page, setPage] = useState(1)
	const [isListPermission] = useState(hasPermission('note:list'))
	const [isCreatePermission] = useState(hasPermission('note:create'))

	const getNotes = (pageNumber) => {
		setLoader(true)
		const queryObj = { ...query, page: pageNumber, limit: limit }
		dispatch(actions.all(`${apiEndpoints.notes}?${toQueryString(queryObj)}`))
			.then((response) => {
				setLoader(false)
				if (response && response.status === 200) {
					const [data, total] = response.data

					if (typeof Number(total) === 'number') {
						setTotal(Number(total))
					}

					if (Array.isArray(data) && data.length > 0) {
						setNotes(data)
					}
				}
			})
			.catch(() => {
				setLoader(false)
			})
	}

	useEffect(() => {
		if (isListPermission && query) {
			getNotes(page)
		}
	}, [query?.search])

	const onPageChange = (e, page) => {
		setPage(page)
		getNotes(page)
	}

	useEffect(() => {
		const queryString = { ...query }
		if (debounceSearch !== '') {
			Object.assign(queryString, { search: debounceSearch })
		} else {
			delete queryString.search
		}
		history.push({ pathname, search: `?${toQueryString(queryString)}` })
	}, [debounceSearch])

	return (
		<AppLayout
			meta={{
				title: 'Notes',
			}}>
			{!loader && !isListPermission && <UnAuthorizePage />}
			{isListPermission && (
				<div className='page-content'>
					<Container fluid>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<h4>Notes</h4>
							<div className='row'>
								<div className='col-sm-7'>
									<input
										className={`form-control ${
											notes.length === 0 ? 'invisible' : ''
										} `}
										placeholder='Search'
										onChange={(e) => setSearch(e.target.value)}
										value={search}
									/>
								</div>
								<div className='col-sm-5 text-right'>
									{isCreatePermission && (
										<ButtonLink to='/notes/create' color='primary'>
											Create Note
										</ButtonLink>
									)}
								</div>
							</div>
						</div>
						{loader ? (
							<SpinLoader />
						) : (
							<NotesListingContainer
								limit={limit}
								notes={notes}
								onPageChange={onPageChange}
								page={page}
								total={total}
							/>
						)}
					</Container>
				</div>
			)}
		</AppLayout>
	)
}

export default NotesListing
