import { useState } from 'react'
import { Field } from 'redux-form'

import { SelectDropdown } from 'components/Forms2'
import WatcherOptionsQuery from './WatcherOptionsQuery'

const WatcherDropdown = ({ validate }) => {
	const [search, setSearch] = useState('')
	const { watcherOptions, isLoading } = WatcherOptionsQuery(search)

	return (
		<Field
			label='Watcher'
			name='watcher_ids'
			type='select'
			component={SelectDropdown}
			variant='horizontal'
			width={'w-56'}
			isMulti={true}
			options={watcherOptions}
			isLoading={isLoading}
			onInputChange={(value) => setSearch(value)}
			validate={validate}
			placeholder='Select watcher...'
		/>
	)
}

export default WatcherDropdown
