import { useUpdateDocumentPackageMutation } from 'modules/documents/document-package-api'
import { Fragment, useState } from 'react'
import notification from 'utilities/notification'
import DeleteConfirmAlert from '../delete-confirm-alert'

const Actions = ({ field, documents, packageDocument }) => {
	const [isOpen, setIsOpen] = useState({
		open: false,
		id: undefined,
	})

	const onOpen = (id) => {
		setIsOpen({
			open: true,
			id,
		})
	}

	const onClose = () =>
		setIsOpen({
			open: false,
			id: undefined,
		})

	const [updateDocumentPackage] = useUpdateDocumentPackageMutation()

	const onDelete = async () => {
		const id = isOpen?.id
		const filteredDocuments = documents?.filter((d) => d.id !== id)
		const payload = {
			title: packageDocument?.title,
			documents: filteredDocuments?.map((item) => ({ id: item?.id })),
		}
		const response = await updateDocumentPackage({ payload, id: packageDocument?.id })
		if (response?.data?.status === 200) {
			notification('success', response?.data?.message)
			onClose()
		}
	}

	return (
		<Fragment>
			<DeleteConfirmAlert
				title='Are you sure want to delete document'
				isOpen={isOpen?.open}
				subTitle='Are you sure you want to delete this Document'
				onClose={onClose}
				onConfirm={onDelete}
			/>
			<div className='flex space-x-2'>
				<button
					type='button'
					className='text-danger flex size-8 items-center justify-center rounded border-none bg-gray-100 p-1.5 px-2 hover:bg-gray-200'
					onClick={() => onOpen(field?.id)}>
					<i className='uil uil-trash' style={{ fontSize: 16 }} />
				</button>
			</div>
		</Fragment>
	)
}

export default Actions
