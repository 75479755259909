import { formatDate } from 'utilities/helpers'
import CommentsContainer from './comments-section'

const WorkflowInfo = ({ workflow, stepId }) => {
	const user = workflow?.advisor

	return (
		<div className='mb-10 flex items-center justify-between'>
			<div className='flex items-center space-x-10'>
				<div className='flex flex-col'>
					<span className='text-sm font-bold'>Workflow Name</span>
					<span className='text-sm font-normal text-[#74788D]'>
						{workflow?.workflow_template?.title || '-'}
					</span>
				</div>

				<div className='flex flex-col'>
					<span className='text-sm font-bold'>Advisor/Assigner</span>
					<span className='text-sm font-normal text-[#74788D]'>
						{user ? (
							<>
								{user?.first_name} {user?.last_name}
							</>
						) : (
							'-'
						)}
					</span>
				</div>

				<div className='flex flex-col'>
					<span className='text-sm font-bold'>Current Status</span>
					<span className='text-sm font-normal text-[#74788D]'>
						{workflow?.status || '-'}
					</span>
				</div>

				<div className='flex flex-col'>
					<span className='text-sm font-bold'>Assigned On</span>
					<span className='text-sm font-normal text-[#74788D]'>
						{workflow?.created_at ? formatDate(workflow?.created_at) : '-'}
					</span>
				</div>
			</div>

			<CommentsContainer stepId={stepId} workflowId={workflow?.id} />
		</div>
	)
}

export default WorkflowInfo
