import PropTypes from 'prop-types'
import { Fragment } from 'react'
import CreatableSelect from 'react-select/creatable'
import { FormFeedback, FormGroup, Label } from 'reactstrap'

export const CreatableSelectField = ({
	meta: { touched, error },
	label,
	input,
	name,
	options,
	isMulti,
	isDisabled = false,
	isLoading,
	onCreateOption,
	...custom
}) => {
	const handleCreateOption = async (value) => {
		try {
			await onCreateOption(value)
			input.onChange({ label: value, value: value })
		} catch (error) {
			//
		}
	}

	return (
		<Fragment>
			<FormGroup>
				{label && <Label>{label}</Label>}
				<CreatableSelect
					{...input}
					{...custom}
					name={name}
					className='basic-single'
					classNamePrefix='select'
					options={options}
					onChange={(value) => input.onChange(value)}
					onCreateOption={handleCreateOption}
					onBlur={(value) => input.onBlur(value.value)}
					value={input.value}
					isSearchable
					isMulti={isMulti}
					isLoading={isLoading}
					isClearable
					isDisabled={isDisabled}
				/>
				{touched && error && (
					<FormFeedback style={{ display: 'block' }}>{touched && error}</FormFeedback>
				)}
			</FormGroup>
		</Fragment>
	)
}

CreatableSelectField.propTypes = {
	meta: PropTypes.object,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
}

CreatableSelectField.defaultProps = {
	meta: null,
	options: [],
	label: '',
	name: '',
	isMulti: false,
}
