/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { snakeCase } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { reduxForm, touch } from 'redux-form'

import { useSaveAssignedWorkflowMutation } from 'modules/workflows/assigned-workflows/assigned-workflow-apis'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getDocumentsPayload, getPayload } from '..'
import { getTabs } from '../tab-content'
import {
	getDocumentBoundFields,
	getDocumentsList,
	getField,
	getFieldsByUser,
	getFieldsList,
} from '../utils'
import DocumentBoundFieldsTab from './document-bound-fields-tab'
import ProfileFieldsTab from './profile-fields-tab/profile-fields-tab'

const Tab2 = (props) => {
	const {
		id,
		createdBy,
		isLoading,
		user_type,
		activeTab,
		toggleTab,
		uploads_id,
		handleSubmit,
		CompleteSteps,
		setCompleteSteps,
		individual_document,
		screen_type,
	} = props

	const [activeFieldTab, setActiveFieldTab] = useState(0)
	const [saveAssignedWorkflow, { isLoading: isWorkflowSaving }] =
		useSaveAssignedWorkflowMutation()

	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => defaultGroup?.group, [defaultGroup])
	const defaultGroupName = snakeCase(group?.name)
	const CLIENT_KEY = 'client'
	const URL = useMemo(
		() => (defaultGroupName === CLIENT_KEY ? `/client/workflow` : `/advisor/workflow`),
		[defaultGroupName],
	)
	const { syncErrors } = useSelector((state) => state.form.workflow_step_2)
	const [disableNext, setDisableNext] = useState(false)

	useEffect(() => {
		const checkErrors = async () => {
			if (Array.isArray(syncErrors?.fields) && syncErrors.fields.length > 0) {
				const allFields = syncErrors.fields
					.filter((item) => item !== undefined)
					.map((item) => Object.keys(item)[0])

				const field = await findCurrentField()
				const { key } = await getField(field)
				if (allFields.includes(key)) {
					setDisableNext(true)
				} else {
					setDisableNext(false)
				}
			} else {
				setDisableNext(false)
			}
		}

		checkErrors()
	}, [syncErrors, activeFieldTab])

	const [error, setError] = useState(null)
	const [isFieldSaved, setIsFieldSaved] = useState(false)

	const fieldsArray = useMemo(
		() => getFieldsList(individual_document?.documents),
		[individual_document?.documents],
	)

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individual_document?.documents, user_type),
		[individual_document?.documents, user_type],
	)

	const tabsList = useMemo(() => getTabs(documentBoundFields.length > 0), [documentBoundFields])
	const fields = useMemo(() => {
		const $fieldsArray = fieldsArray.filter((f) => f?.is_document_bound === false)
		return getFieldsByUser($fieldsArray, user_type)
	}, [fieldsArray, user_type])

	const total_fields = fields.length
	const total_doc_bound_fields = documentBoundFields.length

	const [activeField, setactiveField] = useState(total_fields === 0 ? 0 : 1)
	const { workflow_step_2: formName } = useSelector((state) => state.form)
	const formValues = useMemo(() => formName?.values, [formName?.values])
	const fieldValues = formValues?.fields || []
	const documentBoundFieldValues = formValues?.document_bound_fields || []

	const documentList = useMemo(
		() => getDocumentsList(individual_document?.documents),
		[individual_document],
	)

	const [docs, setDocs] = useState([])
	const isStepAvailable = !isLoading && documentList.length === 0

	const toggleField = (tab) => {
		if (activeField !== tab) {
			if (tab >= 1 && tab <= total_fields) {
				setactiveField(tab)
			}
		}
	}
	const { active } = useSelector((state) => state.form?.workflow_step_2)
	const $activeField = useMemo(() => {
		if (active) {
			// eslint-disable-next-line no-unused-vars
			const [_, fieldName] = active.split('.')
			return fields.find((field) => field?.name === fieldName)
		}
		return undefined
	}, [active])

	useEffect(() => {
		if ($activeField && fields.length > 0) {
			const $docs = $activeField?.documents || []
			setDocs($docs)
		}
	}, [$activeField])

	const saveFields = () => {
		return new Promise((resolve) => {
			if (total_fields === activeField) {
				setTimeout(() => {
					setIsFieldSaved(true)
					resolve(true)
				}, 1000)
			} else {
				setIsFieldSaved(false)
				resolve(true)
			}
		})
	}

	const findCurrentField = () => {
		return Promise.resolve(fieldValues[activeField - 1])
	}

	const handleNext = async () => {
		try {
			const field = await findCurrentField()
			const { value, key } = await getField(field)
			const field_name = `fields[${activeField - 1}].${key}`
			props.dispatch(touch('workflow_step_2', field_name))

			if (value !== '') {
				setError(null)
				toggleField(activeField + 1)
				await saveFields()
			}
		} catch (error) {
			notification('error', 'Something went wrong while saving the field')
		}
	}

	const onSubmit = async () => {
		try {
			if (activeFieldTab === 0 && tabsList.length > 1) {
				setActiveFieldTab(1)
			} else {
				const documentsPayload = getDocumentsPayload(
					documentList,
					documentBoundFieldValues,
					fieldValues,
					user_type,
				)
				const payload = getPayload(
					id,
					uploads_id,
					individual_document?.id,
					documentsPayload,
				)

				const response = await saveAssignedWorkflow({ payload, url: URL })
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					toggleTab(activeTab + 1)
					!CompleteSteps.includes(activeTab) && CompleteSteps.push(activeTab)
					setCompleteSteps([...CompleteSteps])
				}
			}
		} catch (error) {
			notification('error', 'Something went wrong while saving workflow')
		}
	}

	const handlePrev = () => {
		if (activeFieldTab === 1) {
			setActiveFieldTab(0)
		} else {
			const preTab = activeTab - 1
			if (!CompleteSteps.includes(preTab)) {
				toggleTab(preTab)
			}
		}
	}

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				{isStepAvailable || (total_fields === 0 && total_doc_bound_fields === 0) ? (
					<Fragment>
						<Row className='justify-content-center'>
							<Col sm={6}>
								<div className='alert alert-info'>
									In this step, no additional information is required. You can
									proceed by simply saving this workflow with a click on the
									&apos;Next&apos; button.
								</div>
							</Col>
						</Row>
					</Fragment>
				) : (
					<Fragment>
						<Tab.Group selectedIndex={activeFieldTab}>
							<Tab.List className='-mt-[10px] mb-3 flex divide-x rounded-sm !border border-gray-200'>
								{tabsList.map((tab, idx) => (
									<Tab
										as='span'
										key={idx}
										i
										className={({ selected }) =>
											classNames(
												'w-full !px-3 py-2.5 text-sm font-medium',
												' focus:outline-none ',
												selected
													? 'bg-[#dee3fa]  text-blue-700'
													: 'bg-[#eef1fd] text-gray-500',
											)
										}>
										{tab.label}
									</Tab>
								))}
							</Tab.List>
							<div>
								<h5 className='mb-3 text-center'>
									<span className='font-bold'>
										{createdBy
											? `${createdBy.first_name} ${createdBy.last_name}`
											: '{advisor}'}
									</span>{' '}
									<span>would like you to provide the following information</span>
								</h5>
							</div>
							<Tab.Panels>
								<Tab.Panel>
									<ProfileFieldsTab
										handleNext={handleNext}
										docs={docs}
										isFieldSaved={isFieldSaved}
										error={error}
										activeField={activeField}
										fields={fields}
										setIsFieldSaved={setIsFieldSaved}
										toggleField={toggleField}
										total_fields={total_fields}
										disableNext={disableNext}
										screen_type={screen_type}
									/>
								</Tab.Panel>
								<Tab.Panel>
									<DocumentBoundFieldsTab
										documentBoundFields={documentBoundFields}
									/>
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</Fragment>
				)}

				<div className='my-4 border-t border-dashed' />
				<div className='actions clearfix'>
					<div className='m-0 flex justify-between'>
						<Button
							type='button'
							onClick={handlePrev}
							disabled={CompleteSteps.includes(activeTab - 1)}>
							Previous
						</Button>

						<Button
							type='submit'
							isLoadingText='Saving...'
							isLoading={isWorkflowSaving}>
							Next
						</Button>
					</div>
				</div>
			</form>
		</Fragment>
	)
}

const Tab2Form = reduxForm({
	form: 'workflow_step_2',
	enableReinitialize: true,
})(Tab2)

export default Tab2Form
