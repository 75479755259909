import classNames from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

const DocumentTabs = () => {
	const tabs = useMemo(
		() => [
			{
				label: 'Document Templates',
				href: '/documents',
			},
			{
				label: 'Deleted Document Templates',
				href: '/deleted-document-templates',
			},
		],
		[],
	)
	return (
		<div className='flex space-x-1 '>
			<div className='flex divide-x overflow-hidden rounded border-[1px] !border-gray-300'>
				{tabs?.map((tab, index) => {
					return (
						<Link to={tab.href} key={index}>
							<div
								className={classNames(
									'w-auto  px-3 py-2.5 text-sm font-medium leading-5 transition-colors',
									tab?.href == location?.pathname
										? ' bg-white text-main'
										: 'text-gray-500 hover:bg-gray-100 hover:text-main',
								)}>
								{tab?.label}
							</div>
						</Link>
					)
				})}
			</div>
		</div>
	)
}

export default DocumentTabs
