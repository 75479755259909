import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import StatusDropdown from './StatusDropdown'

const StatusContainer = () => {
	const [isOpen, setIsOpen] = useState(false)
	const values = useSelector((state) => state?.form?.task_form?.values)

	const style = useMemo(() => (isOpen ? { display: 'flex' } : { display: 'none' }), [isOpen])

	return (
		<ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className='p-0'>
			<DropdownToggle caret tag='div'>
				<span className='text-[#B5B5C3]'>in list</span>
				&nbsp;
				<span className='cursor-pointer underline'>
					{values?.progress_status?.value ?? 'To do List'}
				</span>
			</DropdownToggle>
			<DropdownMenu onClick={() => setIsOpen(true)} className='!p-0'>
				<DropdownItem tag='div' className='!p-0'>
					<div
						className='flex w-[185px] shrink-0 flex-col divide-y !border bg-white'
						style={style}>
						<div className='flex h-10 w-full items-center justify-center'>
							<div />
							<h3 className='m-0  text-sm font-bold not-italic leading-5 text-[#495057]'>
								Move Task
							</h3>
							{/* <button
								className='text-2xl'
								type='button'
								onClick={() => setIsOpen(false)}>
								<i className='uil-times' />
							</button> */}
						</div>
						<div className='mx-auto pt-3'>
							<StatusDropdown />
						</div>
					</div>
				</DropdownItem>
			</DropdownMenu>
		</ButtonDropdown>
	)
}

export default StatusContainer
