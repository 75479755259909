import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetClientsQuery } from '../client-apis'
import Filters from './filters'
import OnboardedClientsTable from './onboarded-clients-table'

const OnboardedClientsContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination

	useEffect(() => {
		if (pagination) {
			const parseQueryString = getParseQueryString(location?.search)
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchClients, { data, isFetching: isLoading }] = useLazyGetClientsQuery()

	useEffect(() => {
		if (location?.search) {
			fetchClients({ ...getParseQueryString(location?.search), role: 'client' })
		}
	}, [location?.search])

	return (
		<Fragment>
			<Filters />
			<OnboardedClientsTable
				clients={data?.clients || []}
				meta={data?.meta}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default OnboardedClientsContainer
