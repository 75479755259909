import { Field } from 'formik'
import { useMemo } from 'react'
import { RadioGroupField } from 'theme/ui/forms'

const ScreenTypeField = ({ name, label }) => {
	const screen_options = useMemo(
		() => [
			{ label: 'Step-by-step', value: 'step_by_step' },
			{ label: 'All on the same page', value: 'same_page' },
		],
		[],
	)

	return (
		<Field
			label={`How do you like to present workflow fulfillment to the ${label}?`}
			name={name}
			component={RadioGroupField}
			options={screen_options}
			isRequiredField
		/>
	)
}

export default ScreenTypeField
