import { useQuery } from 'hooks'
import { snakeCase } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { findObjectByField } from 'utils/commonFunctions'
import K from 'utils/constants'

const useWorkflowQuery = (id) => {
	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => defaultGroup?.group, [defaultGroup])
	const defaultGroupName = snakeCase(group?.name)
	const CLIENT_KEY = 'client'
	// const ADVISOR_KEY = 'advisor'

	const URL = useMemo(
		() =>
			defaultGroupName === CLIENT_KEY ? `/client/workflow/${id}` : `/advisor/workflow/${id}`,
		[defaultGroupName, id],
	)

	const options = {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	}
	const { data, isLoading, isError, mutate } = useQuery(URL, options)
	const workflow = data?.workflow
	const steps = workflow?.workflow_steps || []
	const signPriority = data?.signPriority
	const signature_status = data?.signatureStatus

	const isNotAvailableForSignature = useMemo(
		() =>
			workflow === null &&
			isLoading === false &&
			signPriority === false &&
			signature_status !== '',
		[workflow, isLoading, signPriority, signature_status],
	)

	return {
		res: data,
		data: {
			signPriority,
			isAllDocumentsSigned: workflow?.isAllDocumentsSigned,
			workflow,
			steps,
			uploads: findObjectByField(steps, 'type', K.STEPS.UPLOADS),
			individualDocument: findObjectByField(steps, 'type', K.STEPS.INDIVIDUAL_DOCUMENT),
			createdBy: workflow?.created_by,
		},
		isNotAvailableForSignature,
		signature_status,
		isLoading,
		isError,
		mutate,
	}
}

export default useWorkflowQuery
