import { useState } from 'react'

import NoteForm from 'modules/notes/note-form'
import UnAuthorize from 'pages/StaticPages/UnAuthorize'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import hasPermission from '../../utils/checkPermission'

const NoteCreate = () => {
	const [isCreatePermission] = useState(hasPermission('note:create'))

	return (
		<AppLayout
			meta={{
				title: 'Create Note',
			}}>
			{isCreatePermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<PageHeader
							title='Create Note'
							description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
							hasBackButton={true}
						/>
						<div className='page-body'>
							<NoteForm />
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default NoteCreate
