import ExpiredInDropdown from './expired-in-dropdown'

export const createExpredInOption = (option) => ({
	label: option,
	value: option,
})

export const createOptionLabel = (option) => (
	<span className='font-bold text-gray-900'>{option?.label}</span>
)

export default ExpiredInDropdown
