import { createApi } from '@reduxjs/toolkit/query/react'

import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

export const assignedWorkflowApi = createApi({
	reducerPath: 'AssignedWorkflow',
	keepUnusedDataFor: 0,
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['AssignedWorkflow'],
	endpoints: (builder) => ({
		getAssignedWorkflow: builder.query({
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['AssignedWorkflow'],
			transformResponse: (response, _, arg) => {
				const workflows = Array.isArray(response?.data?.workflows)
					? response?.data?.workflows.map((workflow, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: workflow?.id,
							title: workflow?.title || workflow?.workflow_template?.title,
							status: workflow?.status,
							assigned_at: formatDate(workflow?.created_at),
							last_updated: formatDate(workflow?.updated_at),
					  }))
					: []
				return {
					workflows,
					meta: response?.data?.meta,
				}
			},
		}),
		saveAssignedWorkflow: builder.mutation({
			query: ({ payload, url }) => ({
				url: url,
				method: 'POST',
				data: payload,
			}),
		}),
		getWorkflowDocuments: builder.query({
			query: (url) => ({
				url: url,
				method: 'GET',
			}),
		}),
	}),
})

export const {
	useLazyGetAssignedWorkflowQuery,
	useSaveAssignedWorkflowMutation,
	useGetWorkflowDocumentsQuery,
} = assignedWorkflowApi
