import { Fragment, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getPaginationParams, getParseQueryString } from 'utilities/helpers'
import { toQueryString } from 'utils/commonFunctions'
import { useLazyGetNotificationsTemplatesQuery } from '../notifications-templates-api'
import NotificationsTemplatesFilters from './notifications-templates-filters'
import NotificationsTemplatesTable from './notifications-templates-table'

const NotificationsTemplatesListingContainer = () => {
	const location = useLocation()
	const history = useHistory()
	const { page, limit } = getPaginationParams(location?.search)
	const [pagination, setPagination] = useState({ pageIndex: page - 1, pageSize: limit })
	const { pageIndex, pageSize } = pagination
	const parseQueryString = getParseQueryString(location?.search)

	useEffect(() => {
		if (pagination) {
			const queryString = toQueryString({
				...parseQueryString,
				page: pageIndex + 1,
				limit: pageSize,
			})
			history.push(`${window.location.pathname}?${queryString}`)
		}
	}, [pagination])

	const [fetchNotificationsTemplates, { data, isFetching }] =
		useLazyGetNotificationsTemplatesQuery()

	useEffect(() => {
		if (location?.search) {
			fetchNotificationsTemplates({
				...parseQueryString,
			})
		}
	}, [location?.search])

	return (
		<Fragment>
			<NotificationsTemplatesFilters />
			<NotificationsTemplatesTable
				data={data?.notificationsTemplates}
				meta={data?.meta}
				isLoading={isFetching}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</Fragment>
	)
}

export default NotificationsTemplatesListingContainer
