import base64 from 'base-64'
import { useSelector } from 'react-redux'

import apiEndpoints from 'helpers/apiEndpoints'
import { store } from 'store/common/actions'
import { getPermissions, login, register } from 'store/jwtauth/actions'
import { get, remove } from 'utils/storageService'
import { dispatch } from '../../../store'

export const getEmail = (token) => {
	try {
		const data = base64.decode(token)
		const _json = JSON.parse(data)
		return _json?.email
	} catch (error) {
		return null
	}
}

export const getPhoneNumber = (token) => {
	try {
		const data = base64.decode(token)
		const _json = JSON.parse(data)
		return _json?.phone_number
	} catch (error) {
		return null
	}
}

export const addPropertyTotoken = (propertyName, propertyValue, prevToken) => {
	const data = base64.decode(prevToken)
	const _json = JSON.parse(data)

	_json[propertyName] = propertyValue

	const _jsonStr = JSON.stringify(_json)

	const token = base64.encode(_jsonStr)

	return token
}

export const usePayload = (token) => {
	const { password, phone_number } = useSelector((state) => state.clientOnboarding)

	const payload = {
		email: null,
		first_name: null,
		last_name: null,
		team_name: null,
		invite_user: true,
	}
	try {
		const data = base64.decode(token)
		const _json = JSON.parse(data)
		return {
			email: _json?.email,
			first_name: _json?.first_name,
			last_name: _json?.last_name,
			invite_user: true,
			team_name: _json?.team,
			password,
			passwordConfirm: password,
			phone_number,
		}
	} catch (error) {
		return payload
	}
}

export const verifyOTP = async (payload) => {
	return new Promise((resolve, reject) => {
		dispatch(store(apiEndpoints.userOnBoard.phone.verifyOTP, payload)).then((res) => {
			if (res?.status === 200) {
				resolve(res)
			} else {
				reject(new Error('Something went wrong'))
			}
		})
	})
}

export const createUser = async (payload) => {
	return new Promise((resolve, reject) => {
		dispatch(register(apiEndpoints.auth.register, payload)).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject(new Error('Something went wrong while registering.'))
			}
		})
	})
}

export const createSession = async (payload) => {
	return new Promise((resolve, reject) => {
		dispatch(login(apiEndpoints.auth.login, payload)).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject(new Error('Something went wrong during authentication.'))
			}
		})
	})
}

export const fetchPermissions = async () => {
	return new Promise((resolve, reject) => {
		dispatch(getPermissions()).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject(new Error('Something went wrong while fetching permissions.'))
			}
		})
	})
}

export const removeToken = async () => {
	return new Promise((resolve, reject) => {
		remove('token')
		const token = get('token')
		if (!token) {
			resolve(true)
		} else {
			reject(new Error('Token is still available.'))
		}
	})
}

export { default as StepOne } from './email-confirm'
export { default as StepTwo } from './email-otp-verify'
export { default as StepThree } from './password-creation'
export { default as StepFour } from './phone-number'
export { default as StepFive } from './phone-number-verification'
