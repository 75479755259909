import apiEndpoints from 'helpers/apiEndpoints'
import { dispatch } from 'store'
import { store } from 'store/common/actions'
import ClientImportForm from './client-import-form'

export const validatePayload = (payload, dbFieldsOptions) => {
	return new Promise((resolve, reject) => {
		const system_fields = dbFieldsOptions.map((field) => field?.value)
		const mapped_fields = payload?.header_fields.map((field) => field?.system)

		system_fields.forEach((field) => {
			if (!mapped_fields.includes(field)) {
				reject(new Error(`Field ${field} is not mapped to system field`))
			}
		})
		resolve()
	})
}

export const importCsv = (payload) => {
	return new Promise((resolve, reject) => {
		dispatch(store(apiEndpoints.clientImport, payload)).then((response) => {
			if (response?.status === 200) {
				resolve(response)
			} else {
				reject(new Error('Something went wrong while importing users'))
			}
		})
	})
}

export default ClientImportForm
