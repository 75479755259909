import { useLazyGetWorkflowCommentsQuery } from 'modules/workflows/workflow-comment-section/workflow-comment-api'
import { useEffect, useMemo, useState } from 'react'
import CommentsPopup from './comments-popup'

const CommentsContainer = ({ workflowId, stepId }) => {
	const [isOpen, setIsOpen] = useState(false)
	const onOpen = () => setIsOpen(true)
	const onClose = () => setIsOpen(false)

	const [fetchComments, { data, isLoading }] = useLazyGetWorkflowCommentsQuery()

	useEffect(() => {
		if (workflowId && stepId && isOpen) {
			fetchComments({
				page: 1,
				limit: 10,
				workflow: workflowId,
				workflow_step: stepId,
			})
		}
	}, [workflowId, stepId, isOpen])

	const comments = useMemo(() => data?.comments || [], [data?.comments])

	return (
		<div>
			<button
				type='button'
				onClick={onOpen}
				className='cursor-pointer space-x-1 rounded-md bg-main/10 !px-3 py-2 text-[15px] font-semibold transition-all hover:bg-main/20 hover:text-main active:bg-main/30'>
				<i className='uil-comment-alt-message' />
				<span>View Comments</span>
			</button>
			<CommentsPopup
				isOpen={isOpen}
				onClose={onClose}
				comments={comments}
				isLoading={isLoading}
			/>
		</div>
	)
}

export default CommentsContainer
