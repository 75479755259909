import { Field, FormikProvider, useFormik } from 'formik'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useAppRouter } from 'hooks'
import { Button, RadioGroupField, SelectField, TextareaField } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getPayload, validationSchema } from '.'
import { useCreateNoteMutation, useUpdateNoteMutation } from '../notes-api'
import { NOTES_APP_ROUTES } from '../routes'

const NoteForm = ({ note }) => {
	const {
		Permissions: { groups },
	} = useSelector((state) => state)
	const { navigate } = useAppRouter()
	const [createNote] = useCreateNoteMutation()
	const [updateNote] = useUpdateNoteMutation()

	const teamOptions = useMemo(
		() =>
			groups
				.map((g) => g?.team)
				.map((t) => ({
					label: t?.team_name,
					value: t?.id,
				})),
		[groups],
	)

	const formik = useFormik({
		initialValues: {
			visibility: note?.visibility || '0',
			teams: note?.teams || [],
			notes: note?.notes || '',
		},
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				setSubmitting(true)
				const payload = getPayload(values)
				let response
				if (note) {
					response = await updateNote({ payload, id: note?.id })
				} else {
					response = await createNote(payload)
				}

				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					navigate(NOTES_APP_ROUTES.findAll())
				}
				setSubmitting(false)
			} catch (error) {
				notification('error', error?.message)
			}
		},
	})

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Note Visiblity</h3>
								<p>Select the note visibility from here.</p>
							</div>

							<div className='form-fields-card gap-3'>
								<Field
									name='visibility'
									component={RadioGroupField}
									label='Select the note visibility from here.'
									options={[
										{ label: 'Public', value: '1' },
										{ label: 'Private', value: '0' },
									]}
								/>

								<Field
									type='select'
									name='teams'
									label='Select Teams'
									isRequiredField
									component={SelectField}
									options={teamOptions}
									isMulti
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Note Description</h3>
								<p>Enter the note description here.</p>
							</div>

							<div className='form-fields-card'>
								<Field
									type='textarea'
									name='notes'
									label='Notes Description'
									rows={5}
									isRequiredField
									component={TextareaField}
								/>
							</div>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Easily manage your feature hassle-free from one convenient
								dashboard.
							</p>
						</div>
					</div>
				</div>

				<div className='form-container !border-t py-6'>
					<div className='flex justify-end'>
						<Button
							type='submit'
							isLoading={formik.isSubmitting}
							isLoadingText='Saving...'>
							Save
						</Button>
					</div>
				</div>
			</form>
			{/* <form onSubmit={handleSubmit}>
				<div className='flex w-full gap-10'>
					<div className='form-container'>
						<div className='form-row'>
							<div className='form-heading'>
								<h3>Note Visiblity</h3>
								<p>Select the note visibility from here.</p>
							</div>

							<div className='form-fields-card'>
								<div>
									<label>Please Select visibility of note</label>
									<div className='flex space-x-6'>
										<Field
											type='radio'
											name='visibility'
											component={RadioComponent}
											value='1'
											id='public'
											label='Public'
											validate={validate.visibility}
											defaultChecked
										/>

										<Field
											type='radio'
											name='visibility'
											component={RadioComponent}
											value='0'
											id='private'
											label='Private'
											validate={validate.visibility}
										/>
									</div>
								</div>

								<Field
									type='select'
									name='teams'
									label='Select Teams *'
									component={SelectField}
									options={teamOptions}
									validate={validate.teams}
									isMulti
								/>
							</div>
						</div>

						<div className='form-row'>
							<div className='form-heading'>
								<h3>Note Description</h3>
								<p>Enter the note description here.</p>
							</div>

							<div className='form-fields-card'>
								<Field
									type='textarea'
									name='notes'
									label='Notes *'
									rows={5}
									component={TextField}
									validate={validate.notes}
								/>
							</div>
						</div>
					</div>
					<div className='max-w-[200px]'>
						<div className='flex items-start space-x-2 !py-5'>
							<i className='mdi mdi-lightbulb-on text-main' />
							<p className='text-xs text-gray-500'>
								Easily manage your feature hassle-free from one convenient
								dashboard.
							</p>
						</div>
					</div>
				</div>

				<div className='form-container !border-t py-6'>
					<div className='flex justify-end'>
						<Button type='submit' isLoading={isSubmitting} isLoadingText='Saving...'>
							Save
						</Button>
					</div>
				</div>
			</form> */}
		</FormikProvider>
	)
}

export default NoteForm
