import classNames from 'classnames'
import { Fragment, useMemo } from 'react'

import saveIcon from 'assets/images/svg-icons/save-icon.svg'
import { useTabs } from 'contexts/tabs-context'
import { Button } from 'theme/ui/forms'
import { useSignatureDocumentStatus } from '../signature-document-context'
import { getTabs } from '../tabs-header'

const TabsFooter = ({ formik }) => {
	const { activeTab, setActiveTab } = useTabs()
	const [isDocumentSaved] = useSignatureDocumentStatus()
	const isSignatureRequired = useMemo(() => formik?.values?.signature_required, [formik?.values])
	const totalTabs = useMemo(() => getTabs(isSignatureRequired).length, [isSignatureRequired])

	const onPrevious = () => {
		setActiveTab(activeTab - 1)
	}

	return (
		<div className='px-[75px]'>
			{activeTab === 3 && (
				<Fragment>
					{isDocumentSaved ? (
						<small className='text-success mb-3'>
							<strong className='mr-6 flex items-center justify-end'>
								Signature fields have been successfully saved! You may now proceed.
							</strong>
						</small>
					) : (
						<small className='text-danger mb-3'>
							<strong className='mr-6 flex items-center justify-end'>
								Please make sure you saved the document using the{' '}
								<img src={saveIcon} className='mx-1 opacity-60' /> save icon in the
								top right corner before submitting.
							</strong>
						</small>
					)}
				</Fragment>
			)}
			<div
				className={classNames(
					'flex !border-t py-6',
					activeTab === 1 ? 'justify-end' : 'justify-between',
				)}>
				{activeTab > 1 ? (
					<Button type='button' onClick={onPrevious} variant='white'>
						Previous
					</Button>
				) : undefined}

				<Button
					type='submit'
					isLoading={formik.isSubmitting}
					disabled={!formik.isValid || (!isDocumentSaved && activeTab === 3)}
					isLoadingText='Saving...'>
					{activeTab === totalTabs ? 'Publish Now' : 'Next'}
				</Button>
			</div>
		</div>
	)
}

export default TabsFooter
