import { Fragment } from 'react'
import { reduxForm } from 'redux-form'

import OTPField from 'components/Forms/OTPField'

const StepForm = (props) => {
	const { otpState, handleSubmit, history, onResend } = props
	const [otp, setOtp] = otpState

	return (
		<Fragment>
			<div className='max-w-sm self-center py-2'>
				<h3 className='mb-2 text-center text-xl font-bold'>We have sent you an OTP</h3>
				<p className='text-muted mb-9 text-center'>
					Enter the OTP we have sent you on your email address
				</p>

				<form className='flex flex-col space-y-7' onSubmit={handleSubmit}>
					<div>
						<div className='flex justify-center'>
							<OTPField otpState={[otp, setOtp]} />
						</div>
						<p className='text-center font-normal'>
							Didn&apos;t receive the OTP?
							<button
								type='button'
								onClick={onResend}
								className='btn btn-link px-1 py-0 hover:underline disabled:cursor-not-allowed'
								disabled={false}>
								Resend Code
							</button>
						</p>
					</div>
					<div className='text-center'>
						<button
							type='submit'
							className='btn-primary-1 mx-auto rounded-[4px] px-10 py-2'>
							Next
						</button>
					</div>
					<div className='text-center'>
						<button type='button' onClick={() => history.goBack()} className='btn-link'>
							<i className='uil uil-arrow-left' /> Go back
						</button>
					</div>
				</form>
			</div>
		</Fragment>
	)
}

export default reduxForm({
	form: 'onboarding_step_two',
	enableReinitialize: true,
})(StepForm)
