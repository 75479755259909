import { createColumnHelper } from '@tanstack/react-table'
import React, { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import apiEndpoints from 'helpers/apiEndpoints'
import { update } from 'store/common/actions'
import DataTable from 'theme/ui/data-table'
import StatusQuery from '../StatusQuery'
import FilterForm from './FilterForm'
import useListViewQuery from './useListViewQuery'

const ListView = () => {
	const dispatch = useDispatch()
	const { statuses } = StatusQuery()
	const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 })
	const { assignments, meta, isLoading, mutate } = useListViewQuery(pagination)

	const onStatusChange = (e, task_id) => {
		e.preventDefault()
		const status = e.target.value

		dispatch(
			update(`${apiEndpoints.task}/${task_id}`, 'update-status', {
				status,
			}),
		).then((response) => {
			if (response?.status === 200) {
				mutate()
			}
		})
	}

	const columnHelper = createColumnHelper()

	const columns = [
		columnHelper.accessor('serial', {
			header: () => <span>#</span>,
			cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'title',
			header: () => <span>Title</span>,
			cell: (info) => (
				<Link
					to={`/tasks/${info.getValue()?.id}`}
					className='link line-clamp-1 max-w-[180px] text-sm font-bold !text-gray-700'
					title={info.getValue()?.task_title}>
					{info.getValue()?.task_title}
				</Link>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'progress_status',
			header: () => <span>Status</span>,
			cell: (info) => (
				<Fragment>
					<select
						className='rounded bg-[#F5F6F8] px-2 py-1 text-xs'
						onChange={(e) => onStatusChange(e, info.getValue()?.id)}
						defaultValue={info.getValue()?.progress_status}>
						{statuses.map((status, i) => (
							<option key={i}>{status?.title}</option>
						))}
					</select>
				</Fragment>
			),
		}),
		columnHelper.accessor('assignee', {
			header: () => <span>Assignee</span>,
			cell: (info) => (
				<div className='flex space-x-1'>
					<div className='flex items-center justify-center rounded bg-indigo-100 px-2 py-1 text-xs'>
						<span className='font-semibold uppercase text-indigo-600'>
							{info.getValue()?.first_name} &nbsp; {info.getValue()?.last_name}
						</span>
					</div>
				</div>
			),
		}),
		columnHelper.accessor('workflow', {
			header: () => <span>Workflow</span>,
			cell: (info) => (
				<span
					className='tag tag-info line-clamp-1 max-w-max'
					title={info.getValue()?.title}>
					{info.getValue()?.title}
				</span>
			),
		}),
		columnHelper.accessor('tags', {
			header: () => <span>Tags</span>,
			cell: (info) => (
				<div className='flex max-w-[150px] flex-wrap gap-1'>
					{info.getValue().map((tag, i) => (
						<span key={i} className='tag tag-primary'>
							{tag?.tag}
						</span>
					))}
				</div>
			),
		}),
	]

	return (
		<React.Fragment>
			<FilterForm />
			<DataTable
				columns={columns}
				data={assignments}
				isLoading={isLoading}
				pagination={pagination}
				setPagination={setPagination}
				meta={meta}
			/>
		</React.Fragment>
	)
}

export default ListView
