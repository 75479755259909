import { Field } from 'formik'

import { useState } from 'react'
import { SelectField } from 'theme/ui/forms'
import { createOptionLabel } from '.'
import DocumentPackagesQuery from './document-packages-query'

const DocumentPackageDropdown = () => {
	const [search, setSearch] = useState('')
	const { documentPackages, isLoading } = DocumentPackagesQuery(search)

	const filterOption = ({ data }, search) => {
		if (data.title.toLowerCase().includes(search.toLowerCase())) {
			return true
		} else {
			return false
		}
	}

	return (
		<Field
			label='Select Packages'
			type='select'
			name='document_packages'
			options={documentPackages}
			isLoading={isLoading}
			isMulti={true}
			onInputChange={(value) => setSearch(value)}
			component={SelectField}
			isHelperIcon={true}
			placeholder='For eg:- Co. Onboarding process 101'
			getOptionLabel={(option) => createOptionLabel(option)}
			filterOption={filterOption}
		/>
	)
}

export default DocumentPackageDropdown
