import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useMemo } from 'react'

import NoteTeamDetails from '../notes-listing/note-team-details'
import NotesAction from '../notes-listing/notes-action'

const NotesDetails = ({ isOpen, onClose, note }) => {
	const { teams, users } = useMemo(() => {
		return {
			teams: Array.isArray(note?.teams) ? note.teams : [],
			users: Array.isArray(note?.users) ? note.users : [],
		}
	}, [note])

	return (
		<div>
			<Transition appear show={isOpen} as={Fragment}>
				<Dialog as='div' open={true} onClose={() => {}} className='popup-overlay relative'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black/25' />
					</Transition.Child>
					<div className='fixed inset-0 bg-black/30' aria-hidden='true' />
					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child
								as={Fragment}
								enter='ease-out duration-300'
								enterFrom='opacity-0 scale-95'
								enterTo='opacity-100 scale-100'
								leave='ease-in duration-200'
								leaveFrom='opacity-100 scale-100'
								leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-lg transform overflow-hidden rounded-md bg-white p-3 text-left align-middle shadow-xl transition-all'>
									<div className='flex flex-col space-y-3'>
										<div className='flex w-full items-center justify-between'>
											<h3 className='text-lg font-medium text-gray-900'>
												Note Details
											</h3>
											<button type='button' className='' onClick={onClose}>
												<i className='fas fa-times text-lg' />
											</button>
										</div>
										<hr />
										<div className=''>
											<p>{note?.notes}</p>
											<div className='border-top note-box-footer pt-1'>
												<NoteTeamDetails teams={teams} users={users} />
												<NotesAction note={note} />
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</div>
	)
}

export default NotesDetails
