import apiEndpoints from 'helpers/apiEndpoints';
import useQuery from './useQuery';

const UserQuery = () => {
    const { data, isLoading } = useQuery(apiEndpoints.user.profile)

    return {
        user: data?.data?.user,
        isOnboarded: data?.data?.onBoardStatus,
        isLoading
    }
}

export default UserQuery