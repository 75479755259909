import Dropzone from 'react-dropzone'
import notification from 'utilities/notification'
const FileUploadZone = ({ onDrop, accept }) => {
	const onDropRejected = () => {
		notification('error', 'Selected file is not supported')
	}

	return (
		<Dropzone onDrop={onDrop} accept={accept} onDropRejected={onDropRejected}>
			{({ getRootProps, getInputProps }) => (
				<div className='dropzone client-workflow-dropzone'>
					<div className='dz-message needsclick' {...getRootProps()}>
						<input {...getInputProps()} multiple={false} />
						<div className='mb-3'>
							<i className='display-4 text-muted uil uil-cloud-upload' />
						</div>
						<h6 className='m-3'>Drop your file here or click to upload.</h6>
						<p className='text-sm text-gray-500'>
							<span>Supports:</span> <span>jpeg, jpg, png, pdf</span>
						</p>
					</div>
				</div>
			)}
		</Dropzone>
	)
}

export default FileUploadZone
