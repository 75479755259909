import classNames from 'classnames'

const Actions = ({ document, updated_at, isActionActive, toggle }) => {
	const fileUrl = document?.file_url

	const documentObj = {
		fileUrl: fileUrl,
		id: document?.id,
		workflow_step_document_id: document?.workflow_step_id,
	}

	return (
		<div className='w-[200px]'>
			{isActionActive ? (
				<>
					<div className='flex space-x-3'>
						<a
							href={fileUrl || '#'}
							download
							className={classNames('action-btn btn-info-outline cursor-pointer')}>
							<i className='uil uil-import' style={{ fontSize: 16 }} />
							<span>Download</span>
						</a>
						<button
							type='button'
							disabled={!fileUrl}
							className='action-btn btn-primary-outline'
							onClick={() => toggle(documentObj)}>
							<i className='uil uil-eye' style={{ fontSize: 16 }} />
							<span>View</span>
						</button>
					</div>
				</>
			) : (
				<span className=' text-sm !text-gray-700'>{updated_at}</span>
			)}
		</div>
	)
}

export default Actions
