import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'
import { SpinLoader } from 'components/Common'
import {
	TabIndicator,
	WorkflowAssignForm,
	getAssignInitialValues,
} from 'modules/workflows/workflow-template'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'

const WorkflowTemplateAssign = () => {
	const [selectedIndex, setSelectedIndex] = useState(0)
	const location = useLocation()
	const initialValues = useMemo(
		() => getAssignInitialValues(location?.search),
		[location?.search],
	)

	return (
		<AppLayout
			meta={{
				title: 'Assign Workflow Template',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Assign Workflow'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
						headerRowClassName={classNames('items-center')}>
						<TabIndicator selectedIndex={selectedIndex} />
					</PageHeader>

					<div className='page-body'>
						{!initialValues ? (
							<SpinLoader />
						) : (
							<WorkflowAssignForm
								setSelectedIndex={setSelectedIndex}
								selectedIndex={selectedIndex}
								initialValues={initialValues}
							/>
						)}
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default WorkflowTemplateAssign
