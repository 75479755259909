import { Fragment, useEffect, useMemo, useState } from 'react'
import { TabContent, TabPane } from 'reactstrap'

import { toSnakeCase } from 'utils/commonFunctions'
import { getFieldsByUser, getFieldsList } from '../../utils'
import Tab1 from './Tab1'
import Tab2 from './Tab2'

const CorrectionTabs = ({
	uploads,
	individual_document,
	workflow_id,
	toggleTab,
	user_type,
	mutate,
	screen_type,
}) => {
	const [activeCorrection, setactiveCorrection] = useState(1)

	const total_correction = 2
	const upload_status = toSnakeCase(uploads?.status) || null
	const document_status = toSnakeCase(individual_document?.status) || null

	const fieldsArray = useMemo(
		() =>
			getFieldsList(individual_document?.documents).filter(
				(field) => field?.is_document_bound === false,
			),
		[individual_document?.documents],
	)

	const fields = useMemo(() => getFieldsByUser(fieldsArray, user_type), [fieldsArray, user_type])

	const rejectFields = useMemo(
		() => fields.filter((field) => field?.status === 'reject'),
		[fields],
	)

	useEffect(() => {
		if (['in_review', 'approved'].includes(upload_status)) {
			setactiveCorrection(2)
		}
	}, [upload_status])

	useEffect(() => {
		if (document_status === 'in_review') {
			toggleTab(3)
		}
	}, [document_status])

	const toggleCorrection = (tab) => {
		if (activeCorrection !== tab) {
			if (tab >= 1 && tab <= total_correction) {
				setactiveCorrection(tab)
			}
		}
	}

	const initialFields = rejectFields.map((field) => {
		if (field?.type === 'select') {
			// const options = Array.isArray(field?.values) ? field?.values : []
			// const selected = findObjectByField(options, 'value', field?.value)
			return { [field?.name]: null }
		} else if (field?.type === 'checkbox') {
			return { [field?.name]: false }
		} else {
			return { [field?.name]: '' }
		}
	})

	return (
		<Fragment>
			<span className='mb-3' style={{ float: 'right' }}>
				Corrections {activeCorrection} of {total_correction}
			</span>

			<div className='wizard clearfix'>
				<div className='content clearfix'>
					<TabContent activeTab={activeCorrection}>
						<TabPane tabId={1}>
							<Tab1
								activeCorrection={activeCorrection}
								toggleCorrection={toggleCorrection}
								workflow_id={workflow_id}
								uploads={uploads}
							/>
						</TabPane>
						<TabPane tabId={2}>
							<Tab2
								initialValues={{
									fields: initialFields,
									document_bound_fields: [],
								}}
								workflow_id={workflow_id}
								uploads_id={uploads?.id}
								individual_document={individual_document}
								toggleCorrection={toggleCorrection}
								toggleTab={toggleTab}
								user_type={user_type}
								mutate={mutate}
								screen_type={screen_type}
							/>
						</TabPane>
					</TabContent>
				</div>
			</div>
		</Fragment>
	)
}

export default CorrectionTabs
