import { useState } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import classNames from 'classnames'
import { useUser } from 'hooks'
import hasPermission from 'utils/checkPermission'
import { getLastTabValue, setLastTabValue } from 'utils/lastTabValue'
import MySubscriptions from './my-subscriptions'
import ProfileManage from './profile'
import WorkflowFiles from './workflow-files'

const ProfileTabs = () => {
	const [isUserListPermission] = useState(hasPermission('user:list'))
	const [activeTab, setActiveTab] = useState(getLastTabValue)
	const { user, group } = useUser()

	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab)
			setLastTabValue(tab)
		}
	}

	const tabs = [
		{
			id: 1,
			label: 'Profile',
			icon: <i className='uil-user-circle font-size-20' />,
			isPermitted: true,
		},
		{
			id: 2,
			label: 'Workflow Files',
			icon: <i className='uil-files-landscapes-alt font-size-20' />,
			isPermitted: ['advisor', 'owner', 'client'].includes(group?.slug),
		},
		{
			id: 3,
			label: 'My Subscriptions',
			icon: <i className='uil-files-landscapes-alt font-size-20' />,
			isPermitted: ['owner'].includes(group?.slug),
		},
	]

	return (
		<Card className='mb-0'>
			<Nav tabs className='nav-tabs-custom nav-justified !flex flex-nowrap'>
				{tabs.map((tab, idx) => {
					if (tab?.isPermitted) {
						return (
							<NavItem key={idx}>
								<NavLink
									className={classNames({
										active: activeTab === tab?.id,
									})}
									onClick={() => toggleTab(tab?.id)}>
									<span>{tab?.icon}</span>
									<span className='d-sm-block d-none'>
										<span className='block'>{tab?.label}</span>
									</span>
								</NavLink>
							</NavItem>
						)
					} else {
						return undefined
					}
				})}
			</Nav>
			<TabContent activeTab={activeTab} className='p-4'>
				<TabPane tabId={1}>
					<ProfileManage />
				</TabPane>
				<TabPane tabId={2}>
					{activeTab === 2 ? (
						<WorkflowFiles user_id={user?.id} groupSlug={group?.slug} />
					) : undefined}
				</TabPane>
				<TabPane tabId={3}>
					<MySubscriptions activeTab={activeTab} />
				</TabPane>
			</TabContent>
		</Card>
	)
}

export default ProfileTabs
