import { useState } from 'react'
import { Field } from 'redux-form'

import { SelectDropdown } from 'components/Forms2'
import ClientOptionsQuery from './ClientOptionsQuery'

const ClientDropdown = ({ validate }) => {
	const [search, setSearch] = useState('')
	const { clientOptions, isLoading } = ClientOptionsQuery(search)

	return (
		<Field
			label='Client'
			name='client_ids'
			type='select'
			isMulti={true}
			component={SelectDropdown}
			options={clientOptions}
			isLoading={isLoading}
			variant='horizontal'
			width={'w-56'}
			onInputChange={(value) => setSearch(value)}
			validate={validate}
			placeholder='Select client...'
		/>
	)
}

export default ClientDropdown
