import DocumentPackageContainer from 'modules/documents/document-packages'
import Header from 'modules/documents/header'
import AppLayout from 'theme/layouts/app-layout'
import { TableProvider } from 'theme/ui/data-table'
import hasPermission from '../../utils/checkPermission'
import UnAuthorize from '../StaticPages/UnAuthorize'

const DocumentPackageListing = () => {
	const isListPermission = hasPermission('teamDocument:list')

	return (
		<AppLayout
			meta={{
				title: 'Document Packages',
			}}>
			{isListPermission ? (
				<div className='page-content px-0 !pt-[70px]'>
					<div className='page-container'>
						<Header />
						<div className='page-body'>
							<TableProvider>
								<DocumentPackageContainer />
							</TableProvider>
						</div>
					</div>
				</div>
			) : (
				<UnAuthorize />
			)}
		</AppLayout>
	)
}

export default DocumentPackageListing
