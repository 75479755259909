import { encode } from 'base-64'
import { findObjectByField } from 'utils/commonFunctions'
import { STEPS_TYPE } from './workflow-template'

const ROUTE_KEY = '/workflows'
const OUTSTANDINGS_ROUTE_KEY = '/workflows-outstandings'
const FINAL_REVIEW_ROUTE_KEY = '/workflows-final-reviews'

export const routes = {
	templatesListing: () => `${ROUTE_KEY}`,
	create: () => `${ROUTE_KEY}/create`,
	edit: (id) => `${ROUTE_KEY}/${id}/edit`,
	assign: (option) => `${ROUTE_KEY}/assign?workflow=${encode(JSON.stringify(option))}`,
	outstandings: () => `${ROUTE_KEY}-outstandings`,
	review: (id) => `${OUTSTANDINGS_ROUTE_KEY}/${id}/review`,
	view: (id) => `${OUTSTANDINGS_ROUTE_KEY}/${id}`,
	finalReviews: () => `${FINAL_REVIEW_ROUTE_KEY}`,
	finalReviewView: (id) => `${FINAL_REVIEW_ROUTE_KEY}/${id}/review`,
}

export const statuses = [
	{ id: 1, name: 'Assigned', color: '#5B73E8' },
	{ id: 2, name: 'Approved', color: '#34C38F' },
	{ id: 3, name: 'Cancelled', color: '#F46A6A' },
	{ id: 4, name: 'Completed', color: '#00B448' },
	{ id: 5, name: 'In-Correction', color: '#F46A6A' },
	{ id: 6, name: 'In Review', color: '#F1B44C' },
	{ id: 7, name: 'Waiting For Signature', color: '#F1B44C' },
	{ id: 8, name: 'Rejected', color: '#F46A6A' },
	{ id: 9, name: 'Final Reviewer Rejected', color: '#F46A6A' },
	{ id: 10, name: 'Final Reviewer Approved', color: '#34C38F' },
	{ id: 10, name: 'In Final Review', color: '#F1B44C' },
	{ id: 11, name: 'Gathering Signatures', color: '#F1B44C' },
]

export const getWorkflowSteps = (steps) => {
	try {
		if (Array.isArray(steps)) {
			const upload_step = findObjectByField(steps, 'type', STEPS_TYPE.UPLOAD)
			const document_step = findObjectByField(steps, 'type', STEPS_TYPE.DOCUMENT)
			return {
				uploads: upload_step,
				documents: document_step,
			}
		} else {
			return {
				uploads: null,
				documents: null,
			}
		}
	} catch (error) {
		return {
			uploads: null,
			documents: null,
		}
	}
}

export const userTypes = [
	{ label: 'Advisor', value: 'advisor' },
	{ label: 'Client', value: 'client' },
	{ label: 'Co Applicant 1', value: 'applicant_1' },
	{ label: 'Co Applicant 2', value: 'applicant_2' },
	{ label: 'Co Applicant 3', value: 'applicant_3' },
]

export { default as Header } from './header'
export { default as WorkflowOutstandings } from './workflows-outstandings'
