import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Offcanvas, OffcanvasBody } from 'reactstrap'
import { Field, reduxForm } from 'redux-form'

import arrowIcon from 'assets/images/svg-icons/arrow-right-icon.svg'
import bellIcon from 'assets/images/svg-icons/bell-icon.svg'

import { TextField } from 'components/Forms/TextFieldComponent'
import { DatePickerInput, InputLg } from 'components/Forms2'
import apiEndpoints from 'helpers/apiEndpoints'
import { useUser } from 'hooks'
import { destroy, store, update } from 'store/common/actions'
import { showSuccessToast } from 'utils/commonFunctions'
import { preparePayload, validate } from '.'
import AssigneeDropdown from './assignee-dropdown'
import Attachments from './attachments'
import ClientDropdown from './client-dropdown'
import CopyLinkBtn from './copy-link-btn'
import { StatusContainer } from './status-dropdown'
import TagDropdown from './tag-dropdown'
import WatcherDropdown from './watcher-dropdown/WatcherDropdown'
import WorkflowDropdown from './workflow-dropdown'

const TaskForm = ({
	id,
	reset,
	onClose,
	canvasState,
	toggleCanvas,
	handleSubmit,
	initialValues,
}) => {
	const [isOpen] = canvasState
	const { team } = useUser()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const [attachments, setAttachments] = useState([])

	useEffect(() => {
		if (Array.isArray(initialValues?.attachments)) {
			setAttachments(initialValues?.attachments)
		}
	}, [initialValues?.attachments])

	const onSubmit = (values) => {
		setIsLoading(true)
		const payload = preparePayload({
			...values,
			team_id: team?.id,
			attachment_ids: attachments.map((attachment) => attachment?.id),
		})
		const action = id
			? update(apiEndpoints.task, id, payload)
			: store(apiEndpoints.task, payload)
		dispatch(action).then((response) => {
			setIsLoading(false)
			if (response?.status === 200) {
				showSuccessToast(response?.message)
				onClose()
				reset()
				setAttachments([])
			}
		})
	}

	const onUpload = (e) => {
		setIsUploading(true)
		const file = e.target.files[0]

		const payload = new FormData()
		payload.append('file', file)
		dispatch(store(apiEndpoints.file, payload)).then((response) => {
			setIsUploading(false)
			if (response?.status === 200) {
				Object.assign(file, { path: response?.data?.path })
				attachments.push({ file, id: response?.data?.fileID })
				setAttachments([...attachments])
				showSuccessToast('File Uploaded successfully')
			}
		})
	}
	const onRemove = (index, id) => {
		dispatch(destroy(apiEndpoints.file, id)).then((response) => {
			if (response?.affected === 1) {
				attachments.splice(index, 1)
				setAttachments([...attachments])
			}
		})
	}

	return (
		<React.Fragment>
			<Offcanvas
				backdrop={true}
				scrollable
				isOpen={isOpen}
				onClosed={onClose}
				toggle={toggleCanvas}
				direction='end'
				className='!w-5/12 overflow-auto'>
				<form onSubmit={handleSubmit((values) => onSubmit(values))}>
					<div className='offcanvas-header border-b !px-8 !pt-[36px]'>
						<div className='flex w-full justify-between'>
							<div>
								<Field
									name='task_title'
									type='text'
									id='task_title'
									component={InputLg}
									placeholder='Write a task name'
									validate={validate.title}
									autoFocus={true}
								/>
								<StatusContainer />
							</div>
							<div className='space-x-1'>
								<button type='button' className='btn !bg-[#F9F9F9] !p-1.5'>
									<img src={bellIcon} />
								</button>
								{id ? <CopyLinkBtn link={window.location.href} /> : undefined}
								<button
									type='button'
									className='btn !bg-[#F9F9F9] !p-1.5'
									onClick={onClose}>
									<img src={arrowIcon} />
								</button>
							</div>
						</div>
					</div>
					<OffcanvasBody className='min-h-screen !px-8'>
						<div className='flex justify-between'>
							<div
								className='flex flex-col items-end'
								style={{
									minWidth: 'calc(100% - 200px)',
								}}>
								<AssigneeDropdown
									validate={validate.assignee_id}
									label='Assignee'
								/>

								<WatcherDropdown />

								<ClientDropdown />

								<WorkflowDropdown />

								<Field
									label='Due Date'
									name='due_date'
									className='w-56'
									component={DatePickerInput}
									variant='horizontal'
									minDate={new Date()}
								/>

								<TagDropdown />
							</div>

							<div className='flex flex-col items-end space-y-2 text-right md:col-span-2'>
								<span className='text-xs font-medium not-italic leading-4 text-[#74788D]'>
									Additional Fields
								</span>
								<label
									type='button'
									htmlFor='attachment'
									className='w-[86px] rounded bg-[#ebedf0] py-1 text-center text-xs font-normal not-italic text-[#495057]'>
									{isUploading ? 'uploading...' : 'Attachment'}
								</label>
								<input
									type='file'
									id='attachment'
									className='hidden'
									onChange={onUpload}
									disabled={isUploading}
								/>
							</div>
						</div>

						<Attachments attachments={attachments} onRemove={onRemove} />

						<Field
							label='Description'
							name='description'
							type='textarea'
							component={TextField}
						/>
						<div className='flex justify-end border-t py-3'>
							<button type='submit' className='btn btn-primary' disabled={isLoading}>
								{isLoading ? 'Creating...' : id ? 'Update Task' : 'Create Task'}
							</button>
						</div>
					</OffcanvasBody>
				</form>
			</Offcanvas>
		</React.Fragment>
	)
}

export default reduxForm({
	form: 'task_form',
	enableReinitialize: true,
})(TaskForm)
