import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { FormFeedback, Input, Label } from 'reactstrap'
import PasswordValidationError from './PasswordValidationError'

export const TextField = ({
	meta: { touched, invalid, error },
	label,
	input,
	infoText,
	hideMessage = false,
	className: { formGroupClass, labelClass, inputClass },
	isValidatePassword,
	ignoreBottomMargin = false,
	isRequired = false,
	...rest
}) => {
	const [val] = useState('')
	const height =
		rest && !['checkbox', 'radio', 'textarea'].includes(rest.type) ? { height: '38px' } : {}
	return (
		<Fragment>
			<div className={classNames({ 'mb-3': !ignoreBottomMargin }, formGroupClass)}>
				{label && (
					<Fragment>
						<Label className={'flex items-center text-left ' + labelClass}>
							{label}
							{isRequired && <span className='text-red-500'>&nbsp;*</span>}
						</Label>
					</Fragment>
				)}
				<Input
					{...rest}
					{...input}
					value={val || input.value}
					invalid={touched && Boolean(invalid)}
					style={height}
					className={inputClass}
				/>
				{infoText && (
					<i className='text-muted' style={{ fontSize: 12 }}>
						{infoText}
					</i>
				)}
				{touched && error && !hideMessage && (
					<Fragment>
						{isValidatePassword ? (
							<PasswordValidationError type={rest?.type} value={input?.value} />
						) : (
							<FormFeedback>{touched && error}</FormFeedback>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	)
}

TextField.propTypes = {
	input: PropTypes.object.isRequired,
	meta: PropTypes.object,
	isValidatePassword: PropTypes.bool,
	ignoreBottomMargin: PropTypes.bool,
}

TextField.defaultProps = {
	meta: null,
	className: { formGroupClass: '', labelClass: '', inputClass: '' },
	isValidatePassword: false,
	ignoreBottomMargin: false,
}
