import { Fragment, useMemo } from 'react'

import fileIcon from 'assets/images/svg-icons/file-icon.svg'

const Attachments = ({ attachments, onRemove }) => {
	const _attachments = useMemo(
		() => (Array.isArray(attachments) ? attachments : []),
		[attachments],
	)

	return (
		<Fragment>
			{_attachments.length > 0 ? (
				<div className='flex items-start space-x-2'>
					<label> Attachments</label>

					<div className='flex flex-col'>
						{attachments.map((attachment, key) => {
							return (
								<div
									key={key}
									className='flex cursor-pointer items-center space-x-2 rounded px-1 hover:bg-gray-100'>
									<img src={fileIcon} />

									<a
										href={attachment?.file?.path}
										target='_blank'
										className='!hover:undeline text-sm font-normal not-italic text-[#495057]'
										rel='noreferrer'>
										{attachment?.file?.name || attachment?.file?.original_name}
									</a>
									<button
										type='button'
										onClick={() => onRemove(key, attachment?.id)}
										className='max-h-min rounded bg-[#fafafa] px-1'>
										<i className='uil-times' />
									</button>
								</div>
							)
						})}
					</div>
				</div>
			) : undefined}
		</Fragment>
	)
}

export default Attachments
