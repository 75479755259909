import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'
import { formatBytes } from 'utils/commonFunctions'
import { CLIENT_API_ROUTES } from './routes'

export const clientApi = createApi({
	reducerPath: 'Client',
	refetchOnFocus: true,
	keepUnusedDataFor: 0,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['Client', 'InvitedClient'],
	endpoints: (builder) => ({
		getClients: builder.query({
			query: (params) => ({
				url: CLIENT_API_ROUTES.findAll(),
				method: 'GET',
				params: params,
			}),
			providesTags: ['Client'],
			transformResponse: (response, _, arg) => {
				const clients = Array.isArray(response?.data?.clients)
					? response?.data?.clients.map((client, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							id: client?.id,
							full_name: `${client?.first_name} ${client?.last_name}`,
							phone_number: client?.phone_number,
							email: client?.email,
							role: client?.role,
							updated_at: formatDate(client?.created_at),
					  }))
					: []

				return {
					clients,
					meta: response?.data?.meta,
				}
			},
		}),
		getInvitedClients: builder.query({
			query: (params) => ({
				url: CLIENT_API_ROUTES.findAllInvites(),
				method: 'GET',
				params: params,
			}),
			providesTags: ['InvitedClient'],
			transformResponse: (response, _, arg) => {
				const clients = Array.isArray(response?.data?.users)
					? response?.data?.users.map((client, idx) => ({
							id: client?.id,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							full_name: `${client?.first_name} ${client?.last_name}`,
							email: client?.email,
							status: client?.on_board_status,
							created_at: formatDate(client?.created_at),
					  }))
					: []

				return {
					clients,
					meta: response?.data?.meta,
				}
			},
		}),
		getOneClient: builder.query({
			keepUnusedDataFor: 0,
			query: (id) => ({
				url: CLIENT_API_ROUTES.findOne(id),
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
			transformResponse: (response) => ({
				client: response?.client,
			}),
		}),
		getClientDetails: builder.query({
			query: (id) => ({
				url: `${apiEndpoints.clients}/${id}`,
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
			transformResponse: (response) => {
				const role = response?.client?.teams.length > 0 ? ' advisor' : ''
				return {
					client: response?.client,
					role,
				}
			},
		}),
		deleteClientDetails: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.users}/delete/client-data/${id}`,
				method: 'POST',
			}),
			invalidatesTags: ['Client'],
		}),
		uploadProfilePicture: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.file,
				method: 'POST',
				data: payload,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
			invalidatesTags: ['Client'],
		}),
		updateClientProfile: builder.mutation({
			query: ({ payload, id }) => ({
				url: `${apiEndpoints.user.profile}/${id}/`,
				method: 'PATCH',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		getQueryData: builder.query({
			keepUnusedDataFor: 0,
			query: (url) => ({
				url: url,
				method: 'GET',
			}),
			invalidatesTags: ['Client'],
		}),
		clientSettingSave: builder.mutation({
			query: (payload) => ({
				url: `${apiEndpoints.clientSetting}/save`,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		createNote: builder.mutation({
			query: (payload) => ({
				url: apiEndpoints.notes,
				method: 'POST',
				data: payload,
			}),
			invalidatesTags: ['Client'],
		}),
		deleteClientSetting: builder.mutation({
			query: (id) => ({
				url: `${apiEndpoints.clientSetting}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Client'],
		}),
		deleteClient: builder.mutation({
			query: (id) => ({
				url: CLIENT_API_ROUTES.destroy(id),
				method: 'POST',
			}),
			invalidatesTags: ['Client'],
		}),
		getWorkflowFiles: builder.query({
			keepUnusedDataFor: 0,
			query: ({ params, url }) => ({
				url: url,
				method: 'GET',
				params: params,
			}),
			providesTags: ['Client'],
			transformResponse: (response, _, arg) => {
				const data = Array.isArray(response?.data?.workflowFiles)
					? response?.data?.workflowFiles.map((file, idx) => ({
							index:
								parseInt(arg?.params?.limit) * (parseInt(arg?.params?.page) - 1) +
								idx +
								1,
							id: file?.id,
							title: file?.title,
							file: file?.files,
							file_size: formatBytes(file?.files?.size),
							created_at: formatDate(file?.files?.created_at),
					  }))
					: []

				return {
					data,
					meta: response?.data?.meta,
				}
			},
		}),
		sendClientInvitation: builder.mutation({
			query: (payload) => ({
				url: 'invite-user/invitation/user',
				method: 'POST',
				data: payload,
			}),
		}),
	}),
})

export const {
	useLazyGetClientsQuery,
	useLazyGetInvitedClientsQuery,
	useLazyGetOneClientQuery,
	useDeleteClientMutation,
	useLazyGetClientDetailsQuery,
	useDeleteClientDetailsMutation,
	useUploadProfilePictureMutation,
	useUpdateClientProfileMutation,
	useGetQueryDataQuery,
	useLazyGetQueryDataQuery,
	useClientSettingSaveMutation,
	useCreateNoteMutation,
	useDeleteClientSettingMutation,
	useLazyGetWorkflowFilesQuery,
	useSendClientInvitationMutation,
} = clientApi
