import { Fragment, useEffect } from 'react'
import { Container } from 'reactstrap'

import LoginForm from 'modules/authentication/login'

const Login = () => {
	useEffect(() => {
		document.body.className = 'authentication-bg'
		return function cleanup() {
			document.body.className = ''
		}
	})

	return (
		<Fragment>
			<div className='account-pages flex min-h-screen items-center justify-center'>
				<Container>
					<LoginForm />
				</Container>
			</div>
		</Fragment>
	)
}

export default Login
