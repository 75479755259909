/* eslint-disable react-hooks/exhaustive-deps */
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm, touch } from 'redux-form'

import { snakeCase } from 'lodash'
import { useSaveAssignedWorkflowMutation } from 'modules/workflows/assigned-workflows/assigned-workflow-apis'
import { Button } from 'theme/ui/forms'
import notification from 'utilities/notification'
import { getDocumentsPayload, getPayload } from '../../..'
import DocumentBoundFieldsTab from '../../../Tab2/document-bound-fields-tab'
import { getTabs } from '../../../tab-content'
import {
	getDocumentBoundFields,
	getDocumentsList,
	getField,
	getFieldsByUser,
	getFieldsList,
} from '../../../utils'
import ProfileFieldsTab from './profile-fields-tab/profile-fields-tab'

const Tab2 = (props) => {
	const {
		workflow_id,
		individual_document,
		handleSubmit,
		uploads_id,
		toggleTab,
		user_type,
		screen_type,
	} = props
	const [activeField, setactiveField] = useState(1)
	const [saveAssignedWorkflow, { isLoading: isWorkflowSaving }] =
		useSaveAssignedWorkflowMutation()
	const [activeFieldTab, setActiveFieldTab] = useState(0)
	const [error, setError] = useState(null)
	const [isFieldSaved, setIsFieldSaved] = useState(false)

	const { defaultGroup } = useSelector((state) => state.Permissions)
	const group = useMemo(() => defaultGroup?.group, [defaultGroup])
	const defaultGroupName = snakeCase(group?.name)
	const CLIENT_KEY = 'client'
	const URL = useMemo(
		() => (defaultGroupName === CLIENT_KEY ? `/client/workflow` : `/advisor/workflow`),
		[defaultGroupName],
	)
	const { syncErrors } = useSelector((state) => state.form.workflow_step_2_review)
	const [disableNext, setDisableNext] = useState(false)

	useEffect(() => {
		const checkErrors = async () => {
			if (Array.isArray(syncErrors?.fields) && syncErrors.fields.length > 0) {
				const allFields = syncErrors.fields
					.filter((item) => item !== undefined)
					.map((item) => Object.keys(item)[0])

				const field = await findCurrentField()
				const { key } = await getField(field)
				if (allFields.includes(key)) {
					setDisableNext(true)
				} else {
					setDisableNext(false)
				}
			} else {
				setDisableNext(false)
			}
		}

		checkErrors()
	}, [syncErrors, activeFieldTab])
	const { workflow_step_2_review: formName } = useSelector((state) => state.form)
	const formValues = formName?.values
	const fieldValues = formValues?.fields || []
	const documentBoundFieldValues = formValues?.document_bound_fields || []

	const note = individual_document?.review_notes

	const fieldsArray = useMemo(
		() => getFieldsList(individual_document?.documents),
		[individual_document?.documents],
	)

	const documentBoundFields = useMemo(
		() => getDocumentBoundFields(individual_document?.documents, user_type),
		[individual_document?.documents],
	)

	const $documentBoundFields = useMemo(
		() =>
			documentBoundFields.map((doc) => ({
				...doc,
				fields: doc.fields.filter((f) => f?.status === 'reject'),
			})),
		[documentBoundFields],
	)

	const tabsList = useMemo(() => getTabs($documentBoundFields.length > 0), [$documentBoundFields])

	const fields = useMemo(() => {
		const $fieldsArray = fieldsArray.filter((f) => f?.is_document_bound === false)
		return getFieldsByUser($fieldsArray, user_type).filter(
			(field) => field?.status === 'reject',
		)
	}, [fieldsArray, user_type])

	const total_fields = fields.length

	const documentList = useMemo(
		() => getDocumentsList(individual_document?.documents),
		[individual_document?.documents],
	)
	const [docs, setDocs] = useState([])

	const toggleField = (tab) => {
		if (activeField !== tab) {
			if (tab >= 1 && tab <= total_fields) {
				setactiveField(tab)
			}
		}
	}

	useEffect(() => {
		if (activeField && fields.length > 0) {
			const currentField = fields[activeField - 1]
			setDocs(currentField?.documents)
		}
	}, [activeField])

	const saveFields = () => {
		return new Promise((resolve) => {
			if (total_fields === activeField) {
				setTimeout(() => {
					setIsFieldSaved(true)
					resolve(true)
				}, 1000)
			} else {
				setIsFieldSaved(false)
				resolve(true)
			}
		})
	}

	const findCurrentField = () => {
		return Promise.resolve(fieldValues[activeField - 1])
	}

	const handleNext = async () => {
		try {
			const field = await findCurrentField()
			const { value, key } = await getField(field)
			const field_name = `fields[${activeField - 1}].${key}`
			props.dispatch(touch('workflow_step_2_review', field_name))

			if (value !== '') {
				setError(null)
				toggleField(activeField + 1)
				await saveFields()
			}
		} catch (error) {
			notification('error', 'Something went wrong while saving the field')
		}
	}

	const onSubmit = async () => {
		try {
			if (activeFieldTab === 0 && tabsList.length > 1) {
				setActiveFieldTab(1)
			} else {
				const documentsPayload = getDocumentsPayload(
					documentList,
					documentBoundFieldValues,
					fieldValues,
					user_type,
				)
				const payload = getPayload(
					workflow_id,
					uploads_id,
					individual_document?.id,
					documentsPayload,
				)

				const response = await saveAssignedWorkflow({ payload, url: URL })
				if (response?.data?.status === 200) {
					notification('success', response?.data?.message)
					toggleTab(3)
				}
			}
		} catch (error) {
			notification('error', error?.message)
		}
	}

	const handlePrev = () => {
		if (activeFieldTab === 1) {
			setActiveFieldTab(0)
		}
	}

	return (
		<Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tab.Group selectedIndex={activeFieldTab}>
					<Tab.List className='-mt-[10px] mb-3 flex w-full divide-x rounded-sm !border border-gray-200'>
						{tabsList.map((tab, idx) => (
							<Tab
								as='span'
								key={idx}
								className={({ selected }) =>
									classNames(
										'w-full !px-3 py-2.5 text-sm font-medium',
										' focus:outline-none ',
										selected
											? 'bg-[#dee3fa]  text-blue-700'
											: 'bg-[#eef1fd] text-gray-500',
									)
								}>
								{tab.label}
							</Tab>
						))}
					</Tab.List>
					<Tab.Panels>
						<Tab.Panel>
							<ProfileFieldsTab
								activeField={activeField}
								docs={docs}
								error={error}
								fields={fields}
								handleNext={handleNext}
								toggleField={toggleField}
								disableNext={disableNext}
								total_fields={total_fields}
								note={note}
								setIsFieldSaved={setIsFieldSaved}
								isFieldSaved={isFieldSaved}
								screen_type={screen_type}
							/>
						</Tab.Panel>
						<Tab.Panel>
							<DocumentBoundFieldsTab documentBoundFields={$documentBoundFields} />
						</Tab.Panel>
					</Tab.Panels>
				</Tab.Group>

				<div className='my-4 border-t border-dashed' />
				<div className='actions clearfix'>
					<div className='m-0 flex justify-between'>
						<Button type='button' disabled={activeFieldTab === 0} onClick={handlePrev}>
							Previous
						</Button>

						<Button
							type='submit'
							isLoadingText='Saving...'
							isLoading={isWorkflowSaving}>
							Next
						</Button>
					</div>
				</div>
			</form>
		</Fragment>
	)
}

export default reduxForm({
	form: 'workflow_step_2_review',
	enableReinitialize: true,
})(Tab2)
