/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { SpinLoader } from 'components/Common'
import { useClientSettingSaveMutation } from 'modules/clients/client-apis'
import { Fragment, useEffect, useState } from 'react'
import { reduxForm } from 'redux-form'
import notification from 'utilities/notification'
import ClientInfo from './ClientInfo'
import CustomFieldsForm from './CustomFields'
import useRelationViewQuery from './useRelationViewQuery'

const Info = ({ client, role }) => {
	const [fieldFormLoading, setFieldFormLoading] = useState(false)
	const [fields, setFields] = useState([])

	const { data, isLoading } = useRelationViewQuery(client)

	const [clientSettingSave] = useClientSettingSaveMutation()

	// const columnHelper = createColumnHelper()

	// const columns = [
	// 	columnHelper.accessor('id', {
	// 		id: 'id',
	// 		size: 40,
	// 		header: () => <span>#</span>,
	// 		cell: (info) => <Fragment>{info.getValue()}.</Fragment>,
	// 	}),

	// 	columnHelper.accessor((row) => row.name, {
	// 		id: 'name',
	// 		size: 200,
	// 		header: () => <span>Name (Job Title)</span>,
	// 		cell: (info) => <span>{info.getValue()}</span>,
	// 	}),

	// 	columnHelper.accessor((row) => row.relationship, {
	// 		id: 'relationship',
	// 		size: 100,
	// 		header: () => <span>Relationship</span>,
	// 		cell: (info) => (
	// 			<span className='line-clamp-1 max-w-[180px] text-sm !text-gray-700'>
	// 				{info.getValue()}
	// 			</span>
	// 		),
	// 	}),

	// 	columnHelper.accessor((row) => row.email, {
	// 		id: 'email',
	// 		header: () => <span>Email</span>,
	// 		cell: (info) => (
	// 			<span className='max-w-[180px] text-sm !text-gray-700'>{info.getValue()}</span>
	// 		),
	// 	}),
	// ]

	const saveFields = async (values) => {
		setFieldFormLoading(true)
		const payload = { ...values, user: { id: client?.id } }
		const response = await clientSettingSave(payload)
		setFieldFormLoading(false)
		if (response?.data?.status === 200 && Array.isArray(response?.data?.data)) {
			notification('success', response?.data?.message)
			const data = response?.data?.data?.[0]
			if (data?.length > 0) {
				const list = data.map((item) => ({
					id: item?.id,
					key: item?.user_key,
					value: item?.user_value,
				}))
				setFields(list)
			}
		}
		setFieldFormLoading(false)
	}

	useEffect(() => {
		if (data?.length > 0) {
			setFields(data)
		}
	}, [data])

	return (
		<Fragment>
			<ClientInfo client={client} />
			<hr className='my-3' />

			<div className='mb-4 rounded-lg bg-white'>
				{isLoading ? (
					<div className='bg-[#F2F5FF] p-4 py-3'>
						<h5 className='font-bold'>Custom Fields</h5>
						<p className='text-muted mb-0' style={{ fontSize: 13 }}>
							(To modify click on field)
						</p>
						<SpinLoader />
					</div>
				) : (
					<CustomFieldsForm
						loading={fieldFormLoading}
						initialValues={{ fields }}
						fields={fields}
						onSubmit={(values) => saveFields(values)}
					/>
				)}
			</div>

			{/* <hr className='my-3' />

			<div className='mb-4 rounded-lg bg-white'>
				<div className='bg-[#F2F5FF] p-4 py-3'>
					<h5 className='font-bold'>Relationships</h5>
				</div>
				<Row className='p-3'>
					<Col sm={12}>
						<FilterForm />
						<DataTable
							columns={columns}
							data={data}
							isLoading={isLoading}
							pagination={pagination}
							setPagination={setPagination}
							meta={meta}
						/>
					</Col>
				</Row>
			</div> */}
		</Fragment>
	)
}

const InfoForm = reduxForm({
	form: 'client_info',
	enableReinitialize: true,
})(Info)
export default InfoForm
