import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import Badge from 'theme/ui/badge'
import DataTable from 'theme/ui/data-table'
import UsersActions from './users-action'

const UsersTable = ({ data, meta, isLoading, pagination, setPagination }) => {
	const columnHelper = createColumnHelper()
	const [sorting, setSorting] = useState([])

	const columns = [
		columnHelper.accessor((row) => row, {
			id: 'id',
			size: 50,
			header: () => <span>#</span>,
			cell: (info) => <div>{info?.getValue()?.ind}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'name',
			size: 200,
			header: () => <span>Name</span>,
			cell: (info) => <div>{info?.getValue()?.name}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'email',
			size: 200,
			header: () => <span>Description</span>,
			cell: (info) => <div>{info?.getValue()?.email}</div>,
		}),
		columnHelper.accessor((row) => row, {
			id: 'status',
			size: 200,
			header: () => <span>Status</span>,
			cell: (info) => (
				<>
					<Badge variant={info?.getValue()?.status == 'Active' ? 'success' : 'danger'}>
						<span className='capitalize'>{info?.getValue()?.status}</span>
					</Badge>
				</>
			),
		}),
		columnHelper.accessor((row) => row, {
			id: 'action',
			size: 200,
			enableSorting: false,
			header: () => <span>Action</span>,
			cell: (info) => (
				<>
					<UsersActions hasTeam={info?.getValue()?.hasTeam} id={info?.getValue()?.id} />
				</>
			),
		}),
	]

	return (
		<DataTable
			columns={columns}
			data={data}
			isLoading={isLoading}
			pagination={pagination}
			setPagination={setPagination}
			meta={meta}
			setSorting={setSorting}
			sorting={sorting}
		/>
	)
}

export default UsersTable
