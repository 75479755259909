import { getCountries, getCountryCallingCode } from 'react-phone-number-input'

const CountryFlagSelect = ({ value, onChange, className, labels, flagURL }) => {
	return (
		<div className={className}>
			<select
				value={value}
				onChange={(event) => onChange(event.target.value || undefined)}
				className='PhoneInputCountrySelect'>
				<option value=''>{labels['ZZ']}</option>
				{getCountries().map((country) => (
					<option key={country} value={country}>
						{labels[country]} +{getCountryCallingCode(country)}
					</option>
				))}
			</select>

			<div className='PhoneInputCountryIcon me-2'>
				<img
					className='PhoneInputCountryIconImg rounded-sm'
					alt='Azerbaijan'
					src={flagURL}
				/>
			</div>

			<div className='PhoneInputCountryCodeInDegit'>
				{value ? '+' + getCountryCallingCode(value) : null}
			</div>

			<div className='PhoneInputCountrySelectArrow me-1'></div>
		</div>
	)
}

export default CountryFlagSelect
