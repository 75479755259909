import { createApi } from '@reduxjs/toolkit/query/react'

import apiEndpoints from 'helpers/apiEndpoints'
import axiosBaseQuery from 'store/axios-base-query'
import { formatDate } from 'utilities/helpers'

export const teamMemberApi = createApi({
	reducerPath: 'TeamMembers',
	refetchOnFocus: true,
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	baseQuery: axiosBaseQuery,
	tagTypes: ['TeamMember'],
	endpoints: (builder) => ({
		getTeamMembers: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: '/teams/get-advisors',
				method: 'GET',
				params: params,
			}),
			providesTags: ['TeamMember'],
			transformResponse: (response, _, arg) => {
				const users = Array.isArray(response?.data?.users)
					? response?.data?.users.map((user, idx) => ({
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							name: `${user?.first_name} ${user?.last_name}`,
							phone: user?.phone_number,
							email: user?.email,
							role: user?.group?.name,
							created_at: formatDate(user?.created_at),
					  }))
					: []

				return {
					users,
					meta: response?.data?.meta,
				}
			},
		}),
		getTeamMembersInvitations: builder.query({
			keepUnusedDataFor: 0,
			query: (params) => ({
				url: apiEndpoints.invites.users,
				method: 'GET',
				params: params,
			}),
			providesTags: ['TeamMember'],
			transformResponse: (response, _, arg) => {
				const users = Array.isArray(response?.data?.users)
					? response?.data?.users.map((user, idx) => ({
							...user,
							index: parseInt(arg?.limit) * (parseInt(arg?.page) - 1) + idx + 1,
							created_at: formatDate(user?.created_at),
					  }))
					: []

				return {
					users,
					meta: response?.data?.meta,
				}
			},
		}),
	}),
})

export const { useLazyGetTeamMembersQuery, useLazyGetTeamMembersInvitationsQuery } = teamMemberApi
