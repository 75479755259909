const DocumentTemplateFormSkeleton = () => {
	return (
		<div className='animate-pulse space-y-6'>
			<div className='h-14 w-full bg-slate-200' />
			<div className='px-[75px]'>
				<div className='flex flex-col gap-6'>
					<div className='grid grid-cols-3 gap-10'>
						<div className='col-span-1'>
							<div className='mb-2 h-[22px] w-[128px] bg-slate-200' />
							<div className='h-[34px] w-[270px] bg-slate-200' />
						</div>
						<div className='col-span-2'>
							<div className='h-[144px] w-full bg-slate-200' />
						</div>
					</div>
					<div className='h-[148px] w-full bg-slate-200' />

					<div className='flex justify-between gap-6 !border-t py-6'>
						<div className='h-screen w-3/5 bg-slate-200' />
						<div className='h-screen w-2/5 bg-slate-200' />
					</div>
				</div>
			</div>
		</div>
	)
}

export default DocumentTemplateFormSkeleton
