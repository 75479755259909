import classNames from 'classnames'
import { NavItem, NavLink } from 'reactstrap'

const TabHeaderContainer = ({ activeTab, tabs }) => {
	return (
		<div className='steps clearfix workflow-step'>
			<ul>
				{tabs.map((tab, idx) => (
					<NavItem
						key={idx}
						className={classNames({
							current: activeTab === tab.id,
						})}>
						<NavLink
							className={classNames({
								current: activeTab === tab.id,
							})}>
							{tab.name}
						</NavLink>
					</NavItem>
				))}
			</ul>
		</div>
	)
}

export default TabHeaderContainer
