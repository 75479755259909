import { Field } from 'redux-form'

import { SelectDropdown } from 'components/Forms2'
import { useMemo } from 'react'
import StatusQuery from '../../StatusQuery'

const StatusDropdown = () => {
	const { statuses, isLoading } = StatusQuery()

	const options = useMemo(
		() =>
			Array.isArray(statuses)
				? statuses.map((status) => ({
						label: (
							<div className='flex items-center space-x-2'>
								<div
									className='h-2 w-2 rounded-full'
									style={{
										backgroundColor: status?.color,
									}}
								/>
								<div>{status?.title}</div>
							</div>
						),
						value: status?.title,
				  }))
				: [],
		[statuses],
	)

	return (
		<Field
			name='progress_status'
			type='select'
			component={SelectDropdown}
			options={options}
			isLoading={isLoading}
			width={'w-40'}
		/>
	)
}

export default StatusDropdown
