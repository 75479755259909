import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Container, Row } from 'reactstrap'

import bg1 from 'assets/images/onboarding/bg2.png'
import apiEndpoints from 'helpers/apiEndpoints'
import {
	authenticate,
	fetchPermissions,
	setDefaultPermissionsGroup,
	setUserDetails,
} from 'modules/authentication/login'
import { store } from 'store/common/actions'
import { showSuccessToast, useQuery } from 'utils/commonFunctions'
import { createUser, getPhoneNumber, removeToken, usePayload, verifyOTP } from '..'
import Wrapper from '../components/Wrapper'
import FormHeader from '../components/header/FormHeader'
import StepForm from './StepForm'

const StepFive = (props) => {
	const { history } = props
	const [otp, setOtp] = useState('')
	const dispatch = useDispatch()
	const { token } = useQuery(props.location.search)
	const payload = usePayload(token)
	const [isLoading, setIsLoading] = useState(false)

	const phoneNumber = getPhoneNumber(token)

	const onSubmit = async () => {
		const { email, password } = payload
		const _payload = {
			email: email,
			phone_number: phoneNumber,
			otp: otp,
		}
		setIsLoading(true)
		try {
			await verifyOTP(_payload)
			await createUser(payload)
			const user = await authenticate({ email, password })
			await removeToken()
			const permissions = await fetchPermissions()

			await setDefaultPermissionsGroup(permissions)
			await setUserDetails(user)
			history.push('/onboarding/input-sin-number')
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			return null
		}
	}

	const onResend = () => {
		dispatch(
			store(apiEndpoints.userOnBoard.phone.sendOTP, {
				phone_number: phoneNumber,
				email: payload.email,
			}),
		).then((response) => {
			if (response?.status === 200) {
				showSuccessToast(response?.message)
			}
		})
	}

	return (
		<Wrapper>
			<Container className='onboarding-container overflow-hidden' fluid>
				<Row>
					<Col sm={7}>
						<div className='flex-column flex min-h-screen space-y-14'>
							<FormHeader token={token} />

							<StepForm
								history={history}
								onSubmit={onSubmit}
								onResend={onResend}
								otpState={[otp, setOtp]}
								isLoading={isLoading}
								phoneNumber={phoneNumber}
							/>
						</div>
					</Col>
					<Col
						sm={5}
						style={{ backgroundImage: 'url(' + bg1 + ')' }}
						className='bg-cover'>
						<div className='px-11 py-28'>
							<h2 className='text-[27px] font-bold'>
								Upload multiple documents with ease
							</h2>
							<p className='text-[#74788D]'>
								No need to wonder what document you might need to upload. When
								workflow is assigned to you, all required document&apos;s list will
								be there for you to completely hassle free with absolutely no rush.
							</p>
						</div>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	)
}

export default StepFive
