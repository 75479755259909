import { useState } from 'react'

import ClientImportForm, { importCsv, validatePayload } from 'modules/clients/client-import'
import AppLayout from 'theme/layouts/app-layout'
import PageHeader from 'theme/layouts/page-header'
import notification from 'utilities/notification'

const ClientImport = (props) => {
	const { history } = props
	const [csvFields, setCsvFields] = useState([])
	const [fileId, setFileId] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [dbFieldsOptions, setDbFieldsOptions] = useState([])

	const initialValues = {
		fields: csvFields.map((item) => ({ csv: item?.name, system: null, index: item?.idx })),
	}

	const onSubmit = async (values) => {
		const payload = {
			file_id: fileId,
			header_fields: values.fields.map((field) => ({
				...field,
				system: field?.system?.value,
			})),
		}

		setIsLoading(true)
		try {
			await validatePayload(payload, dbFieldsOptions)
			const response = await importCsv(payload)
			setIsLoading(false)
			notification('succecss', response?.message)
			history.goBack()
		} catch (error) {
			setIsLoading(false)
			notification('error', error?.message || 'Something went wrong')
		}
	}

	return (
		<AppLayout
			meta={{
				title: 'Import Clients',
			}}>
			<div className='page-content px-0 !pt-[70px]'>
				<div className='page-container'>
					<PageHeader
						title='Import Clients'
						description='When assigning documents/packages here, do not ignore to fill in all the required fields completely and follow the document/package adding rules.'
						hasBackButton={true}
					/>
					<div className='page-body'>
						<ClientImportForm
							onSubmit={onSubmit}
							initialValues={initialValues}
							csvFieldsState={[csvFields, setCsvFields]}
							dbFieldsState={[dbFieldsOptions, setDbFieldsOptions]}
							fileIdState={[fileId, setFileId]}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</AppLayout>
	)
}

export default ClientImport
