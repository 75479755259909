import { useState } from 'react'
import { Link } from 'react-router-dom'
import DeleteConfirmAlert from 'theme/ui/delete-confirm-alert'
import notification from 'utilities/notification'
import hasPermission from 'utils/checkPermission'
import { useDeleteClientMutation } from '../client-apis'

const Actions = ({ isActionActive, client }) => {
	const isReadPermission = hasPermission('client:read')
	const [isOpen, setIsOpen] = useState(false)
	const onClose = () => setIsOpen(false)

	const [deleteClient, { isFetching }] = useDeleteClientMutation()

	const onConfirm = () => {
		deleteClient(client?.id).then((response) => {
			if (response?.data?.status === 200) {
				notification('success', response?.data?.message)
			}
		})
	}

	return (
		<div className='w-[170px]'>
			<DeleteConfirmAlert
				isDeleting={isFetching}
				isOpen={isOpen}
				onClose={onClose}
				onConfirm={onConfirm}
			/>
			{isActionActive ? (
				<div className='flex space-x-3'>
					{isReadPermission && (
						<Link to={`/clients/${client?.id}`} className='action-btn btn-gray-outline'>
							<i className='uil uil-eye' style={{ fontSize: 16 }} />
							<span>View</span>
						</Link>
					)}
					&nbsp;
					<button
						type='button'
						className='action-btn btn-red-outline'
						onClick={() => setIsOpen(true)}>
						<i className='uil-trash-alt' style={{ fontSize: 16 }} />
						<span>Delete</span>
					</button>
				</div>
			) : (
				<span className=' text-sm !text-gray-700'>{client?.updated_at}</span>
			)}
		</div>
	)
}

export default Actions
