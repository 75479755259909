/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import UserQuery from 'hooks/UserQuery'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mutate } from 'swr'

import { SpinLoader } from 'components/Common'
import apiEndpoints from 'helpers/apiEndpoints'
import * as actions from 'store/common/actions'
import * as authActions from 'store/jwtauth/actions'
import { showErrorToast, showSuccessToast } from 'utils/commonFunctions'
import { preparePayload } from '.'
import ProfileForm from './profile-form'
import SignatureForm from './signature-form'

const ProfileManage = () => {
	const dispatch = useDispatch()
	const { user_id } = useSelector((state) => state.Permissions)
	const [fileId, setFileId] = useState(null)
	const [loader, setLoader] = useState(false)
	const [upLoader, setUpLoader] = useState(false)
	const avatarRef = useRef(null)
	const { user: Profile, isLoading } = UserQuery()

	const handleUpload = (file) => {
		const formData = new FormData()
		formData.append('file', file)
		if (file) {
			setUpLoader(true)
			axios
				.post(apiEndpoints.file, formData)
				.then((res) => {
					setUpLoader(false)
					const response = res?.data
					if (response?.status === 200) {
						showSuccessToast(`${response?.message} Now you can save the profile.`)
						setFileId(response?.data?.fileID)
					}
				})
				.catch((error) => {
					setUpLoader(false)
					showErrorToast(
						error?.response?.data?.message ||
							'Something went wrong while uploading the profile picture. Please try again',
					)
					if (avatarRef.current) {
						avatarRef.current.value = null
					}
				})
		}
	}

	const handleUpdate = (values) => {
		if (upLoader) {
			showErrorToast('Please wait file is uploading...')
		} else {
			setLoader(true)
			const payload = preparePayload(values)

			if (fileId) {
				Object.assign(payload, { avatar: { id: fileId } })
			}

			dispatch(actions.update(apiEndpoints.user.profile, user_id, payload)).then(
				(response) => {
					setLoader(false)
					if (response?.status === 200 && response?.data) {
						showSuccessToast(response?.message)
						dispatch(authActions.saveUserData(response?.data))
						if (avatarRef.current) {
							avatarRef.current.value = null
						}
						mutate(apiEndpoints.user.profile)
					}
				},
			)
		}
	}

	useEffect(() => {
		if (!isLoading && window.location.hash === '#signatures') {
			const section = document.querySelector('#signatures')
			section.scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	}, [isLoading])

	return (
		<Fragment>
			{isLoading ? (
				<SpinLoader />
			) : (
				<Fragment>
					<ProfileForm
						initialValues={Profile}
						loading={loader}
						handleUpload={handleUpload}
						avatarRef={avatarRef}
						upLoader={upLoader}
						user={Profile}
						onSubmit={(values) => handleUpdate(values)}
					/>
					<div className='my-6 border-t border-gray-400' />
					<SignatureForm />
				</Fragment>
			)}
		</Fragment>
	)
}

export default ProfileManage
